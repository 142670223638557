import GWRApiClient from './GWRApiClient';

/**
 * Concrete implementation for the Upgrade room endpoints
 *
 * @class Nor1UpgradeApiClient
 * @extends {GWRApiClient}
 */

export default class Nor1UpgradeApiClient extends GWRApiClient {
  /**
   * @constructor
   * Calls parent constructor.
   * If there isn't any url provided, then we use the default from the environment variable
   * fills the url and token information
   * @param {string} url
   *  The url where the service endpoints are
   * @param {string} token
   *  The token of the logged user
   */

  constructor(url = '', token = '') {
    let urlAux = url !== '' ? url : process.env.REACT_APP_NOR1_UPGRADES_URL;

    super(urlAux, token);
  }

  /**
   * function to get all the upgraded room data
   * @function
   * @param {Object} upgradesRequest - parameters to send about reservation ytp get upgraded room details
   */
  async getNor1UpgradesInfo(upgradesRequest) {
    const urlRequest = `${this.url}dynLink/`;

    let request = await super.postApiCall(urlRequest, upgradesRequest);
    return request;
  }
}

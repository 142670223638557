// *** Leave following commented lines in place till 30-JUN-2024 ***
// import moment from 'moment';
import { objectIntersection } from './objectKeysManagementUtils';
import { GwDatesWrapper } from './gwDatesWrapper';

export const LocalStorageKeys = {
  REDUX_LOCAL_STORAGE: 'state',
  RecentSearches: 'RECENT_SEARCHES',
  Itineraries: 'ITINERARIES', // TODO: Remove after local development, this is just for test purposes
  Ungated: 'UNGATED', // Flag to ungate deals pages
  InteractionsUserId: 'intUserId',
  SALE_TOKEN: 'saleToken',
  GET_SESSION_STORAGE: 'getSessionStorage',
  SESSION_STORAGE: 'sessionStorage',
  URL_LOCATION: 'urlLocation'
};

export const SessionStorageKeys = {
  REFER_A_FRIEND: 'referFriend',
  ENDPOINT_TTL_KEY: 'endpointTTLData'
};

export function loadStorageDateConversion(dateString) {
  let dateStringToConvert = dateString;

  if (dateStringToConvert) {
    dateStringToConvert =
      dateStringToConvert.hasOwnProperty('_isAMomentObject') && dateStringToConvert._isAMomentObject
        ? dateStringToConvert
        : GwDatesWrapper.getMoment(dateStringToConvert);
  }

  return dateStringToConvert;
}

/**
 * @function
 * Populate the initial state for the storage. This will allow the live updates for the Redux's localStorage.
 * If the state has more properties than the initialState then the those remaining properties will be removed from the state.
 * Otherwise, if the initialState has more properties than the state, then those properties will be added to the state.
 * @param {Object} state
 * The state of the store. It may come from the localStorage.
 * @param {Object} initialState
 * The initial values of the state in the store.
 */
export function initializeState(state, initialState) {
  if (state !== undefined && state !== null) {
    // Remove the properties not present in initialState
    let objectIntersected = objectIntersection(state, initialState);
    // This will merge the initial object on the saved state.
    // The second object will overwrite the values, this way we
    // can get the saved state values
    state = { ...initialState, ...objectIntersected };
  } else {
    state = initialState;
  }

  return state;
}

/**
 *
 * @param {string} key state name for the sessionStorage
 * @param {object} value value of the sessionStorage
 */
export function setNewSessionKey(key, value) {
  // get current key object
  const sessionData = sessionStorage.getItem(key);
  // verify if the key exists
  const sessionExists = !!sessionData;
  // flow for existing key
  if (sessionExists) {
    // get current key object
    const existingData = JSON.parse(sessionData);
    // update or create new session values
    const neTempObject = { ...existingData, ...value };
    sessionStorage.setItem(key, JSON.stringify(neTempObject));
  } else {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
}

/**
 *
 * @param {string} valueToSearch value to search for
 * @param {string} keyState state name
 * @returns
 */
export function getSpecificItem(valueToSearch, keyState) {
  const getData = sessionStorage.getItem(keyState);
  if (getData) {
    const getParsedData = JSON.parse(getData);
    return getParsedData[valueToSearch];
  } else {
    return null;
  }
}

export function isReferAfriendSessionCreatedAlready() {
  let haveSessionAlready = true;

  const data = sessionStorage.getItem(SessionStorageKeys.REFER_A_FRIEND);
  if (data === null) {
    haveSessionAlready = false;
  }
  return haveSessionAlready;
}

export function sessionStorage_transfer(event) {
  if (event.key === LocalStorageKeys.GET_SESSION_STORAGE) {
    // Some tab asked for the sessionStorage -> send it
    console.log('transfer');
    let sessionstotransfer = {};

    // Get session that can be transerred
    const getData = JSON.parse(sessionStorage.getItem(SessionStorageKeys.REFER_A_FRIEND));
    if (getData) {
      sessionstotransfer = getData;
    }

    // Set Local storage for transfers
    if (Object.keys(sessionstotransfer).length > 0) {
      localStorage.setItem(LocalStorageKeys.SESSION_STORAGE, JSON.stringify(sessionstotransfer));
    }
  } else if (event.key === LocalStorageKeys.SESSION_STORAGE && !isReferAfriendSessionCreatedAlready()) {
    // sessionStorage is empty -> fill it
    console.log('should rehydrate?', !isReferAfriendSessionCreatedAlready());
    // Get Session from Local
    const data = JSON.parse(localStorage.getItem(LocalStorageKeys.SESSION_STORAGE));
    // Remove the session on local
    localStorage.removeItem(LocalStorageKeys.SESSION_STORAGE);
    console.log(data);
    // Restore sessionStorage
    if (data) {
      sessionStorage.setItem(SessionStorageKeys.REFER_A_FRIEND, JSON.stringify(data));
    }
  }
}

import axios from 'axios';
import { MODAL_KEYS } from '../../components/GlobalModal/modalSource';
import { actionCreators as modalActionCreators } from '../../store/componentStores/GlobalModal';

axios.interceptors.response.use(undefined, async err => {
  if (
    err.response?.status === 401 ||
    err.response?.data?.message === '401 Unauthorized'
  ) {
    localStorage.removeItem('user');
    modalActionCreators.openModal(
      {
        keyContentComponent: MODAL_KEYS.AUTH_MODAL,
        params: {
          shouldOpenCreateForm: false
        }
      },
      false,
      true
    );
  }
  return Promise.reject(err);
});

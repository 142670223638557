/**
 * Function to get birthday time and locations details for birthday parties for the current property
 * @returns {Array} Array of objects containing the time and locations' details
 */
export const getBirthdayTimesAndLocations = () => {
  const timesAndLocations = window.birthdayPartyTimesAndLocationsJson
    ? JSON.parse(window.birthdayPartyTimesAndLocationsJson)
    : [];
  return timesAndLocations;
};

/**
 * Function to get the name of the selected time and location
 * @param {number|string} The value that corresponds the value key of the object
 * @returns {string} The label of the key and location for that property and selected value
 */
export const getTimesAndLocationLabel = value => {
  const timesAndLocations = getBirthdayTimesAndLocations();
  const timeLocationValue = parseInt(value);
  let timeAndLocationLabel = '';

  if (timesAndLocations) {
    try {
      const timeAndLocation = timesAndLocations.find(timeAndLoc => timeAndLoc.value === timeLocationValue);
      timeAndLocationLabel = timeAndLocation.name;
    } catch (error) {
      timeAndLocationLabel = 'Error having the time and location';
    }
  }

  return timeAndLocationLabel;
};

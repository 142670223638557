// This component is deprecated, please use src/components/ui-kit/NewSelect
/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { themeGet } from 'styled-system';
import { fontWeights } from '../../ui-kit/themes/theme';
import Box from '../Box';
import Flex from '../Flex';
import Label from '../Label';
import Relative from '../Relative';
import Text from '../Text';
import ArrowDownThinIcon from '../icons/ArrowDownThin';

const borderColor = ({ invalid, valid }) => {
  if (valid) {
    return css`
      border-color: ${themeGet('colors.successGreen')};
    `;
  }
  if (invalid) {
    return css`
      border-color: ${themeGet('colors.digitalBrightRed.100')};
    `;
  }

  return css`
    border-color: ${themeGet('colors.preciousStoneBlue.20')};
  `;
};

const StyledSelect = styled.select`
  margin: 0px;
  cursor: pointer;
  appearance: none;
  padding-top: ${({ placeholder, paddingTop }) => (paddingTop ? paddingTop : !!placeholder ? `22px` : `15px`)};
  padding-bottom: ${({ placeholder, paddingBottom }) =>
    paddingBottom ? paddingBottom : !!placeholder ? `10px` : `14px`};
  padding-left: 16px;
  padding-right: 30px;
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '10px')};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? themeGet('colors.' + backgroundColor) : themeGet('colors.snowMountainGrey.20')};
  width: 100%;
  border-width: ${({ borderWidth }) => (borderWidth ? borderWidth : '1px')};
  border-style: solid;
  font-size: ${({ fontSize }) => (fontSize ? themeGet('fontSizes.' + fontSize) : themeGet('fontSizes.1'))}px;
  color: ${({ color }) => (color ? themeGet('colors.' + color) : themeGet('colors.deepLakeBlue.100'))};
  font-family: ${themeGet('fonts.secondary')};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : themeGet('fontWeights.regular'))};
  line-height: 1.2;
  height: ${({ height }) => (height ? height : `50px`)};

  &:focus {
    outline: unset;
    border: none;
    box-shadow: 0 0 0 2px ${themeGet('colors.focusBlue')};
  }

  & + svg {
    ${({ isNewWidgetStyleActive }) =>
      isNewWidgetStyleActive &&
      css`
        fill: ${themeGet('colors.deepLakeBlue.100')};
        top: 35px;
        right: 13px;
        stroke-width: 2;
        path {
          stroke: black;
          stroke-width: 2;
        }
      `}
  }

  ${borderColor}
`;

const StyledChevronDownIcon = styled(ArrowDownThinIcon)`
  pointer-events: none;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  fill: ${themeGet('colors.preciousStoneBlue.100')};
`;

const StyledPlaceHolder = styled(Label)`
  top: 25px;
  transform: ${({ active }) => (active ? `translateY(-15px) scale(.57)` : `translateY(-50%)`)};
  left: ${themeGet('space.3')}px;
  color: ${props => (props.active ? themeGet('colors.preciousStoneBlue.100') : themeGet('colors.deepLakeBlue.100'))};
  opacity: ${props => (props.active ? 1 : 0.6)};
  transition: all 0.1s ease-in-out 0s;
  font-size: ${themeGet('fontSizes.1')}px;
`;

const propTypes = {
  /**
    Use the invalid state to indicate there was an error validating this field.
    */
  invalid: PropTypes.bool,
  /**
    Use the valid prop to indicate if the select was successfully validated
    filled; this will take precedence over the invalid prop.
    */
  valid: PropTypes.bool,
  /**
   * Shows an error message, it will only display if invalid is `true`.
   */
  errorMessage: PropTypes.string,
  /**
   * Floating label text.
   */
  placeholder: PropTypes.string,
  /**
   * Execute function when the component gets focus, this will be executed
   * after moving the floating label.
   */
  onFocus: PropTypes.func,
  /**
   * Execute function when the `Select` component losses focus, this will be
   * executed after moving the floating label.
   */
  onBlur: PropTypes.func,
  /**
   * Value to select if the `Select`component is used as a controlled input.
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Value to be used when the `Select`component is not used as a controlled
   * input.
   */
  defaultValue: PropTypes.string
};

const defaultProps = {
  invalid: false,
  valid: false,
  onFocus: () => {},
  onBlur: () => {}
};

/**
 * Use the `Select` component to give the user multiple selections with and
 * enhanced design but, preserving native select UI.
 *
 * Aside from the props listed below, this component accepts all the normal
 *  props from a select HTML tag, they will be streamed to the select tag.
 */
const Select = ({ children, errorMessage, placeholder, onFocus, onBlur, value, defaultValue, invalid, ...props }) => {
  const [isFocusedOrFilled, setIsFocusedOrFilled] = useState((!!value && value !== 'placeholder') || defaultValue);

  useEffect(() => {
    setIsFocusedOrFilled((!!value && value !== 'placeholder') || defaultValue);
  }, [value]);

  const selectRef = useRef(null);

  const focusHandler = e => {
    if (!isFocusedOrFilled) {
      setIsFocusedOrFilled(true);
    }

    onFocus(e);
  };

  const blurHandler = e => {
    const {
      current: { value: selectValue }
    } = selectRef;
    setIsFocusedOrFilled(!!selectValue && selectValue !== 'placeholder');
    onBlur(e);
  };

  const getDefaultValue = () => {
    if (defaultValue) {
      return {
        defaultValue
      };
    }

    if (placeholder && !value) {
      return {
        defaultValue: 'placeholder'
      };
    }

    return {};
  };

  const getValue = () => {
    if (isFocusedOrFilled) {
      return { value };
    }

    return {};
  };

  return (
    <Box>
      <Relative>
        <StyledSelect
          className={''}
          {...props}
          {...getValue()}
          {...getDefaultValue()}
          ref={selectRef}
          placeholder={placeholder}
          onFocus={focusHandler}
          onBlur={blurHandler}
          invalid={invalid}>
          {placeholder && <option value="placeholder" hidden></option>}
          {children}
        </StyledSelect>
        {placeholder && <StyledPlaceHolder active={isFocusedOrFilled}>{placeholder}</StyledPlaceHolder>}
        <StyledChevronDownIcon size={14}></StyledChevronDownIcon>
      </Relative>
      {errorMessage && invalid && (
        <Flex minHeight="13px" alignItems="center">
          <Text
            as={'label'}
            fontWeight={fontWeights.medium}
            mt="8px"
            mb="8px"
            fontSize={9}
            color="digitalBrightRed.100">
            {errorMessage}
          </Text>
        </Flex>
      )}
    </Box>
  );
};

Select.propTypes = propTypes;
Select.defaultProps = defaultProps;

export default Select;

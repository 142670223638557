import PropTypes from 'prop-types';
import React from 'react';
import * as COPY from '../utilities/copy';

import { CheckInOutDate } from '../../../../../CheckInOutDates';
import { Flex, Text } from '../../../../../ui-kit';
import ArrowRightIcon from '../../../../../ui-kit/icons/ArrowRight';
import theme from '../../../../../ui-kit/themes/theme';
import { SummaryContent } from '../../../Content';

const propTypes = {
  /** Checkin Date in the MM/DD format */
  checkIn: PropTypes.string.isRequired,

  /** Checkout Date in the MM/DD format */
  checkOut: PropTypes.string.isRequired,

  /** Number of nights for the reservation */
  numberOfNights: PropTypes.number.isRequired
};

export const Header = ({ checkIn, checkOut, numberOfNights, hasNewGuests }) => {
  const numberOfNightsLabel = `${numberOfNights} ${
    numberOfNights && numberOfNights > 1 ? COPY.SUMMARY_NIGHTS_PLURAL : COPY.SUMMARY_NIGHTS_SINGULAR
  }`;

  const newGuestsBold = <b>{COPY.NEW_GUESTS_BOLD}</b>;
  return (
    <>
      {hasNewGuests && <SummaryContent.NewGuestsMessage copy={COPY.NEW_GUESTS_MESSAGE} newCopy={newGuestsBold} />}
      <SummaryContent.Item>
        <CheckInOutDate>
          <CheckInOutDate.Label fontSize="12px" mt="10px" lineHeight="18px" height="15px">
            {COPY.SUMMARY_CHECKIN}
          </CheckInOutDate.Label>
          <CheckInOutDate.Date fontSize="20px" height="15px" lineHeight="24px">
            {checkIn}
          </CheckInOutDate.Date>
        </CheckInOutDate>
        <Flex mt="32px" flex="1" justifyContent="center">
          <ArrowRightIcon color={theme.colors.deepLakeBlue[100]} size={21} />
        </Flex>
        <CheckInOutDate>
          <CheckInOutDate.Label fontSize="12px" mt="10px" lineHeight="18px" height="15px">
            {COPY.SUMMARY_CHECKOUT}
          </CheckInOutDate.Label>
          <CheckInOutDate.Date fontSize="20px" height="15px" lineHeight="24px">
            {checkOut}
          </CheckInOutDate.Date>
        </CheckInOutDate>
      </SummaryContent.Item>
      <SummaryContent.Item>
        <SummaryContent.Description>
          <Text fontSize={0} fontWeight={700} lineHeight="16px">
            {numberOfNightsLabel}
          </Text>
        </SummaryContent.Description>
      </SummaryContent.Item>
    </>
  );
};

Header.propTypes = propTypes;

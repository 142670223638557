import Axios from 'axios';
import { push } from 'connected-react-router';
import get from 'lodash-es/get';
import { reverse } from 'named-urls';
import { animateScroll as Scroll } from 'react-scroll';
import ReservationsApiClient from '../../api/clients/ReservationsApiClient';
import { fillVaultedCardDetails } from '../../components/PaymentForm/PaymentFormHelper';
import routes from '../../routes';
import {
  logFindYourReservationConfNumber,
  logFindYourReservationLoginFail,
  logFindYourReservationLoginSuccess
} from '../../utilities/analytics';
import {
  EVENT_CATEGORIES,
  trackInteractionFailureEvent,
  trackReservationFailureEvent
} from '../../utilities/applicationInsights';
import { SuiteStatus } from '../../utilities/availabilityUtils';
import { CUSTOMER_CONTACT_CENTER_NUM } from '../../utilities/constants';
import { getErrorMessage } from '../../utilities/messageUtils';
import { LocalStorageKeys, initializeState } from '../../utilities/storageUtils';
import {
  failureType as reservationDetailsFailureType,
  successType as reservationDetailsSuccessType
} from './reservationDetailsEntity';
import { gaTrackingCheckoutErrorCode } from './utilities/util';

import AuthClient from '../../api/auth/AuthClient';
import { actionCreators as accountActionCreators } from './Account';
import { removeAllActiveOffersType } from './Offer';

const getReservationByIdSuccessType = 'GET_RESERVATION_BY_ID_SUCCESS';
const getReservationByIdLoadingType = 'GET_RESERVATION_BY_ID_LOADING';
const getReservationByIdErrorType = 'GET_RESERVATION_BY_ID_ERROR';
const getReservationByIdFinishType = 'GET_RESERVATION_BY_ID_FINISH';

const getReservationInfoSuccessType = 'GET_RESERVATION_INFO_SUCCESS';
const getReservationInfoLoadingType = 'GET_RESERVATION_INFO_LOADING';
const getReservationInitialInfoLoadingType = 'GET_RESERVATION_INITIAL_INFO_LOADING';
const getReservationInfoErrorType = 'GET_RESERVATION_INFO_ERROR';
const getReservationInfoFinishType = 'GET_RESERVATION_INFO_FINISH';

const createReservationLoadingType = 'CREATE_RESERVATION_LOADING';
const createReservationErrorType = 'CREATE_RESERVATION_ERROR';
const createReservationSuccessType = 'CREATE_RESERVATION_SUCCESS';
const createReservationTempUserInfoType = 'CREATE_RESERVATION_TEMP_USER';
const clearReservationErrorType = 'CLEAR_RESERVATION_ERROR';

const putReservationCancelSuccessType = 'PUT_RESERVATION_CANCEL_SUCCESS';
const putReservationCancelUpdateType = 'PUT_RESERVATION_CANCEL_UPDATE';

const setLastNameFindReservationById = 'SET_LAST_NAME_FIND_RESERVATION_BY_ID';
const setReservationkeyFindReservationById = 'SET_RESERVATION_KEY_FIND_RESERVATION_BY_ID';

export const logNewRevervationOrDayPassType = 'GTM_NEW_RESERVATION_OR_DAYPASS';

/**
 * Type of guest.
 * @enum {string}
 */
export const rateInfoField = {
  ADVANCE_DEPOSIT: 'advancedDeposit',
  TAXES: 'taxes',
  TOTAL: 'total',
  TOTAL_ROOM_RATE: 'totalRoomRate',
  RESORT_FEE: 'resortFee',
  TOTAL_ROOM_RATE_AND_PACKAGES: 'totalRoomRateAndPackages'
};

// SET AN INITIAL STATE FOR REDUX
const initialState = {
  tempUserInfo: {},
  confirmedReservation: {},
  createdReservation: {},
  reservationList: [],
  upcomingReservationList: [],
  pastReservationList: [],
  cancelledReservationList: [],
  isLoading: false,
  isInitialLoading: false,
  requestFailed: false,
  requestHasFinished: false,
  reservationUser: undefined,
  errorMessage: '',
  status: '',
  cancellationHasFailed: false,
  lastNameFindReservationById: '',
  reservationKeyFindReservationById: { key: '', reservationId: '' }
};

const createReservationStatuses = {
  PARTIAL_SUCCESS: 1,
  SUCCESS: 2,
  ALT_PAYMENT_ERROR: 3
};

const ERROR_MESSAGES = {
  default: 'Oops - we had a problem with processing the information.',
  OWS_SYSTEM_ERROR: `Unfortunately, there is been an error processing your credit card. Please contact our call center at ${CUSTOMER_CONTACT_CENTER_NUM} in order to complete your reservation`,
  CREDIT_CARD_AUTHORIZATION_FAILED: 'Credit card authorization has failed. Please check the numbers and try again.',
  PAYPAL_AUTHORIZATION_FAILED:
    'PayPal authorization has failed. Please select a different payment method and try again.',
  INVALID_CREDIT_CARD_EXPIRATION: 'The credit card expiration date is invalid. Please check the numbers and try again.',
  INVALID_RATE_CODE_FOR_PROMOTION_CODE:
    'The Offer Code you have entered does not match the Rate Code, this may be caused by multiple browser tabs opened to the Great Wolf website. Please close additional tabs and try your booking again.',
  RATE_CODE_NOT_AVAILABLE:
    'The Offer Code you have entered is no longer valid, please visit our deals page to find our latest offers.',
  ROOM_UNAVAILABLE:
    'We apologize for the inconvenience, but the room you are trying to book is no longer available, please go back and select another room type.',
  RATES_MISMATCH:
    'We apologize for the inconvenience, but the rates of the room you are trying to book has changed, please note cost summary has been updated with the new rates',
  AVAILABILITY_KEY_NOT_FOUND:
    'Booking availability has expired. To proceed with your booking, please return to the suites page and start again.'
};

const ERROR_TYPES = {
  rateMismatch: 'RATES_MISMATCH'
};

/**
 *
 * IMPORTANT!!!
 * The validation of reservation.isCabana is required to avoid showing cabanas reservations
 * this was implemented by story 30448, this is just a FE fix, the fix needs to be completed
 * on the BE and will be implemented on another story
 */
function reservationListHandler(reservationData) {
  let reservationList = reservationData;
  let _now = new Date();

  // Split the reservation list into upcoming, past and cancelled reservation list
  // Should be done right here. Placing this declarations in some other side may
  // lead this operations being executed multiple times
  let upcomingReservationList = reservationList.filter(reservation => {
    const arrivalDate = new Date(reservation?.arrival);
    let arrivalEndOfDay = new Date(
      arrivalDate.getFullYear(),
      arrivalDate.getMonth(),
      arrivalDate.getDate(),
      23,
      59,
      59
    );
    return arrivalEndOfDay >= _now && !reservation?.isCanceled && reservation?.isCabana === false;
  });
  let pastReservationList = reservationList.filter(reservation => {
    const arrivalDate = new Date(reservation?.arrival);
    let arrivalEndOfDay = new Date(
      arrivalDate.getFullYear(),
      arrivalDate.getMonth(),
      arrivalDate.getDate(),
      23,
      59,
      59
    );
    return arrivalEndOfDay < _now && !reservation?.isCanceled && reservation?.isCabana === false;
  });
  let cancelledReservationList = reservationList.filter(
    reservation => reservation?.isCanceled && reservation?.isCabana === false
  );

  const filteredReservations = {
    upcomingReservationList: upcomingReservationList,
    pastReservationList: pastReservationList,
    cancelledReservationList: cancelledReservationList
  };

  return filteredReservations;
}

/**
 * Check if create reservation response is success
 *
 * @function
 * @param {object} response create reservation response
 */
function validateCreateReservationResponse(response) {
  return (
    (response.status === createReservationStatuses.SUCCESS ||
      response.status === createReservationStatuses.PARTIAL_SUCCESS) &&
    response.reservation &&
    (response.reservation?.id || response.reservation?.externalId)
  );
}

export const actionCreators = {
  /**
   * Action to create a reservation
   * @param {object} reservationData Reservation POST Payload
   * @param {function} onRatesMismatchUpdate Callback to call once a Rate Mismatch error is found
   * @param {function} setSubmittingCallback Callback to tell Formik Form to enable book buton on Rate Mismatch
   * @returns
   */
  createReservation: (
    reservationData,
    onRatesMismatchUpdate = null,
    setSubmittingCallback = null,
    provider = null
  ) => dispatch => {
    dispatch({
      type: createReservationLoadingType,
      isLoading: true
    });

    dispatch({
      type: createReservationTempUserInfoType,
      tempUserInfo: reservationData
    });

    let request;
    const authClient = new AuthClient();
    const apiClient = new ReservationsApiClient('v2.1');

    if (reservationData.payment.type === 1 && reservationData.isTokenisationEnabled) {
      request = fillVaultedCardDetails(
        reservationData.payment.creditCard,
        reservationData.address,
        reservationData.details?.selection?.property
      ).then(reservationDataWithVaultedCard => {
        Object.assign(reservationData.payment.creditCard, reservationDataWithVaultedCard);

        return apiClient.createReservation(reservationData);
      });
    } else {
      if (reservationData.payment.type === 1) {
        reservationData.payment.creditCard.vaultedCardId = reservationData.payment.creditCard.lastFourDigits =
          'not applicable';
      }
      request = apiClient.createReservation(reservationData);
    }

    const onRequestEnd = (isSuccess = false) => {
      if (!isSuccess) {
        Scroll.scrollToTop();
      }

      dispatch({
        type: createReservationLoadingType,
        isLoading: false
      });
      dispatch({
        type: removeAllActiveOffersType
      });
    };

    return new Promise(function(resolve, reject) {
      request.then(
        response => {
          if (validateCreateReservationResponse(response.data)) {
            try {
              const {
                packages,
                selection: { suiteContent }
              } = reservationData.details;
              if (provider) {
                response.data.reservation.paymentType = provider === 'googlePay' ? 'GP' : 'AP';
              }
              dispatch({
                type: logNewRevervationOrDayPassType,
                response: {
                  reservation: response.data.reservation,
                  packages,
                  suiteContent
                }
              });
            } catch (error) {
              trackInteractionFailureEvent(error, 'interactions_not_pushed_server');
            } finally {
              dispatch({
                type: reservationDetailsSuccessType,
                response: response.data.reservation
              });

              onRequestEnd(true);

              // UPDATE HERE

              // If there's a token in sessionStorage, call worker to invalidate that token
              const token = sessionStorage.getItem(LocalStorageKeys.SALE_TOKEN);
              if (token) {
                sessionStorage.removeItem(LocalStorageKeys.SALE_TOKEN);
                const config = {
                  method: 'post',
                  url: `https://sales-queue-token.great-wolf-resorts.workers.dev/delete-token`,
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  data: JSON.stringify({ token })
                };

                Axios(config);
              }

              let userIsLoggedIn = false;
              const isUserLoggedIn = authClient.isLoggedIn();
              if (isUserLoggedIn) {
                userIsLoggedIn = true;
              }

              if (userIsLoggedIn) {
                dispatch(accountActionCreators.updateUserPointsAndOffers(authClient.getUserId()));
              }
              dispatch(
                push(reverse(routes.plan.paymentSuccess), {
                  newReservation: true
                })
              );
              return resolve(response.data);
            }
          }

          trackReservationFailureEvent(
            EVENT_CATEGORIES.bookingEngineBookReservationError,
            'Validate create reservation response error'
          );

          dispatch({
            type: createReservationErrorType,
            errorMessage: getErrorMessage(ERROR_MESSAGES.default, false)
          });
          onRequestEnd(false);
          return reject(response);
        },
        error => {
          const rawError = get(error, 'err.response.data.Error.ErrorMessage', error.err.message);
          trackReservationFailureEvent(
            EVENT_CATEGORIES.bookingEngineBookReservationError,
            'Book a reservation request failure.',
            rawError
          );
          const errorCode = rawError && Object.keys(ERROR_MESSAGES).find(errorCode => rawError.includes(errorCode));
          let errorMessage = ERROR_MESSAGES[errorCode] || ERROR_MESSAGES.default;

          //This is the treatment to show the error when the availability key expires
          //==========================================================================
          if (get(error, 'err.response.data.Error.ErrorCode') === 'CRS006')
            errorMessage = ERROR_MESSAGES.AVAILABILITY_KEY_NOT_FOUND;

          dispatch({
            type: createReservationErrorType,
            errorMessage: getErrorMessage(errorMessage, false)
          });

          gaTrackingCheckoutErrorCode(JSON.stringify(error));

          const isARateMismatchError = errorCode ? errorCode === ERROR_TYPES.rateMismatch : false;

          // If is a rate mismatch error
          if (isARateMismatchError && onRatesMismatchUpdate && typeof onRatesMismatchUpdate === 'function') {
            const newRates = get(error, 'err.response.data.Error.ErrorData', null);
            gaTrackingCheckoutErrorCode('RATES_MISMATCH');
            onRatesMismatchUpdate(JSON.parse(newRates), setSubmittingCallback).then(() => {
              onRequestEnd(false);
              return reject(error);
            });
          }

          onRequestEnd(false);
          reject(error);
        }
      );
    });
  },

  createDayPass: reservationData => dispatch => {
    dispatch({
      type: createReservationLoadingType,
      isLoading: true
    });

    let apiClient = new ReservationsApiClient();
    let request;
    if (reservationData.payment.type === 1 && reservationData.isTokenisationEnabled) {
      request = fillVaultedCardDetails(
        reservationData.payment.creditCard,
        reservationData.address,
        reservationData.details?.selection?.property
      ).then(reservationDataWithVaultedCard => {
        Object.assign(reservationData.payment.creditCard, reservationDataWithVaultedCard);

        return apiClient.createDayPass(reservationData);
      });
    } else {
      if (reservationData.payment.type === 1) {
        reservationData.payment.creditCard.vaultedCardId = reservationData.payment.creditCard.lastFourDigits =
          'not applicable';
      }
      request = apiClient.createDayPass(reservationData);
    }

    return new Promise((resolve, reject) => {
      request
        .then(
          response => {
            try {
              const {
                packages,
                selection: { suiteContent }
              } = reservationData.details;
              dispatch({
                type: logNewRevervationOrDayPassType,
                response: {
                  reservation: response.data.reservation,
                  packages,
                  suiteContent
                }
              });
            } catch (error) {
              trackInteractionFailureEvent(error, 'interactions_not_pushed_server');
            } finally {
              dispatch({
                type: reservationDetailsSuccessType,
                response: response.data.reservation
              });
              dispatch(
                push(routes.daypass.paymentSuccess, {
                  newReservation: true
                })
              );
              resolve(response.data);
            }
          },
          error => {
            const rawError = error?.message;
            const errorCode = rawError && Object.keys(ERROR_MESSAGES).find(errorCode => rawError.includes(errorCode));
            const errorMessage = ERROR_MESSAGES[errorCode] || ERROR_MESSAGES.default;
            dispatch({
              type: createReservationErrorType,
              errorMessage: getErrorMessage(errorMessage, false)
            });

            reject(error);
          }
        )
        .finally(() =>
          dispatch({
            type: createReservationLoadingType,
            isLoading: false
          })
        );
    });
  },

  updateReservationStatus: status => ({
    status: status,
    type: putReservationCancelUpdateType
  }),

  updateReservationStatusItem: (item, status) => (item.status = status),

  // Perform the request search by Phone and Cc to API endpoint
  getReservationsByPhoneAndCc: (phone, creditCard) => dispatch => {
    dispatch({ type: getReservationInfoLoadingType, isLoading: true });

    // This way is the one we are going to use for get the Authorization from the AuthClient
    const apiClient = new ReservationsApiClient();
    const request = apiClient.getReservationsByPhoneAndCc(phone, creditCard);

    return request.then(
      response => {
        const { upcomingReservationList, pastReservationList, cancelledReservationList } = reservationListHandler(
          response.data
        );
        dispatch({
          type: getReservationInfoSuccessType,
          reservationList: response.data,
          upcomingReservationList: upcomingReservationList,
          pastReservationList: pastReservationList,
          cancelledReservationList: cancelledReservationList,
          reservationUser: undefined
        });
      },
      err => {
        dispatch({
          type: getReservationInfoErrorType,
          reservationList: [],
          reservationUser: undefined,
          requestFailed: true,
          errorMessage: getErrorMessage(err.message)
        });
      }
    );
  },

  // Perform the request search by Email and Cc to API endpoint
  getReservationsByEmailAndCc: (email, creditCard) => (dispatch, getState) => {
    dispatch({ type: getReservationInfoLoadingType, isLoading: true });

    let apiClient = new ReservationsApiClient('v2', process.env.REACT_APP_USER_TOKEN);
    const request = apiClient.getReservationsByEmailAndCc(email, creditCard);

    return request.then(
      response => {
        let { upcomingReservationList, pastReservationList, cancelledReservationList } = reservationListHandler(
          response.data
        );
        dispatch({
          type: getReservationInfoSuccessType,
          reservationList: response.data,
          upcomingReservationList: upcomingReservationList,
          pastReservationList: pastReservationList,
          cancelledReservationList: cancelledReservationList,
          reservationUser: undefined
        });
      },
      err => {
        dispatch({
          type: getReservationInfoErrorType,
          reservationList: [],
          reservationUser: undefined,
          requestFailed: true,
          errorMessage: getErrorMessage(err.message)
        });
      }
    );
  },

  goToMyReservationPage: page => (dispatch, getState) => {
    dispatch(
      push({
        pathname: page,
        state: { shouldBlockUserReservationsOnStartup: true }
      })
    );
    dispatch({
      type: getReservationInfoFinishType,
      requestHasFinished: false
    });
  },

  getReservationById: reservationId => dispatch => {
    dispatch({ type: getReservationByIdLoadingType, isLoading: true });

    const apiClient = new ReservationsApiClient();

    const request = apiClient.getReservationsById(reservationId);

    return request
      .then(
        response => {
          dispatch({
            type: getReservationByIdSuccessType,
            confirmedReservation: response.data
          });
        },
        err => {
          dispatch({
            type: getReservationByIdErrorType,
            confirmedReservation: {},
            errorMessage: getErrorMessage(err.message)
          });
        }
      )
      .finally(() => {
        dispatch({
          type: getReservationByIdFinishType,
          requestHasFinished: true
        });
      });
  },

  getReservationsByReservationIdAndReservationKey: (
    reservationId,
    reservationKey,
    callback = () => {},
    errorCallback = () => {}
  ) => async dispatch => {
    dispatch({ type: getReservationInfoLoadingType, isLoading: true });
    dispatch({
      type: getReservationInfoFinishType,
      requestHasFinished: false,
      errorMessage: '',
      requestFailed: false
    });

    const apiClient = new ReservationsApiClient('v2.1');

    try {
      logFindYourReservationConfNumber();

      const request = await apiClient.getReservationsByIdAndReservationKey(reservationId, reservationKey);
      dispatch({
        type: setReservationkeyFindReservationById,
        reservationIdKey: { key: reservationKey, reservationId: reservationId }
      });
      dispatch({ type: reservationDetailsSuccessType, response: request.data });

      logFindYourReservationLoginSuccess();
    } catch (err) {
      logFindYourReservationLoginFail();

      const errorMessage = getErrorMessage(err);
      dispatch({ type: reservationDetailsFailureType, message: errorMessage });
      errorCallback(err);
    } finally {
      dispatch({
        type: getReservationInfoFinishType,
        requestHasFinished: true,
        isLoading: false
      });
      callback();
    }
  },

  // Perform the request search by Reservation Id and Last Name to API endpoint
  getReservationsByReservationIdAndLastName: (
    reservationId,
    lastName,
    callback = () => {},
    errorCallback = () => {}
  ) => async dispatch => {
    dispatch({ type: getReservationInfoLoadingType, isLoading: true });
    dispatch({
      type: getReservationInfoFinishType,
      requestHasFinished: false,
      errorMessage: '',
      requestFailed: false
    });

    const apiClient = new ReservationsApiClient();
    try {
      const request = await apiClient.getReservationsByIdAndLastName(reservationId, lastName);
      dispatch({ type: setLastNameFindReservationById, lastName: lastName });
      dispatch({ type: reservationDetailsSuccessType, response: request.data });
      callback(request);
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      dispatch({ type: reservationDetailsFailureType, message: errorMessage });
      dispatch({
        type: getReservationInfoFinishType,
        requestHasFinished: true,
        errorMessage: errorMessage,
        requestFailed: errorMessage ? true : false
      });
      errorCallback(err);
    } finally {
      dispatch({
        type: getReservationInfoFinishType,
        requestHasFinished: true,
        isLoading: false
      });
    }
  },

  // We can't manage the modal variables globally in Redux, because the modal has to be tied per array object
  cancelReservation: reservationId => (dispatch, getState) => {
    dispatch({ type: getReservationInfoLoadingType, isLoading: true });

    let apiClient = new ReservationsApiClient('v2', process.env.REACT_APP_USER_TOKEN);
    const request = apiClient.cancelReservationById(reservationId);

    return request.then(
      response => {
        // Set failed to false, because it succeded!
        dispatch({
          type: putReservationCancelSuccessType,
          cancellationHasFailed: false
        });
        // Change the status to canceled
        dispatch({
          type: putReservationCancelUpdateType,
          reservationId: reservationId,
          status: SuiteStatus.CANCELLED
        });
        // Change the status to canceled
        dispatch({ type: getReservationInfoLoadingType, isLoading: false });
      },
      err => {
        dispatch({
          type: putReservationCancelSuccessType,
          cancellationHasFailed: true
        });
        dispatch({ type: getReservationInfoLoadingType, isLoading: false });
      }
    );
  },

  getAllReservationsForCurrentUser: () => async dispatch => {
    dispatch({
      type: getReservationInitialInfoLoadingType,
      isInitialLoading: true
    });
    dispatch({
      type: getReservationInfoFinishType,
      requestHasFinished: false
    });

    try {
      const apiClient = new ReservationsApiClient();
      const response = await apiClient.getAllReservationsForCurrentUser();

      const { upcomingReservationList, pastReservationList, cancelledReservationList } = reservationListHandler(
        response.data
      );
      dispatch({
        type: getReservationInfoSuccessType,
        reservationList: response.data,
        upcomingReservationList: upcomingReservationList,
        pastReservationList: pastReservationList,
        cancelledReservationList: cancelledReservationList,
        reservationUser: undefined
      });
      dispatch({
        type: getReservationInfoFinishType,
        requestHasFinished: true
      });
    } catch (err) {
      dispatch({
        type: getReservationInfoErrorType,
        reservationList: [],
        reservationUser: undefined,
        requestFailed: true,
        errorMessage: getErrorMessage(err.message)
      });
      dispatch({
        type: getReservationInfoFinishType,
        requestHasFinished: true
      });
    }
  },

  clearReservationRequestFaliedErrors: () => (dispatch, getState) => {
    dispatch({
      type: clearReservationErrorType
    });
  }
};

// This reducer combines reducers for providing new values
// to the Redux store based on actions defined above
//
// If the TYPE matches the action, the new state will replace the old
// this occurs on a per action basis and you should see the Redux store
// (state tree) reflect the change

const reducer = (state, action) => {
  state = initializeState(state, initialState);

  // Get Book information reducers
  switch (action.type) {
    case setLastNameFindReservationById:
      return {
        ...state,
        lastNameFindReservationById: action.lastName
      };
    case createReservationSuccessType:
      return {
        ...state,
        tempUserInfo: {
          ...state.tempUserInfo,
          customer: {
            ...state.tempUserInfo.customer
          },
          details: {
            ...state.tempUserInfo.details
          },
          address: {
            ...state.tempUserInfo.address
          },
          payment: {
            ...state.tempUserInfo.payment
          }
        },
        confirmedReservation: {
          ...state.confirmedReservation
        },
        createdReservation: action.createdReservation,
        reservationList: [...state.reservationList],
        upcomingReservationList: [...state.upcomingReservationList],
        pastReservationList: [...state.pastReservationList],
        cancelledReservationList: [...state.cancelledReservationList]
      };
    case createReservationLoadingType:
      return {
        ...state,
        tempUserInfo: {
          ...state.tempUserInfo,
          customer: {
            ...state.tempUserInfo.customer
          },
          details: {
            ...state.tempUserInfo.details
          },
          address: {
            ...state.tempUserInfo.address
          },
          payment: {
            ...state.tempUserInfo.payment
          }
        },
        confirmedReservation: {
          ...state.confirmedReservation
        },
        createdReservation: {
          ...state.createdReservation,
          reservation: {
            ...state.createdReservation.reservation
          }
        },
        reservationList: [...state.reservationList],
        upcomingReservationList: [...state.upcomingReservationList],
        pastReservationList: [...state.pastReservationList],
        cancelledReservationList: [...state.cancelledReservationList],
        isLoading: action.isLoading
      };
    case createReservationErrorType:
      return {
        ...state,
        tempUserInfo: {
          ...state.tempUserInfo,
          customer: {
            ...state.tempUserInfo.customer
          },
          details: {
            ...state.tempUserInfo.details
          },
          address: {
            ...state.tempUserInfo.address
          },
          payment: {
            ...state.tempUserInfo.payment
          }
        },
        confirmedReservation: {
          ...state.confirmedReservation
        },
        createdReservation: {
          ...state.createdReservation,
          reservation: {
            ...state.createdReservation.reservation
          }
        },
        reservationList: [...state.reservationList],
        upcomingReservationList: [...state.upcomingReservationList],
        pastReservationList: [...state.pastReservationList],
        cancelledReservationList: [...state.cancelledReservationList],
        errorMessage: action.errorMessage
      };
    case getReservationByIdSuccessType:
      return {
        ...state,
        tempUserInfo: {
          ...state.tempUserInfo,
          customer: {
            ...state.tempUserInfo.customer
          },
          details: {
            ...state.tempUserInfo.details
          },
          address: {
            ...state.tempUserInfo.address
          },
          payment: {
            ...state.tempUserInfo.payment
          }
        },
        confirmedReservation: action.confirmedReservation,
        createdReservation: {
          ...state.createdReservation,
          reservation: {
            ...state.createdReservation.reservation
          }
        },
        reservationList: [...state.reservationList],
        upcomingReservationList: [...state.upcomingReservationList],
        pastReservationList: [...state.pastReservationList],
        cancelledReservationList: [...state.cancelledReservationList],
        requestFailed: false,
        isLoading: false,
        errorMessage: ''
      };

    case getReservationByIdLoadingType:
      return {
        ...state,
        tempUserInfo: {
          ...state.tempUserInfo,
          customer: {
            ...state.tempUserInfo.customer
          },
          details: {
            ...state.tempUserInfo.details
          },
          address: {
            ...state.tempUserInfo.address
          },
          payment: {
            ...state.tempUserInfo.payment
          }
        },
        confirmedReservation: {
          ...state.confirmedReservation
        },
        createdReservation: {
          ...state.createdReservation,
          reservation: {
            ...state.createdReservation.reservation
          }
        },
        reservationList: [...state.reservationList],
        upcomingReservationList: [...state.upcomingReservationList],
        pastReservationList: [...state.pastReservationList],
        cancelledReservationList: [...state.cancelledReservationList],
        isLoading: action.isLoading
      };

    case getReservationByIdErrorType:
      return {
        ...state,
        tempUserInfo: {
          ...state.tempUserInfo,
          customer: {
            ...state.tempUserInfo.customer
          },
          details: {
            ...state.tempUserInfo.details
          },
          address: {
            ...state.tempUserInfo.address
          },
          payment: {
            ...state.tempUserInfo.payment
          }
        },
        confirmedReservation: {
          ...initialState.confirmedReservation
        },
        createdReservation: {
          ...state.createdReservation,
          reservation: {
            ...state.createdReservation.reservation
          }
        },
        reservationList: [...state.reservationList],
        upcomingReservationList: [...state.upcomingReservationList],
        pastReservationList: [...state.pastReservationList],
        cancelledReservationList: [...state.cancelledReservationList],
        isLoading: false
      };

    case getReservationByIdFinishType:
      return {
        ...state,
        tempUserInfo: {
          ...state.tempUserInfo,
          customer: {
            ...state.tempUserInfo.customer
          },
          details: {
            ...state.tempUserInfo.details
          },
          address: {
            ...state.tempUserInfo.address
          },
          payment: {
            ...state.tempUserInfo.payment
          }
        },
        confirmedReservation: {
          ...state.confirmedReservation
        },
        createdReservation: {
          ...state.createdReservation
        },
        reservationList: [...state.reservationList],
        upcomingReservationList: [...state.upcomingReservationList],
        pastReservationList: [...state.pastReservationList],
        cancelledReservationList: [...state.cancelledReservationList],
        requestHasFinished: action.requestHasFinished
      };

    // Reservation item request succeded
    case getReservationInfoSuccessType:
      return {
        ...state,
        confirmedReservation: action.confirmedReservation,
        reservationList: action.reservationList,
        upcomingReservationList: action.upcomingReservationList,
        pastReservationList: action.pastReservationList,
        cancelledReservationList: action.cancelledReservationList,
        reservationUser: action.reservationUser,
        requestFailed: false,
        isLoading: false,
        isInitialLoading: false,
        errorMessage: ''
      };
    // The request is currently running
    case getReservationInfoLoadingType:
      return {
        ...state,
        isLoading: action.isLoading
      };
    // Reservation item request failed
    case getReservationInfoErrorType:
      return {
        ...state,
        reservationList: action.reservationList,
        upcomingReservationList: [],
        pastReservationList: [],
        cancelledReservationList: [],
        reservationUser: action.reservationUser,
        requestFailed: action.requestFailed,
        errorMessage: action.errorMessage,
        isLoading: false,
        isInitialLoading: false
      };
    // Reservation item request finished
    case getReservationInfoFinishType:
      const getValidValue = (value, key) => (value !== undefined ? value : state[key]);

      const newIsLoadingValue = getValidValue(action.isLoading, 'isLoading');
      const newErrorMessageValue = getValidValue(action.errorMessage, 'errorMessage');
      const newRequestFailedValue = getValidValue(action.requestFailed, 'requestFailed');

      return {
        ...state,
        requestHasFinished: action.requestHasFinished,
        isLoading: newIsLoadingValue,
        errorMessage: newErrorMessageValue,
        requestFailed: newRequestFailedValue
      };
    // Cancel Reservation succeded/failed
    case putReservationCancelSuccessType:
      return {
        ...state,
        cancellationHasFailed: action.cancellationHasFailed
      };
    // Update the status of the registry
    case putReservationCancelUpdateType:
      // Get the reservation by Id, and then only refresh/update that element inside the array
      const reservationListAux = state.reservationList;
      let foundIndex = reservationListAux.findIndex(x => x.id === action.reservationId);
      reservationListAux[foundIndex] = {
        ...reservationListAux[foundIndex],
        status: action.status
      };

      let { upcomingReservationList, pastReservationList, cancelledReservationList } = reservationListHandler(
        reservationListAux
      );

      return {
        ...state,
        reservationList: reservationListAux,
        upcomingReservationList: upcomingReservationList,
        pastReservationList: pastReservationList,
        cancelledReservationList: cancelledReservationList
      };

    case createReservationTempUserInfoType:
      return {
        ...state,
        tempUserInfo: {
          ...action.tempUserInfo
        },
        confirmedReservation: {
          ...state.confirmedReservation
        },
        createdReservation: {
          ...state.createdReservation
        },
        reservationList: [...state.reservationList],
        upcomingReservationList: [...state.upcomingReservationList],
        pastReservationList: [...state.pastReservationList],
        cancelledReservationList: [...state.cancelledReservationList]
      };
    // Action to clear the errors
    case clearReservationErrorType:
      return {
        ...state,
        requestFailed: false,
        errorMessage: ''
      };

    // Action to set initial loading.
    case getReservationInitialInfoLoadingType:
      return {
        ...state,
        isInitialLoading: action.isInitialLoading
      };

    case setReservationkeyFindReservationById:
      return {
        ...state,
        reservationKeyFindReservationById: action.reservationIdKey
      };

    default:
      return state;
  }
};

// Selectors
export const getReservationIsLoading = state => {
  return state.isLoading;
};

export const getLastNameFindReservationById = state => {
  return state.lastNameFindReservationById;
};

export const getReservationKeyFindReservationById = state => {
  return state.reservationKeyFindReservationById;
};

export default reducer;

import PropTypes from 'prop-types';

import Heading from '../../ui-kit/Heading';
import Text from '../../ui-kit/Text';
import ArrowCaretLeft from '../../ui-kit/icons/ArrowCaretLeft';
import CloseIcon from '../../ui-kit/icons/Close';
import theme from '../../ui-kit/themes/theme';

import { BackButton, CloseButton, HeadingContainer } from './styles';

const ModalHeader = ({
  title,
  fontSize,
  lineHeight,
  onCloseModal,
  backButton,
  backButtonSize,
  backButtonVertPosValue,
  onBackButtonClick,
  closeButton,
  titleColor,
  closeBtnPosition,
  closeBtnSize,
  closeBtnVertPosValue,
  closeBtnHorizonPosValue,
  closeBtnColor
}) => {
  return (
    <HeadingContainer hasTitle={title} color="preciousStoneBlue.100">
      {title && (
        <Heading textAlign="center" fontSize={7} mb={4} mt={0} color={titleColor} lineHeight={[22, 24]}>
          <Text lineHeight={lineHeight && lineHeight} fontSize={fontSize && fontSize}>
            {title}
          </Text>
        </Heading>
      )}
      {closeButton && (
        <CloseButton
          type="button"
          aria-label="close"
          closeBtnPosition={closeBtnPosition}
          closeBtnVertPosValue={closeBtnVertPosValue}
          closeBtnHorizonPosValue={closeBtnHorizonPosValue}
          closeBtnColor={closeBtnColor}
          onClick={onCloseModal}>
          <CloseIcon size={closeBtnSize} color={closeBtnColor ? closeBtnColor : theme.colors.preciousStoneBlue[100]} />
        </CloseButton>
      )}
      {backButton && (
        <BackButton backButtonVertPosValue={backButtonVertPosValue} onClick={onBackButtonClick}>
          <ArrowCaretLeft size={backButtonSize ? backButtonSize : 10} color={theme.colors.preciousStoneBlue[100]} />
        </BackButton>
      )}
    </HeadingContainer>
  );
};

ModalHeader.defaultProps = {
  title: '',
  onCloseModal: null,
  backButton: false,
  onBackButtonClick: null,
  titleColor: 'preciousStoneBlue.100'
};

ModalHeader.propTypes = {
  title: PropTypes.string,
  onCloseModal: PropTypes.func,
  backButton: PropTypes.bool,
  onBackButtonClick: PropTypes.func,
  titleColor: PropTypes.string
};

export default ModalHeader;

import PropTypes from 'prop-types';
import React from 'react';

import { getCurrencyCodeText } from '../../../../../utilities/planUtils';
import SelectPackageQuantity from '../../../../Packages/SelectPackageQuantity';
import { Box } from '../../../../ui-kit';
import { SummaryContent } from '../../Content';
import * as COPY from '../utilities/copy';

const propTypes = {
  /** Moment date object with the checkin date */
  checkinDateSelection: PropTypes.object.isRequired,

  /** Moment date object with the checkout date */
  checkoutDateSelection: PropTypes.object.isRequired,

  /** Currenty selected packages */
  currentSelectedPackages: PropTypes.arrayOf(
    PropTypes.shape({
      packageCode: PropTypes.string,
      packageName: PropTypes.string,
      currencyCode: PropTypes.string,
      quantity: PropTypes.number,
      amount: PropTypes.number,
      isAdjustment: PropTypes.bool,
      showQuantitySelect: PropTypes.bool
    })
  ),

  /** Number of guests for the reservation */
  numberOfGuests: PropTypes.number.isRequired,

  /** Flag to indicate the property is in Niagara */
  isNiagaraProperty: PropTypes.bool,

  /** Function to update the package quantity */
  upsertPackage: PropTypes.func,

  /** Funtion to delete a package */
  handleDeletePackage: PropTypes.func,

  /** Flag to indicate if the reservation has selected packages */
  hasSelectedPackages: PropTypes.bool
};

const getPackagePriceDisplay = packageItem =>
  `${(parseInt(packageItem.quantity || 1) * packageItem.amount).toFixed(2)}`;

const PackageList = ({
  currentSelectedPackages,
  handleDeletePackage,
  isNiagaraProperty,
  checkinDateSelection,
  checkoutDateSelection,
  numberOfGuests,
  handleUpdatePackageQuantity
}) => {
  if (!currentSelectedPackages || !currentSelectedPackages.length) return null;

  return currentSelectedPackages.map(
    (item, index) =>
      !item.isAdjustment && (
        <SummaryContent.Item key={`packageCode-${index}`}>
          <SummaryContent.Description>{item.packageTitle}</SummaryContent.Description>
          <Box width="80px" style={{ textAlign: 'right' }}>
            {item.quantity && !item.packageType?.includes('dining') ? (
              <SelectPackageQuantity
                isNiagaraProperty={isNiagaraProperty}
                checkinDateSelection={checkinDateSelection}
                checkoutDateSelection={checkoutDateSelection}
                packageItem={item}
                numberOfGuests={numberOfGuests}
                handleUpdatePackageQuantity={handleUpdatePackageQuantity}
              />
            ) : null}
          </Box>
          <SummaryContent.Action onClick={() => handleDeletePackage(item)}>{COPY.REMOVE}</SummaryContent.Action>
          <SummaryContent.Amount>
            ${getPackagePriceDisplay(item)} {getCurrencyCodeText(item.currencyCode)}
          </SummaryContent.Amount>
        </SummaryContent.Item>
      )
  );
};

export const PackagesList = ({
  checkinDateSelection,
  checkoutDateSelection,
  currentSelectedPackages,
  numberOfGuests,
  isNiagaraProperty,
  upsertPackage,
  deletePackage
}) => {
  function handleUpdatePackageQuantity(e, packageItem) {
    upsertPackage({ ...packageItem, quantity: e.target.value });
  }

  return (
    <>
      {currentSelectedPackages.length > 0 && (
        <>
          <PackageList
            currentSelectedPackages={currentSelectedPackages}
            handleDeletePackage={deletePackage}
            isNiagaraProperty={isNiagaraProperty}
            checkinDateSelection={checkinDateSelection}
            checkoutDateSelection={checkoutDateSelection}
            numberOfGuests={numberOfGuests}
            handleUpdatePackageQuantity={handleUpdatePackageQuantity}
          />
          <SummaryContent.Divider />
        </>
      )}
    </>
  );
};

PackagesList.propTypes = propTypes;

PackagesList.defaultProps = {
  isNiagaraProperty: false,
  hasSelectedPackages: false
};

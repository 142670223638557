import moment from 'moment';
import {
  DateRangePicker,
  DayPickerRangeController,
  SingleDatePicker,
  DayPickerSingleDateController,
  DayPicker,
  toISODateString
} from 'react-dates';
import { GwDatesWrapper } from '../../utilities/gwDatesWrapper';
import {
  ANCHOR_LEFT,
  END_DATE,
  HORIZONTAL_ORIENTATION,
  VERTICAL_ORIENTATION,
  VERTICAL_SCROLLABLE,
  START_DATE,
  ISO_FORMAT
} from 'react-dates/constants';

export {
  ANCHOR_LEFT as GW_ANCHOR_LEFT,
  END_DATE as GW_END_DATE,
  HORIZONTAL_ORIENTATION as GW_HORIZONTAL_ORIENTATION,
  VERTICAL_ORIENTATION as GW_VERTICAL_ORIENTATION,
  VERTICAL_SCROLLABLE as GW_VERTICAL_SCROLLABLE,
  START_DATE as GW_START_DATE,
  ISO_FORMAT as GW_ISO_FORMAT
};

// Check to see if the input provided is 'moment' object
const isMoment = inputData => {
  return inputData instanceof moment;
};

// Helper to convert 'moment' object values into standard string dates.
// Input can be an object, an array, or a single value. It would recurse
// through all entries to convert the entire input (all indeces of array
// and all properties of an Object)
const convertMomentToDates = inputData => {
  try {
    // Return null/undefined if input is null/undefined
    if (!inputData) return inputData;

    // Check type of input data
    if (isMoment(inputData)) {
      // Single moment object
      return GwDatesWrapper.createDate(inputData);
    } else if (Array.isArray(inputData)) {
      // Array of potential moment objects
      return inputData.map(val => convertMomentToDates(val));
    } else if ({}.constructor === inputData.constructor) {
      // JSON with potential moment object properties
      return Object.entries(inputData).reduce((acc, [key, val]) => {
        acc[key] = convertMomentToDates(val);
        return acc;
      }, {});
    } else return inputData;
  } catch (ex) {
    console.error('convertMomentToDates', ex);
    return inputData;
  }
};

// Helper to convert standard string dates into 'moment' object values.
// Input can be an object, an array, or a single value. It would recurse
// through all entries to convert the entire input (all indeces of array
// and all properties of an Object)
const convertDatesToMoment = inputData => {
  // Check to see if the input provided is a string in standard date format
  const isDateStr = inputStr => {
    // input needs to be of string type
    if (typeof inputStr !== 'string') return false;
    const formatStr = GwDatesWrapper.gwDateFromat;
    // input needs to have the same length as format string
    if (formatStr.length !== inputStr.length) return false;
    // check input against the default/standard date format
    const formatPieces = formatStr.split('-');
    const inputPieces = inputStr.split('-');

    // check if equal count of pieces based on separator
    if (formatPieces.length !== inputPieces.length) return false;
    // length of each piece
    for (let i = 0; i < formatPieces.length; i++) {
      // if any piece is not same, then its not in desired format
      if (formatPieces[i].length !== inputPieces[i].length) {
        return false;
      }
    }
    // if same count of pieces and all pieces are same length
    // means input string is in standard date format
    return true;
  };

  try {
    // Return null/undefined if input is null/undefined
    if (!inputData) return inputData;

    // Check type of input data
    if (isMoment(inputData)) {
      // moment object
      return inputData; // Nothing to do - already a moment
    } else if (isDateStr(inputData)) {
      // Single string representing a date
      return moment(inputData);
    } else if (Array.isArray(inputData)) {
      // Array of dates
      return inputData.map(val => convertDatesToMoment(val));
    } else if ({}.constructor === inputData.constructor) {
      // JSON with date properties
      return Object.entries(inputData).reduce((acc, [key, val]) => {
        acc[key] = convertDatesToMoment(val);
        return acc;
      }, {});
    } else return inputData;
  } catch (ex) {
    console.error('convertDatesToMoment', ex);
    return inputData;
  }
};

// Change handlers (functions) of given set of props so that outer components can send
// in dates in string format, but downstream 'react-dates' component will
// receive 'moment' objects; also outer component can return dates in string format
// but the converted function from this helper will send 'moment' objects to the
// 'react-dates' component.
const changeHandlersForMoments = (propsWithHandlers, nameOfHandlersToReplace) => {
  const convertedProps = { ...propsWithHandlers };
  nameOfHandlersToReplace.forEach(name => {
    const origHandler = propsWithHandlers[name]; // Original handler that will return date in string format
    if (origHandler) {
      const newHandler = (param1, param2, param3, param4, param5) => {
        // New handler capable of returning moment
        // convert input parameter to dateStr (in case of any moment) and capture output from original handler
        const dateStrOutput = origHandler(
          convertMomentToDates(param1),
          convertMomentToDates(param2),
          convertMomentToDates(param3),
          convertMomentToDates(param4),
          convertMomentToDates(param5)
        );
        // convert output of original handler (which is expected to be a dateStr) into moment and return that
        const convertedOutput = convertDatesToMoment(dateStrOutput);
        return convertedOutput;
      };
      // update props with new hanlder (that receives moment but calls original handler with dateStr)
      convertedProps[name] = newHandler;
    }
  });
  return convertedProps;
};

// ******************************************************************************************
// *** Generic wrapper component that is re-used for creating specific wrapper components ***
// *** in order to provide moment-agnostic versions of 'reac-dates' components ***
// ******************************************************************************************
// Documentation used for names of 'react-dates' library props and handlers in this wrapper component:
// DateRangePicker --> https://github.com/react-dates/react-dates?tab=readme-ov-file#daterangepicker
// GWDayPickerRangeController --> https://github.com/react-dates/react-dates?tab=readme-ov-file#daypickerrangecontroller
// SingleDatePicker --> https://github.com/react-dates/react-dates?tab=readme-ov-file#singledatepicker
// DayPicker --> https://github.com/react-dates/react-dates/blob/master/src/components/DayPicker.jsx
// DayPickerSingleDateController --> https://github.com/react-dates/react-dates/blob/master/src/components/DayPickerSingleDateController.jsx
// ******************************************************************************************
// Additionally, in future, we'll have to find substitutes for these components to remove dependency on
// 'react-dates' library in order to eliminate use of 'moment' from code base. The idea would be to find
// substitutes that can produce current UI/output, using same/similar input (props & hanlders), have no
// reliance on 'moment' library and also provide better run-time chunk size (good support for tree shaking
// and lazy loading).
//
// To get things started, here is an article that talks about a few other date pickers:
//  --> https://blog.logrocket.com/top-react-date-pickers/#comparing-date-pickers
// ******************************************************************************************

const GWReactDatesWrapper = ({ ComponentToWrap, ...props }) => {
  const propsNeedingMoment = ['minDate', 'maxDate', 'startDate', 'endDate', 'date'];
  const handlersForDateConvert = [
    'onDatesChange',
    'onPrevMonthClick',
    'onNextMonthClick',
    'onClose',
    'isDayBlocked',
    'isOutsideRange',
    'isDayHighlighted',
    'initialVisibleMonth'
  ];
  // handlers not included in above 'handlersForDateConvert' list
  //    --> renderCalendarInfo, onOutsideClick, renderCalendarDay, renderDayContents, onFocusChange, renderMonthText, renderMonthElement

  // Change inputs of given set of props so that outer components can send
  // in dates in string format, but downstream 'react-dates' compoent will
  // receive 'moment' objects.

  propsNeedingMoment.forEach(name => {
    if (props[name]) {
      props[name] = convertDatesToMoment(props[name]);
    }
  });

  props = changeHandlersForMoments(props, handlersForDateConvert);

  return <ComponentToWrap {...props} />;
};

export const GWRDateRangePicker = ({ ...props }) => {
  return <GWReactDatesWrapper ComponentToWrap={DateRangePicker} {...props} />;
};

export const GWDayPickerRangeController = ({ ...props }) => {
  return <GWReactDatesWrapper ComponentToWrap={DayPickerRangeController} {...props} />;
};

export const GWSingleDatePicker = ({ ...props }) => {
  return <GWReactDatesWrapper ComponentToWrap={SingleDatePicker} {...props} />;
};

export const GWDayPicker = ({ ...props }) => {
  return <GWReactDatesWrapper ComponentToWrap={DayPicker} {...props} />;
};

export const GWDayPickerSingleDateController = ({ ...props }) => {
  return <GWReactDatesWrapper ComponentToWrap={DayPickerSingleDateController} {...props} />;
};

// Helper functions based on 'react-dates' code from:

//  https://github.com/react-dates/react-dates/blob/be4ef1e52f012a79128021b8f92fdcbcad6cd168/src/utils/isInclusivelyAfterDay.js
export const gwIsInclusivelyAfterDay = (dt1, dt2) => {
  return GwDatesWrapper.isValid(dt1) && GwDatesWrapper.isValid(dt2) && !GwDatesWrapper.isBefore(dt1, dt2);
};

export const gwToISODateString = date => {
  return toISODateString(date);
};

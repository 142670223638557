import { getResortLocationUrl } from '../../utilities/resortLocation';
import { initializeState } from '../../utilities/storageUtils';

const updateCurrentLodgeType = 'UPDATE_CURRENT_LODGE';

const initialState = {
  resortLocation: '',
  resortLocationUrl: '',
  resortData: {}
};

export const actionCreators = {
  updateCurrentLodge: lodges => dispatch => {
    const resortLocationUrl = getResortLocationUrl();

    const locationFromUrl = resortLocationUrl.replace(/\//g, '');

    const currentLocationData = Object.values(lodges).find(location => location.url === locationFromUrl);

    if (currentLocationData) {
      dispatch({
        type: updateCurrentLodgeType,
        resortData: currentLocationData,
        resortLocationUrl: resortLocationUrl
      });
    }
  }
};

export const reducer = (state, action) => {
  state = initializeState(state, initialState);
  if (action.type === updateCurrentLodgeType) {
    return {
      ...state,
      resortData: action.resortData,
      resortLocation: action.resortData.operaCode,
      resortLocationUrl: action.resortLocationUrl
    };
  }
  return state;
};

export const getResortLocation = state => {
  return state.resortLocation;
};

export const getResortLocationUrlParam = state => {
  return state.resortLocationUrl;
};
export const getCurrentResort = state => {
  return state.resortData;
};

export const getCurrentResortPhone = state => {
  return state.resortData.phone;
};

export const getCurrentResortDisplay = state => {
  return state.resortData.display;
};

export const getCurrentResortDataUrl = state => {
  return state.resortData.url;
};

import { API_URLS, getApiUrl } from '../Utils/apiURLConfiguration';
import GWRApiClient from './GWRApiClient';

/**
 * Concrete implementation for the Birthdays endpoints
 *
 * @class BirthdaysApiClient
 * @extends {GWRApiClient}
 */
export default class BirthdaysApiClient extends GWRApiClient {
  /**
   * @constructor
   * Calls parent constructor.
   * If there isn't any url provided, then we use the default from the environment variable
   * fills the url and token information
   * @param {string} url
   *  The url where the service endpoints are
   * @param {string} token
   *  The token of the logged user
   */
  constructor(version = '', token = '') {
    let apiVersion = version !== '' ? version : `v2`;

    const urlAux = getApiUrl(API_URLS.REACT_APP_BIRTHDAYS_SERVICE_ENDPOINT, apiVersion);

    super(urlAux, token);
  }

  /**
   * Create a Birthday booking, it sends it to the backend and the backend is going to send
   * and email for the guest and another for the property
   * @param {Object} Birthday payload object
   */
  async createBirthdayBooking(birthdayRequest) {
    return super.postApiCall(this.url, birthdayRequest);
  }
}

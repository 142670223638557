import { Link as ReactRouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { space, themeGet } from 'styled-system';
import { Absolute, Box, Button, Card, Flex, Relative, Text } from '../../components/ui-kit';
import { FlexCard } from '../../components/ui-kit/Card';
import CloseCircleIcon from '../../components/ui-kit/icons/CloseCircle';
import theme, { colors, fontWeights, mediaQueries } from '../../components/ui-kit/themes/theme';
import { IMAGES } from './constants';

export const WaveWrapper = styled(Relative)`
  margin-left: 0;
  padding-top: 80px;
  padding-bottom: 40px;
  left: auto;
  width: 100%;

  background-image: url(${IMAGES.waveSVG}),
    linear-gradient(
      to bottom,
      ${themeGet('colors.preciousStoneBlue.100')} -55%,
      ${themeGet('colors.deepLakeBlue.100')} 97%
    );
  background-repeat: repeat-x;
  background-position: -90px top;
  background-size: 430px, 100%;

  ${themeGet('mediaQueries.lg')} {
    background-position: -170px top;
    padding-top: 96px;
  }

  ${themeGet('mediaQueries.xl')} {
    background-size: 375px, 100%;
    background-position: -100px top;
    padding-top: 70px;
    padding-bottom: 45px;
    margin-top: 32px;
  }
`;

export const MobileAppDevice = styled.img`
  width: 100%;
`;

export const AppFeaturesWrapper = styled.ul`
  padding-left: 0;
  font-family: ${theme.fontSecondary};
  font-size: ${theme.fontSizes[1]}px;
  font-weight: ${fontWeights.medium};
  line-height: 2;
  color: ${theme.colors.deepLakeBlue[100]};
  list-style: none;

  li::before {
    content: '•  ';
    color: ${theme.colors.digitalBrightRed[100]};
    font-weight: ${fontWeights.extraBold};
    font-size: ${theme.fontSizes[3]}px;
  }
`;

export const AppStoreButtonWrapper = styled.a`
  display: block;

  img {
    display: block;
    max-height: 40px;
  }
`;

export const StyledCloseCircleIcon = styled(CloseCircleIcon)`
  ${space}
`;

export const Link = styled(ReactRouterLink)`
  text-decoration: none;
  color: ${colors.woodpeckerRed[100]};
`;

export const CloseButton = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;
  position: absolute;
  right: 5px;
  top: 6px;
`;

export const PackageCard = styled(Flex).attrs({
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'start'
})`
  padding-bottom: 20px;
  border-bottom: 1px solid ${themeGet('colors.snowMountainGrey.100')};
  padding-left: ${themeGet('space.3')}px;
  padding-right: ${themeGet('space.3')}px;
  width: 100%;

  :not(:last-child) {
    margin-bottom: 20px;
  }

  .slider-list {
    min-height: 145px;
  }

  ${theme.mediaQueries.xl} {
    width: 284px;
    margin-bottom: 20px;
    border: none;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 0;
  }

  ${theme.mediaQueries.xxl} {
    width: 284px;
    margin-bottom: 0;

    :not(:last-child) {
      margin-bottom: 0;
    }
  }
`;

PackageCard.displayName = 'PackageCard';

export const PackageCardItem = styled(Flex).attrs({
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between'
})`
  margin-top: 10px;
  overflow: hidden;
`;

export const InfoCardAddOnsWrapper = styled(Card)`
  display: block;
  position: relative;
  border-radius: 15px;
  border: none;
  background-color: rgba(255, 255, 255, 0.18);
  width: ${({ carouselWidth }) => {
    return carouselWidth ? `${carouselWidth}px` : 'clamp(164px, 100%, 277px)';
  }};
  &:before {
    content: '';
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='white' stroke-width='2' stroke-dasharray='5%2c 8' stroke-dashoffset='3' stroke-linecap='square'/%3e%3c/svg%3e");
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.3;
  }
  > a {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: block;

    cursor: pointer;
    transition: 0.2s background ease-in-out;
    :hover {
      background: ${themeGet('colors.deepLakeBlue.100')};
      ${Text} {
        transition: 0.2s color ease-in-out;
        color: ${themeGet('colors.pureWhite.0')};
      }

      svg {
        transition: 0.2s fill ease-in-out;
        fill: ${themeGet('colors.pureWhite.0')};
      }
    }
  }
`;

export const InfoCardBody = styled(Absolute)`
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
`;

export const TitleField = styled(Text)`
  font-weight: bold;
  text-align: center;
  font-size: ${theme.fontSizes[9]}px;
  line-height: ${theme.fontSizes[1]}px;
  padding-top: 0px;
  color: ${theme.colors.deepLakeBlue[100]};
  padding-bottom: 1px;

  ${mediaQueries.lg} {
    padding-top: 2px;
    font-size: ${theme.fontSizes[1]}px;
    line-height: ${theme.fontSizes[7]}px;
  }
`;

export const SeeAllCTA = styled(Text)`
  cursor: pointer;
  text-decoration: underline;
  color: ${theme.colors.preciousStoneBlue[100]};
  font-size: ${theme.fontSizes[0]}px;
  line-height: ${theme.fontSizes[2]}px;

  ${mediaQueries.lg} {
    font-size: ${theme.fontSizes[1]}px;
    line-height: ${theme.fontSizes[7]}px;
  }
`;

export const DescriptionText = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  p {
    margin: 8px 0;
  }
`;

export const FlexCardPackage = styled(FlexCard)`
  overflow-y: auto;
  padding: ${themeGet('space.3')}px 0 0 0;

  ${mediaQueries.lg} {
    padding: ${themeGet('space.3')}px ${themeGet('space.2')}px;
  }
  ${mediaQueries.xl} {
    padding: ${themeGet('space.3')}px ${themeGet('space.3')}px;
  }
`;

export const CloseModalButton = styled.button`
  background: none;
  cursor: pointer;
  color: ${colors.preciousStoneBlue[100]};
  position: absolute;
  right: 0;
  top: 22px;
  z-index: 10;
  font-size: ${theme.fontSizes[2]}px;
  padding: 10px 32px;
  border: none;
  :hover {
    border: none;
    color: ${colors.blue};
  }

  @media (max-width: 390px) {
    top: 14px;
  }
`;

export const PackageInfo = styled(FlexCard)`
  overflow-y: auto;
`;

export const PackageDetailsContainerModal = styled(Box)`
  overflow: auto;
  width: 100%;

  ul {
    width: auto;
    padding: 0;
    list-style-type: none;

    > li {
      font-family: Montserrat;
      font-size: ${themeGet('fontSizes.1')}px;
      font-weight: ${themeGet('fontWeights.medium')};
      line-height: 1.71;
      color: ${themeGet('colors.deepLakeBlue.100')};
      position: relative;
      padding-left: ${themeGet('space.3')}px;
      margin-bottom: ${themeGet('space.1')}px;

      &:before {
        content: '';
        position: absolute;
        display: inline;
        left: 0;
        top: 8px;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        background-color: ${themeGet('colors.successGreen')};
      }
    }
  }
`;

export const PackageCardText = styled(Text)`
  width: 100%;
`;

export const ChargeTypeText = styled(Text)`
  width: 100%;
  font-size: ${themeGet('fontSizes.0')}px;
  font-weight: ${themeGet('fontWeights.bold')};
  color: ${themeGet('colors.deepLakeBlue.100')};
  margin-bottom: 12px;
`;

export const PackageCardDescription = styled(Text)`
  width: 100%;
  padding-left: 0px;
  overflow: hidden;
  font-size: ${themeGet('fontSizes.1')}px;
  line-height: 1.71;
`;

export const PackageCardDescriptionCollapsible = styled(Text)`
  width: 100%;
  padding-left: 8px;
  overflow: hidden;
  font-size: ${themeGet('fontSizes.1')}px;
  line-height: 1.71;
`;

export const CabanaDetailsCardDescription = styled(Text)`
  margin: 0;
  min-height: 50px;
  max-height: 100px;
  width: 100%;
  line-height: 1.67;

  & > p {
    margin: 0;
  }
`;

export const PackageCardButtonContainer = styled(Flex)`
  border-top-style: outset;
  border-top-color: ${themeGet('colors.snowMountainGrey.100')};
  border-top-width: 1px;
  margin-top: auto;
  padding-top: 12px;
  flex-direction: column;
`;

export const CabanaCardButtonContainer = styled(Box)`
  padding: ${themeGet('space.3')}px;
  padding-bottom: 0;
  border-top: 1px solid ${themeGet('colors.snowMountainGrey.100')};
  opacity: ${props => (props.isAvailable ? 'initial' : '0.7')};
  pointer-events: ${props => (props.isAvailable ? 'initial' : 'none')};
`;

export const DetailsButtonContainer = styled(Box)`
  background-color: ${themeGet('colors.kiddiePoolBlue.10')};
  padding: ${themeGet('space.7')}px ${themeGet('space.4')}px;
  position: absolute;
  bottom: 0;
  ${props => {
    if (props.alignLeft) {
      return `
      width: 330px;
      left: 0;
      `;
    } else {
      return `
      width: 50%;
      right: 0;
      `;
    }
  }}
`;

export const CabanaCard = styled(FlexCard)`
  border-radius: 0;
  box-shadow: ${themeGet('elevation.3')};
  margin-bottom: 32px;

  ${mediaQueries.lg} {
    max-width: 50%;
    border-radius: 15px;
    box-shadow: ${themeGet('elevation.3')};
    margin: ${themeGet('space.2')}px;
    flex: 27%;
    max-width: calc(33.3% - 16px);
    min-width: calc(33.3% - 16px);
  }
`;

export const PackageContainer = styled(FlexCard)`
  position: relative;
  overflow: visible;
  min-height: 400px;
`;

export const PackageContainerMobile = styled(FlexCard)`
  position: relative;
  overflow: visible;
  @media (max-width: 375px) {
    height: 150px;
  }
`;

export const QuantityButton = styled(FlexCard)`
  background-color: ${themeGet('colors.preciousStoneBlue.100')};
  cursor: pointer;
`;

export const PassDetailsNavigatorMobile = styled(FlexCard)`
  background: ${props => props.themeColor};
  border-radius: 0 10px 10px 0;
`;

export const PassCardHeaderMobile = styled(Text)`
  line-height: 1.2;
  font-size: ${themeGet('fontSizes.3')}px;
  font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: bold;
  @media (max-width: 375px) {
    font-size: ${themeGet('fontSizes.2')}px;
  }
`;

export const PassDetailsImageContainer = styled(FlexCard)`
  background-color: ${themeGet('colors.snowMountainGrey.20')};
`;

export const PackageDetailsContainerMobile = styled(Box)`
  ul {
    padding-left: 18px;
  }
  p {
    padding-left: 18px;
  }
`;

export const CMPInfoBox = styled(Box)`
  width: ${({ carouselWidth }) => {
    return carouselWidth ? `${carouselWidth}px` : 'calc(50% - 8px)';
  }};
  position: relative;
  
  ${theme.mediaScreens.phone`

    ${({ noMargin }) => {
      return noMargin
        ? ''
        : ` &:nth-child(1),
    &:nth-child(3) {
      margin-right: 15px;
    }
    &:nth-child(1),
    &:nth-child(2) {
      margin-bottom: 30px;
    }`;
    }}
  `}

  ${theme.mediaScreens.tablet`
      width:
      ${({ carouselWidth }) => {
        return carouselWidth ? `${carouselWidth}px` : '266px';
      }}

      ${({ noMargin }) => {
        return noMargin
          ? ''
          : `&:nth-child(1),
      &:nth-child(3) {
        margin-right: 16px;
      }
      &:nth-child(1),
      &:nth-child(2) {
        margin-bottom: 36px;
      }`;
      }}
  `}

  ${theme.mediaScreens.desktop`
      width: ${({ carouselWidth }) => {
        return carouselWidth ? `${carouselWidth}px` : 'calc(25% - 23px)';
      }};
      ${({ noMargin }) => {
        return noMargin
          ? ''
          : `margin-right: 30px;
      &:nth-child(1), &:nth-child(3){
        margin-right: 30px;
      }
      &:last-child {
        margin-right: 0;
      }`;
      }}

      
  `}
`;

export const AddCabanaButton = styled(Button)`
  opacity: ${props => (props.isDateSelected ? 'initial' : '0.5')};
  pointer-events: ${props => (props.isDateSelected ? '' : 'none')};
  white-space: nowrap;
  padding: 10px 40px;
  ${mediaQueries.sm} {
    padding: 10px 40px;
  }
  ${mediaQueries.lg} {
    padding: 10px 10px;
  }
  ${mediaQueries.xl} {
    padding: 10px 30px;
  }
`;

export const HeaderInfoContainer = styled(Flex)`
  justify-content: 'flex-start';
  width: 100%;

  ${mediaQueries.xl} {
    width: 688px;
    justify-content: flex-end;
  }

  ${mediaQueries.xxl} {
  }
`;

HeaderInfoContainer.displayName = 'HeaderInfoContainer';

export const StyledImage = styled.img`
  width: 64px;
  height: 64px;

  ${mediaQueries.lg} {
    width: 80px;
    height: 80px;
  }
`;

export const PackageDetailsImage = styled.img`
  object-fit: cover;
  width: 100%;
  top: 0;
  height: auto;
  z-index: -1;
  ${mediaQueries.xl} {
    height: 100%;
    width: auto !important;
    position: absolute;
  }
`;

export const MobileCardText = styled(Text.P)`
  @media (max-width: 375px) {
    font-size: ${themeGet('fontSizes.0')}px;
    margin: 4px 0;
    & > p {
      font-size: ${themeGet('fontSizes.0')}px;
    }
  }
  & > p {
    margin: 0;
  }
`;

export const CapitalizeTextModal = styled(Text)`
  text-transform: capitalize;
  line-height: 20px;
`;

export const Headline = styled(Text)`
  display: flex;
  justify-content: flex-start;
  font-size: ${themeGet('fontSizes.3')}px;
  margin-bottom: 35px;
  font-weight: ${themeGet('fontWeights.bold')};
  color: ${themeGet('colors.preciousStoneBlue.100')};
  line-height: 24px;
  ${mediaQueries.sm} {
    justify-content: center;
    font-size: ${themeGet('fontSizes.4')}px;
    margin-bottom: 60px;
    line-height: 32px;
  }
`;

export const HowToRedeemText = styled(Text)`
  font-weight: 500;
  font-size: ${themeGet('fontSizes.1')}px;
  color: ${themeGet('colors.nightSkyBlack.100')};
  width: 50%;
  line-height: 20px;
`;

export const IconImage = styled.img`
  width: 64px;

  ${mediaQueries.xl} {
    width: 80px;
  }
`;

export const Divider = styled.div`
  width: 100%;
  border-top: 1px solid ${theme.colors.snowMountainGrey[100]};
  margin: ${({ margin }) => (margin ? margin : '12px 0px')};
`;
export const DividerVertical = styled.div`
  width: 4%;
  border-left: 1px solid ${theme.colors.snowMountainGrey[100]};
  height: 64px;
  margin-left: 24px;
`;

export const DataIcon = styled.img`
  width: ${({ isMobile }) => (isMobile ? 30 : 45)}px;
  height: ${({ isMobile, imgException }) => (imgException ? imgException : isMobile ? 30 : 45)}px;
  margin-right: 25px;
`;

export const InfoIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: ${props => (props.smallerMargin ? 8 : 16)}px;
`;

export const ModalTitle = styled(Text)`
  font-size: ${({ isMobile }) => (isMobile ? theme.fontSizes[7] : theme.fontSizes[4])}px;
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.deepLakeBlue[100]};
  line-height: ${({ isMobile }) => (isMobile ? theme.fontSizes[8] : theme.fontSizes[5])}px;
  margin-bottom: 33px;
`;
export const DataTitle = styled(Text)`
  font-size: ${({ isMobile }) => (isMobile ? theme.fontSizes[1] : theme.fontSizes[7])}px;
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.deepLakeBlue[100]};
  line-height: ${({ isMobile }) => (isMobile ? '18px' : '24px')};
`;
export const DataDescription = styled(Text)`
  font-size: ${theme.fontSizes[0]}px;
  font-weight: ${theme.fontWeights.medium};
  color: ${theme.colors.nightSkyBlack[80]};
  line-height: 18px;
`;

export const RoomInclusionsSection = styled(Flex)`
  background-color: ${theme.colors.kiddiePoolBlue[10]};
  flex-direction: column;
  padding: 16px 36px;
  margin: 24px -36px;
`;

export const CounterLabel = styled(Text)`
  font-size: ${({ isMobile }) => (isMobile ? theme.fontSizes[3] : theme.fontSizes[7])}px;
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.nightSkyBlack[80]};
  line-height: 24px;
`;

export const CounterLabelDescription = styled(Text)`
  font-size: ${({ isMobile }) => (isMobile ? theme.fontSizes[9] : theme.fontSizes[0])}px;
  font-weight: ${theme.fontWeights.medium};
  color: ${theme.colors.deepLakeBlue[100]};
  line-height: ${({ isMobile }) => (isMobile ? theme.fontSizes[2] : theme.fontSizes[7])}px;
`;
export const SelectDescription = styled(Text)`
  font-size: ${theme.fontSizes[1]}px;
  font-weight: ${theme.fontWeights.medium};
  color: ${theme.colors.nightSkyBlack[80]};
  line-height: 20px;
`;

export const InfoAlertWrapper = styled(Flex)`
  align-items: flex-start;
  background-color: ${theme.colors.sandstoneBuff[20]};
  border: 1px solid ${theme.colors.digitalYellowOrange[100]};
  border-radius: 10px;
  padding: 12px 16px;
  margin: 25px 0;
`;

export const NewGuestsBadgeContainer = styled.div`
  width: 40px;
  position: absolute;
  text-align: center;
  z-index: 100;
  margin-top: -10px;
  left: 50%;
  transform: translateX(-50%);
  ${mediaQueries.sm} {
    width: 45px;
    position: absolute;
    text-align: center;
    z-index: 100;
    margin-top: -10px;
  }
`;

export const AddGuestsCTAButton = styled(Button)`
  width: 343px;
  margin-left: auto;
  margin-right: auto;
`;

export const Total = styled(Text)`
  font-size: ${({ isMobile, showPackagesInAddGuestsModal }) =>
    showPackagesInAddGuestsModal || isMobile ? theme.fontSizes[7] : theme.fontSizes[4]}px;
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.deepLakeBlue[100]};
  line-height: ${({ isMobile, showPackagesInAddGuestsModal }) =>
    showPackagesInAddGuestsModal ? (isMobile ? '22px' : '24px') : isMobile ? '22px' : '32px'};
`;

export const InclusionsTitle = styled(Text)`
  font-size: ${({ isMobile }) => (isMobile ? theme.fontSizes[0] : theme.fontSizes[1])}px;
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.nightSkyBlack[80]};
  line-height: ${({ isMobile }) => (isMobile ? '16px' : '20px')};
`;

// Info Cards
export const Image = styled.img`
  width: 64px;
  height: 64px;
  margin-bottom: 8px;
  ${mediaQueries.sm} {
    width: 80px;
    height: 80px;
    margin-bottom: 12px;
  }
`;

export const AddCardsButtonContainer = styled(Box)`
  width: clamp(148px, 100%, 245px);
  max-width: calc(100% - 16px);
  margin: 0 auto;
  ${mediaQueries.md} {
    max-width: calc(100% - 32px);
  }
`;

export const PlusInfoImgs = styled.img`
  width: 24px;
  height: 24px;
  ${mediaQueries.md} {
    width: 32px;
    height: 32px;
  }

  @media (max-width: 1024px) and (min-width: 768px) {
    width: 24px;
    height: 24px;
  }
`;

export const LcoHeaderImageSection = styled(Box)`
  height: 210px;
  width: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  ${theme.mediaQueries.md} {
    height: 250px;
  }
`;

export const LcoContentSection = styled(Box)`
  padding: 24px 24px 0px;
`;

export const LcoButtonsSection = styled(Flex)`
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  height: 82px;
  padding: 24px;

  ${theme.mediaQueries.md} {
    padding: 18px 24px;
    width: 100%;
    margin: 0;
  }
`;

export const AddBundleButton = styled(Button)`
  width: 207px;
  ${theme.mediaQueries.lg} {
    width: 221px;
  }
`;

export const LcoHoursContainer = styled(Flex)`
  border-radius: 8px;
  background-color: ${theme.colors.snowMountainGrey[50]};
  padding: 8px 0px;
  justify-content: center;
  & > div {
    flex: 1;
    &:first-child {
      border-right: 1px solid ${theme.colors.nightSkyBlack[60]};
    }
  }
  ${theme.mediaQueries.lg} {
    width: 327px;
  }
`;

export const BundleImageContainer = styled(Box)`
  width: 172px;
  height: 64px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

export const BundleImage = styled.img`
  width: 72px;
  height: 72px;
  ${theme.mediaQueries.md} {
    width: 56px;
    height: 56px;
  }
`;

export const CustomMobileAddonsContainer = styled(Flex)`
  ${({ isBundleAvailable }) => {
    if (isBundleAvailable) {
      return css`
        > div {
          &:nth-child(1) {
            margin-right: 0px;
            margin-bottom: 40px;
            width: 100%;
            height: 302px;
            max-width: 570px;
            > .info-card-addons-bundle {
              height: 302px;
              width: 100%;
              padding-top: initial;
            }
          }
          &:nth-child(2) {
            margin-right: 15px;
            ${theme.mediaQueries.md} {
              width: 278px;
            }
          }
          &:nth-child(3) {
            margin-right: 0px;
            ${theme.mediaQueries.md} {
              width: 278px;
            }
          }
          &:nth-child(4) {
            margin-right: 15px;
            ${theme.mediaQueries.md} {
              width: 278px;
            }
          }
          &:nth-child(5) {
            ${theme.mediaQueries.md} {
              width: 278px;
            }
          }
        }
      `;
    }
  }}
`;

export const BundleInfoCardWrapper = styled(Flex)`
  column-gap: 24px;
  ${theme.mediaQueries.md} {
    column-gap: 4px;
  }
`;

export const AddonsCarouselContainer = styled(Box)`
  > div {
    padding: 0;
  }
`;

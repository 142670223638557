import { DATE_FORMATS } from '../../../utilities/constants';
import { GwDatesWrapper } from '../../../utilities/gwDatesWrapper';

const days = (date_1, date_2) => {
  let difference = date_1.getTime() - date_2.getTime();
  let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
  return TotalDays;
};

export const addResortLocationToOffer = (resortLocation, offer) => ({ resortLocation, ...offer });

export const formatOffer = (offer, isCheckBoxActive, checkIn, checkOut) => {
  // CREATE TEMP FORMAT
  const offerObject = {
    ...offer
  };
  // ADD CHECKBOX
  offerObject.checkBoxActive = isCheckBoxActive;

  const checkInDate = GwDatesWrapper.format(checkIn, DATE_FORMATS.default);
  const checkOutDate = GwDatesWrapper.format(checkOut, DATE_FORMATS.default);
  const offerStayStart = GwDatesWrapper.format(offer.stayStartDate, DATE_FORMATS.default);
  const offerStayEnd = GwDatesWrapper.format(offer.stayEndDate, DATE_FORMATS.default);

  offerObject.searchIsWithinOfferDates =
    GwDatesWrapper.isSameOrAfter(checkInDate, offerStayStart) &&
    GwDatesWrapper.isSameOrBefore(checkOutDate, offerStayEnd);

  // ADD EXPIRATION
  const todayDate = new Date();
  const compareDate = new Date(offer.bookByDate);
  const isExpiring = days(compareDate, todayDate);
  offerObject.expiringSoon = isExpiring < 14 ? true : false;

  return offerObject;
};

export const nonExpiredOrderByDateOffers = (offers, isCheckBoxActive, checkIn, checkOut) => {
  const formattedOffers = offers.map(offer => formatOffer(offer, isCheckBoxActive, checkIn, checkOut));
  let nonExpiredOffers = [];
  let notEligibleOffers = [];
  const todayDate = GwDatesWrapper.today();

  formattedOffers.forEach(offer => {
    const stayEndDate = GwDatesWrapper.format(offer.stayEndDate, DATE_FORMATS.default);
    if (GwDatesWrapper.isSameOrAfter(stayEndDate, todayDate) && offer.searchIsWithinOfferDates) {
      nonExpiredOffers.push(offer);
    } else notEligibleOffers.push(offer);
  });
  return nonExpiredOffers
    .sort(function(a, b) {
      return new Date(a.bookByDate) - new Date(b.bookByDate);
    })
    .concat(notEligibleOffers);
};

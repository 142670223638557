import AuthClient from '../../api/auth/AuthClient';

import AvailabilityApiClient from '../../api/clients/AvailabilityApiClient';
import { formatOffer } from '../../components/LoyaltySummary/utilities/functions';
import { OFFERS_ACTIONS, offerEventBuilder } from '../../infrastructure/middleware/analytics/analyticsObjectBuilders';
import { getNormalizedCode } from '../../utilities/availabilityUtils';
import { pushEvent } from '../../utilities/dataLayerUtils';
import { GwDatesWrapper } from '../../utilities/gwDatesWrapper';
import { getErrorMessage } from '../../utilities/messageUtils';
import { initializeState } from '../../utilities/storageUtils';

const authClient = new AuthClient();

const addOfferCodeType = 'ADD_OFFER_CODE';
const removeOfferCodeType = 'REMOVE_OFFER_CODE';
const setOfferCodeDescriptionType = 'SET_OFFER_CODE_DESCRIPTION';
export const createOfferErrorType = 'CREATE_OFFER_ERROR';
export const addActiveOfferType = 'ADD_ACTIVE_OFFER_TYPE';
const addEmailActiveOfferType = 'ADD_EMAIL_ACTIVE_OFFER_TYPE';
export const removeActiveOfferType = 'REMOVE_ACTIVE_OFFER_TYPE';
export const removeAllActiveOffersType = 'REMOVE_ALL_ACTIVE_OFFERS_TYPE';

const ERROR_MESSAGE = 'Unfortunately that is not a valid offer code. Please re-enter or view our other offers.';

const DATE_FORMAT = 'YYYY-MM-DD';

const getDefaultOfferCode = (fallbackValue = '') => {
  const isUserLoggedIn = authClient.isLoggedIn();
  const promoCodeList = window.promoCodeList ? JSON.parse(window.promoCodeList) : [];
  const relatedOfferCode = promoCodeList.find(item => item.promoCode === window.dealPromoCode);
  const isGatedOffer = relatedOfferCode && JSON.parse(relatedOfferCode.isExclusive);

  if (isGatedOffer && !isUserLoggedIn) {
    return fallbackValue;
  }

  return window.dealPromoCode || fallbackValue;
};

const initialState = {
  code: getDefaultOfferCode(),
  description: '',
  validOffer: false,
  errorMessage: '',
  activeOffers: [],
  emailOffers: []
};

export const actionCreators = {
  addOfferCode: code => ({
    type: addOfferCodeType,
    code: getNormalizedCode(code)
  }),
  removeOfferCode: () => ({ type: removeOfferCodeType }),
  getOfferCodeDescription: (location, offerCode, arrivalDate = null, departureDate = null) => dispatch => {
    // Call availability V2.1 to get the offer code description by the stay dates
    let apiClient = new AvailabilityApiClient('v2.1');

    const arrivalDateTime = GwDatesWrapper.createDate(arrivalDate); // Convert the datetime string if it comes with timezone offset
    const departureDateTime = GwDatesWrapper.createDate(departureDate); // Convert the datetime string if it comes with timezone offset

    const arrival = GwDatesWrapper.format(arrivalDateTime, DATE_FORMAT); // Convert the date again to string to the specific format
    const departure = GwDatesWrapper.format(departureDateTime, DATE_FORMAT); // Convert the date again to string to the specific format

    const request = apiClient.getOfferCodeDescription({
      location,
      offerCode: getNormalizedCode(offerCode),
      arrival,
      departure
    });

    return request.then(
      response => {
        if (response.data) {
          dispatch({
            type: setOfferCodeDescriptionType,
            description: response.data
          });
        } else {
          dispatch({
            type: createOfferErrorType,
            errorMessage: ERROR_MESSAGE
          });
        }
      },
      err => {
        dispatch({
          type: createOfferErrorType,
          errorMessage: getErrorMessage(err.message)
        });
      }
    );
  },
  setOfferCodeDescription: description => dispatch => {
    dispatch({
      type: setOfferCodeDescriptionType,
      description: description
    });
  },
  addActiveOffer: offerData => dispatch => {
    dispatch({
      type: addActiveOfferType,
      offerData: offerData
    });
  },
  addEmailActiveOffer: offerData => dispatch => {
    dispatch({
      type: addEmailActiveOfferType,
      offerData: offerData
    });
  },
  removeActiveOffer: offerData => dispatch => {
    dispatch({
      type: removeActiveOfferType,
      offerData: offerData
    });
  },
  removeAllActiveOffers: () => dispatch => {
    dispatch({
      type: removeAllActiveOffersType
    });
  }
};

const reducer = (state, action) => {
  state = initializeState(state, initialState);

  switch (action.type) {
    case addOfferCodeType:
      return { ...state, code: action.code };
    case setOfferCodeDescriptionType:
      return {
        ...state,
        description: action.description,
        validOffer: action.description ? true : false,
        errorMessage: ''
      };
    case removeOfferCodeType:
      return {
        ...state,
        code: ''
      };
    case createOfferErrorType:
      return {
        ...state,
        validOffer: false,
        errorMessage: action.errorMessage,
        description: ''
      };
    case addActiveOfferType: {
      const eventObject = offerEventBuilder(OFFERS_ACTIONS.ADD, {
        resortLocation: action.offerData.resortLocation,
        name: action.offerData.title,
        value: -action.offerData.dollarValue
      });
      pushEvent(eventObject);
      const activeOffers = [...state.activeOffers];
      if (!activeOffers.includes(action.offerData.id)) {
        activeOffers.push(action.offerData.id);
        return { ...state, activeOffers: activeOffers };
      }
      return state;
    }
    case addEmailActiveOfferType: {
      const emailOffers = [...state.emailOffers];
      if (!emailOffers.includes(action.offerData.id)) {
        emailOffers.push(action.offerData.id);
        return { ...state, emailOffers: emailOffers };
      }
      return state;
    }
    case removeActiveOfferType: {
      const eventObject = offerEventBuilder(OFFERS_ACTIONS.REMOVE, {
        resortLocation: action.offerData.resortLocation,
        name: action.offerData.title,
        value: -action.offerData.dollarValue
      });
      pushEvent(eventObject);
      const activeOffers = state.activeOffers.filter(offer => offer !== action.offerData.id);
      const activeEmailOffers = state.emailOffers.filter(offer => offer !== action.offerData.id);
      return { ...state, activeOffers: activeOffers, emailOffers: activeEmailOffers };
    }
    case removeAllActiveOffersType: {
      return { ...state, activeOffers: [], emailOffers: [] };
    }
    default:
      return state;
  }
};

export default reducer;

// selectors
export const getOfferCode = state => getDefaultOfferCode(state.code);

export const getOfferCodeDescription = state => state.description;

export const getOfferCodeErrorMessage = state => state.errorMessage;

export const getOfferCodeStatus = state => state.validOffer;

export const getActiveOffers = state => state.activeOffers;

export const getEmailActiveOffers = state => state.emailOffers;

export const getTotalActiveOffers = state => {
  const activeOffers = state.offer.activeOffers;
  if (activeOffers.length > 0) {
    const userOffers = state.account?.currentLoggedInUser?.offerList;
    const offerstotal = activeOffers?.reduce((accumulator, currentValue) => {
      const offer = userOffers?.find(offer => offer.id === currentValue);
      return accumulator + offer?.dollarValue;
    }, 0);
    return offerstotal;
  }
  return 0;
};

export const getAvailableOffersBySearch = state => {
  const availableOffers = [];
  const userOffers = state.account.currentLoggedInUser.offerList || [];
  const { checkinDateSelection, checkoutDateSelection } = state.dates;
  userOffers.forEach(offer => {
    const formatedOffer = formatOffer(offer, false, checkinDateSelection, checkoutDateSelection);
    if (formatedOffer.searchIsWithinOfferDates === true) {
      availableOffers.push(formatedOffer);
    }
  });
  return availableOffers;
};

import { isDevEnvironment } from '../../utilities/env';

const openModal = 'OPEN_MODAL';
const closeModal = 'CLOSE_MODAL';
const resetModal = 'RESET_MODAL';
const updateParams = 'UPDATE_PARAMS';
const initialState = {
  isVisible: false,
  keyContentComponent: undefined,
  shouldUseGWComponent: false,
  params: {}
};

const logConsoleMessage = (type, message, objects = []) => {
  if (isDevEnvironment) {
    console.log(`[${type}] ${message}`);
    if (objects && objects.length > 0) {
      objects.forEach(property => {
        console.log({ property });
      });
    }
  }
};

export const actionCreators = {
  openModal: ({ keyContentComponent, params }, shouldUseGWComponent = false, shouldForceOpen = false) => (
    dispatch,
    getState
  ) => {
    const currentState = getState();
    const { globalModal } = currentState;

    logConsoleMessage('REQUEST', `Open modal with key: ${keyContentComponent} - [FORCE]: ${shouldForceOpen}`);

    if (!globalModal.isVisible || !globalModal.keyContentComponent || shouldForceOpen) {
      logConsoleMessage('SUCCESS', `Modal opened with success`);

      dispatch({
        type: openModal,
        payload: {
          keyContentComponent,
          shouldUseGWComponent,
          params
        }
      });
    } else {
      logConsoleMessage('FAILURE', `It was not possible to open the modal`, [keyContentComponent, globalModal]);
    }
  },
  closeModal: key => (dispatch, getState) => {
    const currentState = getState();
    const { globalModal } = currentState;

    logConsoleMessage('REQUEST', `Close modal with key: ${key}`);

    if (!key || key === globalModal.keyContentComponent) {
      logConsoleMessage('SUCCESS', `Modal closed with success`);

      dispatch({
        type: closeModal
      });
    } else {
      logConsoleMessage('FAILURE', `It was not possible to close the modal.`, [key, globalModal]);
    }
  },
  updateParams: params => (dispatch, getState) => {
    dispatch({
      type: updateParams,
      payload: {
        params
      }
    });
  },
  resetModal: () => dispatch => {
    dispatch({
      type: resetModal
    });
  }
};

actionCreators.openOrUpdateModal = ({ keyContentComponent, params }, shouldUseGWComponent = false) => (
  dispatch,
  getState
) => {
  const currentState = getState();
  const isOpened = verifyIfModalIsOpened(keyContentComponent, currentState);

  if (isOpened) {
    dispatch(actionCreators.updateParams(params));
  } else {
    dispatch(actionCreators.openModal({ keyContentComponent, params }, shouldUseGWComponent));
  }
};

actionCreators.toggleModal = ({ keyContentComponent, params }, shouldUseGWComponent = false) => (
  dispatch,
  getState
) => {
  const currentState = getState();
  const isOpened = verifyIfModalIsOpened(keyContentComponent, currentState);

  if (isOpened) {
    dispatch(actionCreators.closeModal(keyContentComponent));
  } else {
    dispatch(actionCreators.openModal({ keyContentComponent, params }, shouldUseGWComponent));
  }
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case openModal:
      return {
        isVisible: true,
        keyContentComponent: action.payload.keyContentComponent,
        shouldUseGWComponent: action.payload.shouldUseGWComponent,
        params: action.payload.params
      };
    case closeModal:
      return {
        isVisible: false,
        keyContentComponent: undefined,
        shouldUseGWComponent: false,
        params: {}
      };
    case resetModal:
      return {
        isVisible: false,
        keyContentComponent: undefined,
        shouldUseGWComponent: false,
        params: {}
      };
    case updateParams:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.payload.params
        }
      };
    default:
      return state;
  }
};

// Selectors
export const verifyIfModalIsOpened = (key, state) => {
  return state.globalModal.keyContentComponent === key;
};

export default reducer;

import React from 'react';
import PropTypes from 'prop-types';
import Scrollable from '../../ui-kit/Scrollable';
import CloseIcon from '../../ui-kit/icons/Close';
import CarouselChevronLeftThinIcon from '../../ui-kit/icons/CarouselChevronLeftThin';
import theme, { colors } from '../../ui-kit/themes/theme';
import { Absolute, Box, Flex, Text } from '../../ui-kit';
import Modal from '../Modal';
import { BackButtonWrapper, CloseButton } from './styles';

const imgPropType = PropTypes.shape({
  alt: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
});

const propTypes = {
  images: PropTypes.arrayOf(imgPropType),
  showModal: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  modalHeight: PropTypes.number
};

const defaultProps = {
  images: [],
  children: null,
  modalHeight: 440
};

const OVERLAY_BACKGROUND_COLOR = 'rgba(251, 252, 249, 0.75)';
const IMAGE_GAP = 4;

const MediaGalleryModal = ({
  isFullSize,
  images,
  showModal,
  onCloseModal,
  children,
  modalHeight
}) => {
  const renderImages = () => {
    return images?.map((image, index) => (
      <img
        key={image.url}
        src={image.url}
        alt={image.alt}
        width="100%"
        height="auto"
        style={{ marginTop: index > 0 ? IMAGE_GAP : '0px' }}
      />
    ));
  };

  const renderDefaultContent = () => {
    return (
      <Flex width={820} position="relative">
        <Absolute top={5} right={10}>
          <CloseButton
            type="button"
            onClick={() => onCloseModal(false)}
            title="Close">
            <Flex alignItems="center">
              <CloseIcon
                color={theme.colors.preciousStoneBlue[100]}
                size={18}
              />
            </Flex>
          </CloseButton>
        </Absolute>
        <Flex width="75vw">
          <Box maxHeight="100%" maxWidth="50%">
            <Scrollable
              showTrack
              maxHeight={modalHeight}
              scrollColor={theme.colors.deepForestGreen[50]}
              trackColor={theme.colors.snowMountainGrey[50]}>
              {renderImages()}
            </Scrollable>
          </Box>
          <Box maxWidth="50%">{children}</Box>
        </Flex>
      </Flex>
    );
  };

  const renderFullSizeContent = () => {
    return (
      <Scrollable
        isInset
        showTrack
        maxHeight="100%"
        trackColor={colors.snowMountainGrey[50]}
        scrollColor={colors.deepForestGreen[50]}>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="90vw"
          mx="5vw">
          <Box key="modal-back-button" width="100%">
            <BackButtonWrapper onClick={onCloseModal}>
              <CarouselChevronLeftThinIcon
                size="14px"
                color={colors.preciousStoneBlue[100]}
              />
              <Text
                as="span"
                color={colors.preciousStoneBlue[100]}
                fontSize={1}
                fontWeight="medium"
                my="0px"
                ml="6px">
                Back
              </Text>
            </BackButtonWrapper>
          </Box>
          {renderImages()}
        </Flex>
      </Scrollable>
    );
  };

  const renderContent = () => {
    if (isFullSize) {
      return renderFullSizeContent();
    }

    return renderDefaultContent();
  };

  return (
    <Modal
      fullSizeContent={isFullSize}
      fullSizeBackgroundColor={OVERLAY_BACKGROUND_COLOR}
      padding="0px"
      borderRadius={`${theme.radii[4]}px`}
      isOpen={showModal}
      onRequestClose={onCloseModal}>
      {renderContent()}
    </Modal>
  );
};

MediaGalleryModal.propTypes = propTypes;
MediaGalleryModal.defaultProps = defaultProps;

export default MediaGalleryModal;

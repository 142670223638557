export const SUMMARY_CODE = 'Code';
export const SUMMARY_APPLIED = 'applied';
export const SUMMARY_WATER_PARK_PASSES = 'Water Park Passes';
export const SUMMARY_INCLUDED = 'INCLUDED';
export const SUMMARY_CHECKIN = 'Check-In';
export const SUMMARY_CHECKOUT = 'Check-Out';
export const SUMMARY_DISCOUNT_CHANGES = 'Discount Charges';
export const SUMMARY_VOYAGERS_POINTS_APPLIED = 'Voyagers Points Applied';
export const SUMMARY_BONNUS_DEALS_APPLIED = 'Bonus Deals Applied';
export const SUMMARY_DEPOSIT_PAID = 'Deposit Paid';
export const SUMMARY_CABANA_DEPOSIT = 'Cabana Deposit';
export const SUMMARY_SUITE_RESERVATION = 'Suite Reservation';
export const SUMMARY_DUE_CHECKIN = 'Due at Check-In';
export const SUMMARY_ADDITIONAL_CHARGES = 'Additional Charges';
export const SUMMARY_TITLE = 'Stay Summary';
export const SUMMARY_TOTAL = 'Total';
export const SUMMARY_NIGHTS_PLURAL = 'Nights';
export const SUMMARY_NIGHTS_SINGULAR = 'Night';
export const NEW_GUESTS_MESSAGE = 'Your <NEW_GUESTS> have been successfully added to your reservation.';
export const NEW_GUESTS_BOLD = 'new guests';
export const NEW_GUESTS_ADDED = '<GUEST_COUNT> New Guests';
export const NEW_GUEST = 'New Guest';
export const NEW_GUESTS = 'New Guests';
export const NEW_GUESTS_IN_BASE_OCUPANCY = 'Included in base occupancy';
export const NEW_GUESTS_IN_MAX_OCUPANCY = 'Expanded occupancy fee';
export const DINING_DEALS_DETAILS = '(For <NUM_GUESTS> <GUEST_PLURAL> & <NUMB_NIGHTS> <NIGHTS_PLURAL>)';
export const SUMMARY_DINING_CREDIT_PACKAGE_TITLE = `$<ALLOWANCE> <PACKAGE_NAME> Dining Credits`;

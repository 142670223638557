import GWRApiClient from './GWRApiClient';

/**
 * Concrete implementation to fetch event calendar details from AEM.
 *
 * @class EventCalendarApiClient
 * @extends {GWRApiClient}
 */
export default class EventCalendarApiClient extends GWRApiClient {
  /**
   * @constructor
   * Calls parent constructor.
   * If there isn't any url provided, then we use the default from the environment variable
   * fills the url and token information
   * @param {string} url
   *  The url where the service endpoints are
   * @param {string} token
   *  The token of the logged user
   */
  constructor(url = '', token = '') {
    const urlAux = url !== '' ? url : '';
    super(urlAux, token);
  }

  /**
   * @function
   * Send a GET request to AEM endpoint to fetch event details based on the date as param.
   * @param {string} date
   */
  async getEventDetails(date) {
    const urlRequest = `${this.url}?date=${date}`;
    const response = await super.getApiCall(urlRequest);
    return response;
  }
}

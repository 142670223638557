const setAlreadyShownType = 'SET_IS_ALREADY_SHOWN';

const initialState = {
  isAlreadyShown: false
};

export const actionCreators = {
  setAlreadyShown: params => dispatch => {
    dispatch({
      type: setAlreadyShownType,
      isAlreadyShown: params
    });
  }
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case setAlreadyShownType:
      return {
        ...state,
        isAlreadyShown: action.isAlreadyShown
      };
    default:
      return state;
  }
};

/**
 * Event names used to trigger Facebook Pixels.
 * @enum {string}
 */
export const EVENT_NAME = {
  subscribe: 'Subscribe'
};

export const trackFbPixelEvent = eventName => {
  if (!eventName) return;

  if (typeof window.fbq === 'function') {
    window.fbq('track', eventName);
  }
};

/**
 * This functions emits an event on the window taht will be listened on AEM
 * @param {String} eventName name that will be spected on the AEM side
 *
 * More custom options can be added later
 */
export const emitEventForAEM = eventName => {
  const event = new Event(eventName);
  window.dispatchEvent(event);
};

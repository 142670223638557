import BlockUi from 'react-block-ui';
import styled from 'styled-components';
import { themeGet } from 'styled-system';

import Box from '../../ui-kit/Box';
import Flex from '../../ui-kit/Flex';

export const ModalContainer = styled(Box)`
  padding: ${({ innerPadding }) => `${innerPadding}px`};
  ${({ hasTopPadding }) => !hasTopPadding && `padding-top: 0px`};
`;

export const OutsideContainer = styled(Flex)`
  width: 100%;
  padding: ${({ topPadding, theme }) => (topPadding ? `${theme.space[4]}px 0 0` : 0)};
`;

export const CloseButton = styled.button`
  cursor: pointer;
  color: inherit;
  border: none;
  background: transparent;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ closeBtnHorizonPosValue }) => 'top:' + closeBtnHorizonPosValue};
  ${({ closeBtnPosition, closeBtnVertPosValue }) =>
    closeBtnPosition === 'left' ? 'left:' + closeBtnVertPosValue : 'right:' + closeBtnVertPosValue};

  svg {
    ${({ closeBtnColor }) => closeBtnColor && 'fill:' + closeBtnColor};
  }

  &:hover {
    text-decoration: underline;
  }
`;

export const BackButton = styled(CloseButton)`
  position: absolute;
  ${({ backButtonVertPosValue }) => 'top:' + backButtonVertPosValue + '!important;'}
  left: ${themeGet('space.4')}px;
  top: 5px;
  right: unset;
`;

export const HeadingContainer = styled(Flex)`
  justify-content: center;
  position: relative;

  ${({ hasTitle, theme }) =>
    hasTitle &&
    `
    border: 0 solid ${theme.colors.snowMountainGrey[100]};
    border-bottom-width: 0.5px;
    padding: 0 70px;
  `}
`;

export const CustomBlockUi = styled(BlockUi)`
  overflow: hidden;
  border-radius: ${themeGet('radii.5')}px;
`;

import { gaTrackingPlanSearchErrorCode } from '../store/componentStores/utilities/util';
import { CURRENCY_CODE, SORTING_CRITERIA } from './constants';
import { GwDatesWrapper } from './gwDatesWrapper';

export const sortSuites = (suites, criteria) => {
  if (criteria === SORTING_CRITERIA.recommendedForYou) {
    return suites.sort((el1, el2) => {
      return el1.sortOrder - el2.sortOrder;
    });
  } else {
    // group multiple suites of same brand
    // using a dictionary keyed by 'suiteBrand' where each element contains:
    // - 'rate' -> rate for brand
    // - 'suites' -> all suites for brand
    let ratesByBrand = {};
    let brandList = [];
    suites.forEach(suite => {
      const brand = suite.suiteBrand || 'missingBrand';
      if (ratesByBrand[brand]) {
        // capture lowest rate for each brand
        if (ratesByBrand[brand].rate > suite.avgNightlyRate) {
          ratesByBrand[brand].rate = suite.avgNightlyRate;
        }
        // collect all suites for the brand
        ratesByBrand[brand].suites.push(suite);
      } else {
        ratesByBrand[brand] = {
          rate: suite.avgNightlyRate,
          suites: [suite]
        };
        // collect all brands in a list
        brandList.push(ratesByBrand[brand]);
      }
    });
    // sort brands based on rate
    brandList.sort((el1, el2) => {
      return criteria === SORTING_CRITERIA.descending ? el2.rate - el1.rate : el1.rate - el2.rate;
    });

    // re-assemble list of suites based on sorted list of brands
    const sortedArray = brandList.reduce(
      (previousValue, currentValue) => previousValue.concat(currentValue.suites),
      []
    );
    return sortedArray;
  }
};

/**
 * Merge Suites reducer with availability and highest rate per suite data
 * @param {*} availabilityHighestRatesList
 * @param {*} availabilityList
 * @param {string} offerType
 */
export const mergedSuitesData = (availabilityHighestRatesList, availabilityList, offerType) => {
  const { roomRates } = availabilityHighestRatesList;

  if (!availabilityList) return [];

  const suitesWithAvailability = availabilityList.reduce((accumulator, suite) => {
    const updatedSuite = {
      ...suite,
      offerType: offerType,
      highestRate: roomRates && Object.keys(roomRates).length > 0 ? roomRates[suite.suiteCode] : null,
      suiteCategory: suite.suiteCategory.toLowerCase()
    };

    return [...accumulator, updatedSuite];
  }, []);

  return suitesWithAvailability;
};

export const getOfferCodeErrorDetails = ({ offerCode, offerCodeError, checkIn, checkOut }) => {
  const offerCodeErrorNumbers = offerCodeError && offerCodeError.match(/\d+/g);
  const stayNumberOfNights = checkOut && checkIn && GwDatesWrapper.diff(checkOut, checkIn, 'days');
  const daysNumberVariable = offerCodeErrorNumbers && parseInt(offerCodeErrorNumbers[0]);
  const variableDaysLabel = daysNumberVariable
    ? `${daysNumberVariable} ${daysNumberVariable > 1 ? 'days' : 'day'}`
    : null;
  const errorTypes = {
    RATE_UNAVAILABLE: {
      shouldDisplayModal: false,
      message: 'Sorry that rate or suite is no longer available. Please try another one of our suite options!',
      shouldDisplaySearchAgain: false,
      shouldDisplaySpecialOffers: false,
      shouldDisplaySignIn: false,
      shouldDisplaySignUp: false
    },
    ROOM_RESTRICTED: {
      shouldDisplayModal: false,
      message: 'Sorry that rate or suite is no longer available. Please try another one of our suite options!',
      shouldDisplaySearchAgain: false,
      shouldDisplaySpecialOffers: false,
      shouldDisplaySignIn: false,
      shouldDisplaySignUp: false
    },
    PROPERTY_RESTRICTED: {
      shouldDisplayModal: false,
      message: 'Sorry that rate or suite is no longer available. Please try another one of our suite options!',
      shouldDisplaySearchAgain: false,
      shouldDisplaySpecialOffers: false,
      shouldDisplaySignIn: false,
      shouldDisplaySignUp: false
    },
    ROOM_UNAVAILABLE: {
      shouldDisplayModal: false,
      message: 'Sorry that suite is no longer available. Please try another one of our suite options!',
      shouldDisplaySearchAgain: false,
      shouldDisplaySpecialOffers: false,
      shouldDisplaySignIn: false,
      shouldDisplaySignUp: false
    },
    SIGN_IN_REQUIRED: {
      shouldDisplayModal: true,
      message: 'Unfortunately you need to be signed into your account to use that promo code.',
      shouldDisplaySearchAgain: false,
      shouldDisplaySpecialOffers: false,
      shouldDisplaySignIn: true,
      shouldDisplaySignUp: true
    },
    INVALID_OFFER_CODE: {
      shouldDisplayModal: false,
      message: 'Unfortunately that is not a valid offer code. Please re-enter or view our other offers.',
      shouldDisplaySearchAgain: true,
      shouldDisplaySpecialOffers: true,
      shouldDisplaySignIn: false,
      shouldDisplaySignUp: false
    },
    INACTIVE_PROMO: {
      shouldDisplayModal: false,
      message: 'Unfortunately that offer has expired. Please view our other offers.',
      shouldDisplaySearchAgain: true,
      shouldDisplaySpecialOffers: true,
      shouldDisplaySignIn: false,
      shouldDisplaySignUp: false
    },
    CLOSED: {
      shouldDisplayModal: false,
      message: 'Unfortunately that offer has expired. Please view our other offers.',
      shouldDisplaySearchAgain: false,
      shouldDisplaySpecialOffers: true,
      shouldDisplaySignIn: false,
      shouldDisplaySignUp: false
    },
    INACTIVE_OFFER_CODE: {
      shouldDisplayModal: false,
      message:
        'Unfortunately those dates are outside the range of this offer or this offer has expired. Please check the offer details or select a different offer code.',
      shouldDisplaySearchAgain: false,
      shouldDisplaySpecialOffers: true,
      shouldDisplaySignIn: false,
      shouldDisplaySignUp: false
    },
    [`MAXIMUM_ADVANCE_BOOKING_${daysNumberVariable}`]: {
      shouldDisplayModal: false,
      message: `Offer code <b>${offerCode}</b> requires booking within <b>${variableDaysLabel}</b> of your arrival to receive this great deal. Please select dates within <b>${variableDaysLabel}</b> from today.`,
      shouldDisplaySearchAgain: true,
      shouldDisplaySpecialOffers: true,
      shouldDisplaySignIn: false,
      shouldDisplaySignUp: false
    },
    [`MINIMUM_ADVANCE_BOOKING_${daysNumberVariable}`]: {
      shouldDisplayModal: false,
      message: `Offer code <b>${offerCode}</b> requires booking at least <b>${variableDaysLabel}</b> prior to your arrival to receive this great deal. Please select dates at least <b>${variableDaysLabel}</b> from today.`,
      shouldDisplaySearchAgain: true,
      shouldDisplaySpecialOffers: true,
      shouldDisplaySignIn: false,
      shouldDisplaySignUp: false
    },
    [`MIN_STAY_${daysNumberVariable}`]: {
      shouldDisplayModal: true,
      message: `Offer code <b>${offerCode}</b> is a <b>${daysNumberVariable} night</b> promotion please add <b>${daysNumberVariable -
        stayNumberOfNights} night(s)</b> to your stay. Or view our deals page for other promotions.`,
      shouldDisplaySearchAgain: true,
      shouldDisplaySpecialOffers: true,
      shouldDisplaySignIn: false,
      shouldDisplaySignUp: false
    },
    [`MAX_STAY_${daysNumberVariable}`]: {
      shouldDisplayModal: false,
      message: `Offer code <b>${offerCode}</b> is a <b>${daysNumberVariable} night</b> promotion please remove <b>${stayNumberOfNights -
        daysNumberVariable} night(s)</b> from your stay. Or view our deals page for other promotions.`,
      shouldDisplaySearchAgain: true,
      shouldDisplaySpecialOffers: true,
      shouldDisplaySignIn: false,
      shouldDisplaySignUp: false
    }
  };

  // If it's contain some restrictions with number,
  // validating if this number is valid or not.
  if (
    offerCodeErrorNumbers &&
    (!daysNumberVariable || !stayNumberOfNights || daysNumberVariable - stayNumberOfNights === 0)
  ) {
    return undefined;
  }

  offerCodeError && gaTrackingPlanSearchErrorCode(JSON.stringify(offerCodeError));

  return offerCodeError
    ? {
        ...errorTypes[offerCodeError],
        offerCode: offerCode,
        offerCodeError: offerCodeError
      }
    : undefined;
};

export const getCurrencyCodeText = currencyCode => {
  return (currencyCode && CURRENCY_CODE[currencyCode] && `(${CURRENCY_CODE[currencyCode]})`) || null;
};

export const shouldHideBookingWidget = () => {
  return window.hideBookingWidget;
};

export const isCaliforniaVerification = resortLocation => {
  return {
    isCaliforniaResorts: resortLocation === 'MANTCA' || resortLocation === 'SOUTCA'
  };
};

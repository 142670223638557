import LodgeApiClient from '../../api/clients/LodgeApiClient';
import { initializeState } from '../../utilities/storageUtils';

const getAllLodgesLocationsSuccessType = 'GET_LODGES_LOCATIONS_SUCCESS';
const getAllLodgesLocationsLoadingType = 'GET_LODGES_LOCATIONS_LOADING';
const getAllLodgesLocationsErrorType = 'GET_LODGES_LOCATIONS_ERROR';
const getAllLodgesLocationsLoadingClear = 'CLEAR_LOADING_LODGES_CONFIG';

const initialState = {
  lodgesLocationsFetchIsLoading: false,
  lodgesLocationsFetchError: '',
  locationList: {}
};

export const actionCreators = {
  getAllLodgesLocation: () => async dispatch => {
    dispatch({ type: getAllLodgesLocationsLoadingClear });
    dispatch({ type: getAllLodgesLocationsLoadingType, isLoading: true });

    try {
      const configurationClient = new LodgeApiClient();
      const result = await configurationClient.getLodgesLocations();
      const lodges = result.data.reduce((prev, current) => {
        return {
          ...prev,
          [current.id]: {
            address: current.Address,
            booking_phone: current.PhoneNumber,
            display: current.Name,
            shortDisplayName: current.Name,
            mobile_booking_phone: current.MobileBookingPhone,
            phone: current.PhoneNumber,
            operaCode: current.id,
            url: current.ShortName,
            region: current.Region,
            birthdayEmail: current.BirthdayEmail,
            maxOccupancy: current.MaxOccupancy
          }
        };
      }, {});

      dispatch({
        type: getAllLodgesLocationsSuccessType,
        locationList: lodges
      });
      dispatch({ type: getAllLodgesLocationsLoadingType, isLoading: false });
    } catch (error) {
      dispatch({
        type: getAllLodgesLocationsErrorType,
        locationList: {},
        requestFailed: true,
        errorMessage: `Oops, ${error.message}`
      });
      dispatch({ type: getAllLodgesLocationsLoadingType, isLoading: false });
    }
  }
};

export const reducer = (state, action) => {
  state = initializeState(state, initialState);

  switch (action.type) {
    // Get Resort Locations SUCCESS
    case getAllLodgesLocationsSuccessType:
      return {
        ...state,
        locationList: action.locationList,
        requestFailed: false,
        errorMessage: ''
      };
    // Get Resort Locations LOADING
    case getAllLodgesLocationsLoadingType:
      return {
        ...state,
        isLoading: action.isLoading
      };
    // Get Resort Locations FAIL
    case getAllLodgesLocationsErrorType:
      return {
        ...state,
        locationsList: {},
        requestFailed: action.requestFailed,
        errorMessage: action.errorMessage
      };
    case getAllLodgesLocationsLoadingClear:
      return {
        ...state,
        errorMessage: ''
      };

    default:
      return state;
  }
};

export default reducer;

export const getLocationList = state => state.locationList;

export const getLocation = (state, locationCode) => state?.locationList[locationCode] || {};

export const getLocationPhone = (state, locationCode) => getLocation(state, locationCode).phone;

export const getLocationURL = (state, locationCode) => getLocation(state, locationCode).url;

export const getLocationDisplayName = (state, shorthandOrCode) => getLocation(state, shorthandOrCode).display;

/**
 * Check if the user has a location already selected
 * @param {array} locationSelectedValues Locations selected by the user
 * @param {string} locationCode Property code to check if the user has selected
 * @returns bool whether the user has a location already selected
 */
export const containsLocation = (locationSelectedValues, locationCode) => {
  return locationSelectedValues.includes(locationCode);
};

/**
 * Get locations/properties by region in a dictionary
 * @returns dict
 */
export const getPropertiesByRegion = state => {
  let regions = Object.entries(state.locationList).map(([key, value]) => {
    return value.region;
  });
  regions = [...new Set(regions)];

  const locations = Object.entries(state.locationList).map(([key, value]) => {
    return {
      display: value.display,
      region: value.region,
      operaCode: value.operaCode
    };
  });

  let propertiesByRegion = {};

  regions.forEach(region => {
    propertiesByRegion[region] = locations.filter(location => location.region === region);
  });

  return propertiesByRegion;
};

/**
 * Get regions selected by the user
 * @param {array} locationSelectedValues Locations selected by the user
 * @returns array containing the names of the regions where the user is subscribed
 */
export const regionsSelected = (state, locationSelectedValues) => {
  const propsByRegion = getPropertiesByRegion(state);
  const filtered = Object.keys(propsByRegion).filter(key =>
    propsByRegion[key].some(location => containsLocation(locationSelectedValues, location.operaCode))
  );

  return filtered;
};

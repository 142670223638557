import { DATE_FORMATS, DATE_LOCALE } from './constants';
import { GwDatesWrapper } from './gwDatesWrapper';

/**
 *
 * @param {Date} date to be converted
 * @returns Date in the format MM/YY
 */
export const getDateMonthDayFormat = date => {
  return date && GwDatesWrapper.format(date, DATE_FORMATS.monthDayFormat);
};

/**
 * @param {Date | string} date to be converted
 * @returns Date in the format Month. DD, YYYY
 */
export const getMonthLetterDayYearFormat = date => {
  if (!date) {
    return '';
  }

  if (typeof date === 'string') {
    return GwDatesWrapper.format(date, DATE_FORMATS.monthLetterDayYear);
  }

  return GwDatesWrapper.format(date, DATE_FORMATS.monthLetterDayYear);
};

const DEFAULT_CONFIG = {
  format: DATE_FORMATS.default,
  getFirstDateOfCurrentMonth: false,
  getLastDateOfCurrentMonth: false
};

/**
 * Method to format a Date object into a string.
 * @param {Date} initialDate Date to format.
 * @param {object} optionsObj An object that includes some options.
 * @param {string} optionsObj.format A conversion formatmoment, by default the format used is 'YYYY-MM-DD'.
 * @param {boolean} optionsObj.getFirstDateOfCurrentMonth Indicates wether or not the formatted day should be specified at the first date of the month.
 * @param {boolean} optionsObj.getLastDateOfCurrentMonth Indicates wether or not the formatted day should be specified at the last date of the month.
 * @returns {string} A date string in the provided format.
 */
export const formatDate = (initialDate, optionsObj) => {
  const options = {
    ...DEFAULT_CONFIG,
    ...optionsObj
  };

  const { format, getLastDateOfCurrentMonth, getFirstDateOfCurrentMonth } = options;

  let date = initialDate;
  if (getFirstDateOfCurrentMonth) {
    date = new Date(initialDate.getFullYear(), initialDate.getMonth(), 1);
  }
  if (getLastDateOfCurrentMonth) {
    date = new Date(initialDate.getFullYear(), initialDate.getMonth() + 1, 0);
  }

  const regexes = [/yyyy/i, /MM/, /dd/i, /hh/i, /mm/, /ss/i];
  const alternateRegexes = [/yyyy/i, /M/, /d/i, /h/i, /m/, /s/i];
  const keys = ['year', 'month', 'day', 'hour', 'minute', 'second'];
  const matches = regexes.map(regex => regex.test(format));

  let result = format;
  keys.forEach((key, index) => {
    const options = {};
    if (key === 'year') {
      options[key] = 'numeric';
    } else {
      options[key] = matches[index] ? '2-digit' : 'numeric';
    }

    const value = date.toLocaleDateString(DATE_LOCALE, options);

    const regex = matches[index] ? regexes[index] : alternateRegexes[index];

    result = result.replace(regex, value);
  });

  return result;
};

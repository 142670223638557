import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import debounce from 'lodash-es/debounce';
import ReactGA from 'react-ga';
import { Tracker } from 'react-tracker';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import trackListeners from '../infrastructure/middleware/analytics/listeners';
import trackingMiddleware from '../infrastructure/middleware/analytics/reduxTracking';
import { deleteState, loadState, saveState } from '../infrastructure/middleware/storage/reduxLocalStorage';
import { getResortLocationUrl } from '../utilities/resortLocation';
import createRootReducer from './reducers';

export const history = createBrowserHistory({
  basename: getResortLocationUrl()
});

// Set page on every router navigation, page view is triggered from GTM
history.listen(location => {
  ReactGA.set({ page: location.pathname });
});

// Set location and page on first load
ReactGA.set({
  location: window.location.href,
  page: window.location.pathname + window.location.search
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const tracker = new Tracker(trackListeners);

const middleware = [thunk, routerMiddleware(history), trackingMiddleware(tracker)];

const debounceTimeout = 900000; // 15 min -- 30000; // 30 sec

// WARNING: This will override any values previously saved
// if property doesn't exist in the storage it will be created
const preloadedStatesDict = {
  'account.changePwdReqIsLoading': false,
  'account.loginHasFailed': false,
  'account.unlockAccountReqIsLoading': false,
  'account.unlockAccountReqHasFailed': false,
  'account.unlockAccountReqHasSucceded': false,
  'account.loginErrorMessage': '',
  'account.unlockAccountReqErrorMessage': '',
  'account.forgotPwdReqIsLoading': false,
  'account.forgotPwdReqHasFinished': false,
  'account.changePwdReqHasFailed': false,
  'account.changePwdReqHasSucceded': false,
  'account.changePwdReqErrorMessage': '',
  'account.creatingNewAccount': false,
  'account.newAccountHasSucceded': false,
  'account.newAccountValidationErrors': null,
  'account.newAccountErrorMessage': '',
  'plans.availabilityReqIsLoading': false,
  'plans.availabilityRequestFailed': false,
  'plans.searchesGetReqIsLoading': false,
  'plans.searchesSetReqIsLoading': false,
  'plans.searchesDelReqIsLoading': false,
  'plans.searchesReqFailed': false,
  'plans.filtersApplied': {
    themed: false,
    standard: false,
    premium: false,
    accessibility: false
  },
  'plans.sorting': 'rec',
  'plans.sortSuite': '',
  'plans.oldFiltersApplied': {},
  'plans.requiresQualifyingID': false,
  'plans.qualifyingIDError': false,
  'plans.isOpenRatesCalendar': false,
  'plans.availabilityHighestRatesReqIsLoading': false,
  'plans.availabilityHighestRatesRequestFailed': false,
  'plans.getAvailabilityRangeIsLoading': false,
  'plans.getAvailabilityRangeFailed': false,
  'plans.getAvRangeBySuiteIsLoading': false,
  'plans.getAvRangeBySuiteFailed': false,
  'plans.itemQuantityReqIsLoading': false,
  'plans.itemQuantityReqFailed': false,
  'plans.isBookingWidgetFocused': false,
  'plans.isErrorModalAlreadyClosed': false,
  'plans.availabilityErrorMessage': '',
  'plans.searchesErrorMessage': '',
  'plans.getAvailabilityRangeMessage': '',
  'plans.getAvRangeBySuiteErrorMsg': '',
  'plans.status': '',
  'plans.offerCodeError': {},
  'plans.availabilityHighestRatesIsLoading': false,
  'plans.availabilityHighestRatesList': [],
  'plans.availabilityList': [],
  'plans.emailCampaign': {},
  'plans.lcoPackages': {},
  'plans.offerType': '',
  'plans.personalizationResponses': [],
  'plans.promoDescription': '',
  'plans.qualifyingID': '',
  'plans.recentSearchesList': [],
  'plans.selectedSuiteForCalendar': '',
  'plans.selectedSuiteForRange': '',
  'packages.isLoading': false,
  'packages.requestFailed': false,
  'packages.isLateCheckoutDenied': false,
  'packages.isLateCheckoutOffered': false,
  'packages.cabanasAvailabilityList': [],
  'packages.packageAvailabilityList': [],
  'packages.errorMessage': '',
  'packages.status': '',
  'birthdayParty.summaryData': {},
  'birthdayParty.sendingPost': false,
  'birthdayParty.postFailed': false,
  'birthdayParty.postErrorMessage': '',
  'suite.getAvailabilityLoading': false,
  'suite.suiteRateMismatched': false,
  'suite.errorMessage': '',
  'suite.onSuiteSelectionError': '',
  'suite.responseHasPackages': false,
  'addApackage.addedPackages': {},
  'addApackage.flashMessage': '',
  'entities.eventDetails': {},
  'entities.bookingEngineAlert': undefined,
  'entities.suites': undefined,
  'entities.packages': undefined,
  'entities.cabanas': undefined,
  'entities.deal.sendingGet': false,
  'entities.deal.getFailed': false,
  'entities.deal.getErrorMessage': '',
  'entities.reservationDetails.cabanaRequestSuccess': false,
  'entities.reservationDetails.isCabanaLoading': false,
  'entities.reservationDetails.isLoading': false,
  'entities.reservationDetails.requestSuccess': false,
  'globalModal.isVisible': false,
  'globalModal.keyContentComponent': undefined,
  'globalModal.shouldUseGWComponent': false,
  'globalModal.params': {},
  'profile.isLoading': false,
  'profile.isUpdateSuccess': false,
  'profile.user': {},
  'profile.isUpdated': false,
  'offer.description': false,
  'offer.validOffer': false,
  'offer.errorMessage': false,
  reservations: {},
  'dayPasses.isLoading': false,
  'dayPasses.isDayPassAvailable': false,
  'dayPasses.availabilityData': [],
  'dayPasses.promoCodeData': null,
  'dayPasses.isDaypassFetching': false,
  'lodgeConfig.configFetchError': '',
  'lodgeConfig.configFetchIsLoading': false,
  lodgesLocations: {},
  'leadGen.isProcessing': false,
  'leadGen.isSignupSuccess': false,
  'leadGen.isSignupFailure': false
};

let persistedState = loadState(preloadedStatesDict);
export const localCreateStore = () => {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    persistedState,
    composeEnhancer(
      applyMiddleware(
        ...middleware
        // for dispatching history actions
        // ... other middlewares ...
      )
    )
  );

  return store;
};

export const store = localCreateStore();

store.subscribe(
  debounce(() => {
    deleteState();
    console.log('Storage deleted');
  }, debounceTimeout)
);

store.subscribe(
  debounce(() => {
    const state = store.getState();
    // We should not persist router state
    const { router, ...stateToPersist } = state;
    saveState(stateToPersist, preloadedStatesDict);
  }, 100)
);

/**
 * THIS LOGIC IS DEPRECATED
 */

// This is an Auxiliary file for the files
// where we cannot get the information from Redux
// FILES:
// src/infrastructure/middleware/analytics/ActionListeners/EventSuccessListeners.js
// src/infrastructure/middleware/analytics/analyticsObjectBuilders.js
// src/infrastructure/middleware/analytics/analyticsUtils.js
// src/store/componentStores/Account.js
// src/store/componentStores/BirthdayParty.js
// src/store/componentStores/deal.js
// src/utilities/reservationsUtils.js
// src/utilities/analytics.js

export const getLocationDisplayName = shorthandOrCode => {
  const location = ResortLocationsAux;
  if (location[shorthandOrCode]) {
    return location[shorthandOrCode].display;
  } else {
    return shorthandOrCode;
  }
};

export const getLocation = locationCode => {
  const locations = ResortLocationsAux;
  return locations[locationCode] || {};
};

export const ResortLocationsAux = {
  MANTCA: {
    address: null,
    booking_phone: null,
    display: 'San Francisco / Manteca, CA',
    shortDisplayName: 'San Francisco, CA',
    mobile_booking_phone: null,
    phone: null,
    operaCode: 'MANTCA',
    url: 'northern-california',
    region: 'West',
    birthdayEmail: ''
  },
  SOUTCA: {
    address: '12681 Harbor Blvd., Garden Grove, CA 92840',
    booking_phone: '714.591.4482',
    display: 'Anaheim, CA',
    shortDisplayName: 'Garden Grove, CA',
    mobile_booking_phone: '714.591.4483',
    phone: '888.960.9653',
    operaCode: 'SOUTCA',
    url: 'southern-california',
    region: 'West',
    birthdayEmail: 'Birthday-SouthernCalifornia@greatwolf.com'
  },
  SCOTAZ: {
    address: '7333 N. Pima Road Scottsdale, AZ 85258',
    booking_phone: null,
    display: 'Scottsdale / Salt River, AZ',
    shortDisplayName: 'Scottsdale, AZ',
    mobile_booking_phone: null,
    phone: null,
    operaCode: 'SCOTAZ',
    url: 'arizona',
    region: 'South',
    birthdayEmail: 'Birthday-Scottsdale@greatwolf.com'
  },
  ATLAGA: {
    address: '2301 Whitesville Road, LaGrange, GA 30240',
    booking_phone: '706.407.0439',
    display: 'Atlanta / LaGrange, GA',
    shortDisplayName: 'LaGrange, GA',
    mobile_booking_phone: '706.407.0454',
    phone: '844.473.9653',
    operaCode: 'ATLAGA',
    url: 'georgia',
    region: 'South',
    birthdayEmail: 'Birthday-LaGrange@greatwolf.com'
  },
  BLOOMN: {
    address: '1700 American Boulevard East, Bloomington, MN 55425',
    booking_phone: '952.562.0463',
    display: 'Minneapolis / Bloomington, MN',
    shortDisplayName: 'Bloomington, MN',
    mobile_booking_phone: '952.562.0462',
    phone: '952.851.9653 ',
    operaCode: 'BLOOMN',
    url: 'minnesota',
    region: 'Midwest',
    birthdayEmail: 'Birthday-Bloomington@greatwolf.com'
  },
  NEWEMA: {
    address: '150 Great Wolf Drive, Fitchburg, MA 01420',
    booking_phone: '978.627.4201',
    display: 'Boston / Fitchburg, MA',
    shortDisplayName: 'Fitchburg, MA',
    mobile_booking_phone: '978.627.4425',
    phone: '866.678.9653',
    operaCode: 'NEWEMA',
    url: 'new-england',
    region: 'Northeast',
    birthdayEmail: 'Birthday-NewEngland@greatwolf.com'
  },
  CONCNC: {
    address: '10175 Weddington Road, Concord, NC 28027',
    booking_phone: '704.631.5522',
    display: 'Charlotte / Concord, NC',
    shortDisplayName: 'Concord, NC',
    mobile_booking_phone: '704.631.5523',
    phone: '866.925.9653',
    operaCode: 'CONCNC',
    url: 'concord',
    region: 'South',
    birthdayEmail: 'Birthday-Concord@greatwolf.com'
  },
  GURNIL: {
    address: '1700 Nations Drive, Gurnee, IL 60031',
    booking_phone: '847.596.3949',
    display: 'Chicago / Gurnee, IL',
    shortDisplayName: 'Gurnee, IL',
    mobile_booking_phone: '847.596.3948',
    phone: '844.482.9653',
    operaCode: 'GURNIL',
    url: 'illinois',
    region: 'Midwest',
    birthdayEmail: 'Birthday-Gurnee@greatwolf.com'
  },
  MASOOH: {
    address: '2501 Great Wolf Drive, Mason, OH 45040',
    booking_phone: '513.234.4143',
    display: 'Cincinnati / Mason, OH',
    shortDisplayName: 'Mason, OH',
    mobile_booking_phone: '513.234.4155',
    phone: '800.913.9653',
    operaCode: 'MASOOH',
    url: 'mason',
    region: 'Midwest',
    birthdayEmail: 'Birthday-Mason@greatwolf.com'
  },
  COLOCO: {
    address: '9494 Federal Drive, Colorado Springs, CO 80921',
    booking_phone: '719.387.0280',
    display: 'Colorado Springs, CO',
    shortDisplayName: 'Colorado Springs, CO',
    mobile_booking_phone: '719.387.0284',
    phone: '844-553-9653',
    operaCode: 'COLOCO',
    url: 'colorado-springs',
    region: 'West',
    birthdayEmail: 'Birthday-ColoradoSprings@greatwolf.com'
  },
  GRANWA: {
    address: '20500 Old Highway 99 SW, Centralia, WA 98531',
    booking_phone: '360.347.1146',
    display: 'Grand Mound, WA',
    shortDisplayName: 'Grand Mound, WA',
    mobile_booking_phone: '360.347.1336',
    phone: '800.640.9653',
    operaCode: 'GRANWA',
    url: 'grand-mound',
    region: 'West',
    birthdayEmail: 'Birthday-GrandMound@greatwolf.com'
  },
  GRAPTX: {
    address: '100 Great Wolf Drive, Grapevine, TX 76051',
    booking_phone: '817.203.8565',
    display: 'Grapevine, TX',
    shortDisplayName: 'Grapevine, TX',
    mobile_booking_phone: '817.778.8151',
    phone: '800.693.9653',
    operaCode: 'GRAPTX',
    url: 'grapevine',
    region: 'South',
    birthdayEmail: 'Birthday-Grapevine@greatwolf.com'
  },
  KANSKS: {
    address: '10401 Cabela Drive, Kansas City, KS 66111',
    booking_phone: '913.871.4402',
    display: 'Kansas City, KS',
    shortDisplayName: 'Kansas City, KS',
    mobile_booking_phone: '913.871.4432',
    phone: '800.608.9653',
    operaCode: 'KANSKS',
    url: 'kansas-city',
    region: 'Midwest',
    birthdayEmail: 'Birthday-KansasCity@greatwolf.com'
  },
  NIAGON: {
    address: '3950 Victoria Avenue, Niagara Falls, ON L2E 7M8',
    booking_phone: '866.956.9653',
    display: 'Niagara Falls, Ontario',
    shortDisplayName: 'Niagara Falls, ON',
    mobile_booking_phone: '888.978.9653',
    phone: '800.605.9653',
    operaCode: 'NIAGON',
    url: 'niagara',
    region: 'Canada',
    birthdayEmail: ''
  },
  POCOPA: {
    address: '1 Great Wolf Drive, Scotrun, PA 18355',
    booking_phone: '570.664.5013',
    display: 'Pocono Mountains, PA',
    shortDisplayName: 'the Poconos',
    mobile_booking_phone: '570.664.5014',
    phone: '800.768.9653',
    operaCode: 'POCOPA',
    url: 'poconos',
    region: 'Northeast',
    birthdayEmail: 'Birthday-Poconos@greatwolf.com'
  },
  SANDOH: {
    address: '4600 Milan Road (US 250), Sandusky, OH 44870',
    booking_phone: '419.239.2319',
    display: 'Sandusky, OH',
    shortDisplayName: 'Sandusky, OH',
    mobile_booking_phone: '419.239.2596',
    phone: '800.641.9653',
    operaCode: 'SANDOH',
    url: 'sandusky',
    region: 'Midwest',
    birthdayEmail: 'Birthday-Sandusky@greatwolf.com'
  },
  TRAVMI: {
    address: '3575 N. US 31 S., Traverse City, MI 49684',
    booking_phone: '231.486.2570',
    display: 'Traverse City, MI',
    shortDisplayName: 'Traverse City, MI',
    mobile_booking_phone: '231.486.2571',
    phone: '866.478.9653',
    operaCode: 'TRAVMI',
    url: 'traverse-city',
    region: 'Midwest',
    birthdayEmail: 'Birthday-TraverseCity@greatwolf.com'
  },
  WILLVA: {
    address: '549 East Rochambeau Drive, Williamsburg, VA 23188-2148',
    booking_phone: '757.603.4569',
    display: 'Williamsburg, VA',
    shortDisplayName: 'Williamsburg, VA',
    mobile_booking_phone: '757.603.4570',
    phone: '800.551.9653',
    operaCode: 'WILLVA',
    url: 'williamsburg',
    region: 'South',
    birthdayEmail: 'Birthday-Williamsburg@greatwolf.com'
  },
  DELLWI: {
    address: '1400 Great Wolf Drive, Baraboo, WI 53913',
    booking_phone: '608.844.8116',
    display: 'Wisconsin Dells, WI',
    shortDisplayName: 'Wisconsin Dells, WI',
    mobile_booking_phone: '608.844.8415',
    phone: '800.559.9653',
    operaCode: 'DELLWI',
    url: 'wisconsin-dells',
    region: 'Midwest',
    birthdayEmail: 'Birthday-WisconsinDells@greatwolf.com'
  },
  NAPLFL: {
    address: '3890 City Gate Blvd N',
    booking_phone: '',
    display: 'Naples, FL',
    shortDisplayName: 'Naples, FL',
    mobile_booking_phone: '877.478.9653',
    phone: '570.688.9899',
    operaCode: 'NAPLFL',
    url: 'naples',
    region: 'Southeast',
    birthdayEmail: ''
  },
  WEBSTX: {
    address: '1000 Great Wolf Way',
    booking_phone: '',
    display: 'Houston/Webster, TX',
    shortDisplayName: 'Houston/Webster, TX',
    mobile_booking_phone: '',
    phone: '',
    operaCode: 'WEBSTX',
    url: 'webster',
    region: 'South',
    birthdayEmail: ''
  },
  PERRMD: {
    address: '1240 Chesapeake Overlook Parkway',
    booking_phone: '877.478.9653',
    display: 'Baltimore / Perryville, MD',
    shortDisplayName: 'Baltimore / Perryville, MD',
    mobile_booking_phone: '877.478.9653',
    phone: '570.688.9899',
    operaCode: 'PERRMD',
    url: 'maryland',
    region: 'Northeast',
    birthdayEmail: ''
  }
};

export default ResortLocationsAux;

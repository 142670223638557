import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { GWDayPickerSingleDateController } from '../../_moment_/GWReactDatesWrapper';
import '../../_moment_/gw_datepicker.css';
// *** Leave following commented lines in place till 30-JUN-2024 ***
// import moment from 'moment';
// *** Leave following commented lines in place till 30-JUN-2024 ***
// import momentPropTypes from 'react-moment-proptypes';

import { useMediaQuery } from 'react-responsive';
import { DATE_FORMATS } from '../../../utilities/constants';
// import {
//   DayPickerSingleDateController,
//   isInclusivelyAfterDay
// } from 'react-dates';
// import 'react-dates/initialize';
// import 'react-dates/lib/css/_datepicker.css';
import { GwDatesWrapper } from '../../../utilities/gwDatesWrapper';

import theme from '../themes/theme';
import { CalendarWrapper } from './styles';

const propTypes = {
  /** The date controlling variable. Should be a moment obj */
  // *** Leave following commented lines in place till 30-JUN-2024 ***
  //date: momentPropTypes.momentObj,
  /** Callback function called when user selects a date */
  onChange: PropTypes.func.isRequired,
  /** Array of available dates (selectable dates). Format: '2021-12-23T00:00:00' */
  availableDates: PropTypes.arrayOf(PropTypes.string).isRequired,
  /** Callback function called when clicked to next month. Contains a moment obj as param */
  onMonthChange: PropTypes.func,
  /** Bool flag to control the loading state */
  isLoading: PropTypes.bool
};

const defaultProps = {
  date: null,
  onMonthChange: null,
  onPreviousMonthClick: null,
  isLoading: false
};

const shouldDisableButton = currentMonth =>
  GwDatesWrapper.year(currentMonth) <= GwDatesWrapper.year(GwDatesWrapper.today()) &&
  GwDatesWrapper.month(currentMonth) <= GwDatesWrapper.month(GwDatesWrapper.today());

/** Single static datepicker. It has blocked days feature. Note: the available days are being randomly mocked */
const Calendar = ({ date, onChange, availableDates, onMonthChange, isLoading }) => {
  const [focused, setFocused] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(GwDatesWrapper.today());

  const isDesktop = useMediaQuery({
    query: `(min-width: ${theme.breakpoints.lg})`
  });

  const isDateOutsideRage = date => {
    return GwDatesWrapper.isBefore(date, GwDatesWrapper.today(), 'day');
  };

  const isDayBlocked = date => {
    // We don't want past dates to be strikethrough, but they'll be disabled
    if (isDateOutsideRage(date)) {
      return false;
    }

    return !availableDates.find(d => GwDatesWrapper.isSame(date, d, 'day'));
  };

  const showLoading = date => isLoading || GwDatesWrapper.month(date) !== GwDatesWrapper.month(currentMonth);

  const onChangeMonth = date => {
    setCurrentMonth(GwDatesWrapper.format(date, DATE_FORMATS.default));
    onMonthChange(date);
  };

  return (
    <CalendarWrapper>
      <GWDayPickerSingleDateController
        date={date}
        onDateChange={onChange}
        focused={focused}
        onFocusChange={() => setFocused(true)}
        numberOfMonths={1}
        hideKeyboardShortcutsPanel
        enableOutsideDays={false}
        daySize={isDesktop ? 60 : 47}
        verticalBorderSpacing={10}
        isOutsideRange={isDateOutsideRage}
        isDayBlocked={isDayBlocked}
        onNextMonthClick={onChangeMonth}
        onPrevMonthClick={onChangeMonth}
        weekDayFormat={'ddd'}
        noNavPrevButton={shouldDisableButton(currentMonth)}
        //Using highlighted feature to control the loading state
        isDayHighlighted={showLoading}
      />
    </CalendarWrapper>
  );
};

Calendar.propTypes = propTypes;
Calendar.defaultProps = defaultProps;

export default Calendar;

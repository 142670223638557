import styled, { css } from 'styled-components';
import theme from '../ui-kit/themes/theme';
import { fontSize, height } from 'styled-system';

import { Text, Flex } from '../ui-kit';
import ArrowRightIcon from '../ui-kit/icons/ArrowRight';

const dateSize = props => {
  const cases = {
    small: `${props.theme.fontSizes[0]}px`,
    medium: `${props.theme.fontSizes[2]}px`,
    large: `${props.theme.fontSizes[5]}px`
  };

  return `font-size: ${cases[props.size]};`;
};

const iconSize = size => {
  const cases = {
    small: theme.fontSizes[0],
    medium: theme.fontSizes[2],
    large: theme.fontSizes[5]
  };

  return cases[size];
};

export const CheckInLabel = styled(Text)`
  ${fontSize}
  ${height}
  font-weight: medium;
  color: ${theme.colors.deepLakeBlue[100]};
  margin-bottom: ${theme.space[3]}px;
  font-size: 12px;
  ${({ lineHeight }) => {
    return css`
      line-height: ${lineHeight};
    `;
  }};
`;

export const Container = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  color: deepLakeBlue;
  align-items: stretch;
  width: 100%;
  margin-top: ${theme.space[7]}px;
`;

export const DateText = styled(Text)`
  font-weight: bold;
  color: ${theme.colors.deepLakeBlue[100]};
  font-size: 20px;
  ${dateSize};
  ${({ lineHeight }) => {
    return css`
      line-height: ${lineHeight};
    `;
  }};
`;

export const IconContainer = styled(Flex)`
  display: flex;
  color: ${theme.colors.preciousStoneBlue[100]};
  flex: 1;
  align-items: flex-end;
  justify-content: center;
`;

export const Icon = styled(ArrowRightIcon)`
  ${iconSize}
`;

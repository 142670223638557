import PropTypes from 'prop-types';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { COSTSUMMARY_TOOLTIP, ROOM_RATE_TYPE } from '../../../../../../utilities/constants';
import { Box, Text } from '../../../../../ui-kit';
import Tooltip from '../../../../../ui-kit/Tooltip';
import QuestionIcon from '../../../../../ui-kit/icons/Question';
import theme from '../../../../../ui-kit/themes/theme';
import { SummaryContent } from '../../../Content';
import * as COPY from '../utilities/copy';

const SummaryTooltip = ({ isDesktop, title, children }) => {
  return (
    <Tooltip
      title={title}
      borderColor={theme.colors.snowMountainGrey[100]}
      textAlign="left"
      textColor="nightSkyBlack.100"
      alignment="right"
      mobileAlignment={!isDesktop}
      fontSize={'12px'}
      triggerComponent={({ triggerRef }) => (
        <Box ref={triggerRef}>
          <SummaryContent.Tooltip>{children}</SummaryContent.Tooltip>
        </Box>
      )}
    />
  );
};

const propTypes = {
  /** Flag that indicates that the reservation has additional charges */
  hasAdditionalCharges: PropTypes.bool.isRequired,

  /** Array with the taxes and fees for reservation*/
  taxesAndFees: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      amount: PropTypes.number,
      tooltip: PropTypes.string
    })
  )
};

export const AdditionalCharges = ({ hasAdditionalCharges, taxesAndFees, rateType }) => {
  const isDesktop = useMediaQuery({
    minWidth: theme.breakpoints.sm
  });

  if (!hasAdditionalCharges) return null;

  const isRoomOnly = rateType === ROOM_RATE_TYPE.roomOnlyWithoutWaterParkPass;

  return (
    <>
      <SummaryContent.Divider />
      <SummaryContent.Item fontWeight="bold" mb="24px">
        <SummaryContent.Description>
          <Text fontSize={0} lineHeight={16}>
            {COPY.SUMMARY_ADDITIONAL_CHARGES}
          </Text>
        </SummaryContent.Description>
      </SummaryContent.Item>

      {taxesAndFees.map(item => (
        <SummaryContent.Item key={item.id}>
          <SummaryContent.Description>
            <Text fontSize={0} lineHeight={18}>
              {item.name}
            </Text>
            {item.tooltip && (
              <SummaryTooltip
                isDesktop={isDesktop}
                title={isRoomOnly && item.name === 'Resort Fee' ? COSTSUMMARY_TOOLTIP.resortFeeJVersion : item.tooltip}
                ariaKey={item.name}>
                <QuestionIcon size="16.5px" />
              </SummaryTooltip>
            )}
          </SummaryContent.Description>
          <SummaryContent.Amount>
            <Text fontSize={0} lineHeight={18}>{`$${item.amount.toFixed(2)}`}</Text>
          </SummaryContent.Amount>
        </SummaryContent.Item>
      ))}
    </>
  );
};

AdditionalCharges.propTypes = propTypes;

AdditionalCharges.defaultProps = {
  hasAdditionalCharges: false,
  taxesAndFees: [],
  iconSize: 16
};

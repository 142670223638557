import React from 'react';
import Box from '../../ui-kit/Box';
import Flex from '../../ui-kit/Flex';

const SummaryItem = ({ leftColumn, centerColumn, rightColumn, bold, highlight, labelTitle, ...props }) => (
  <Flex
    role="complementary"
    aria-label={`Summary Item ${labelTitle ? labelTitle : ''}`}
    mb={3}
    flexFirection="row"
    justifyContent="flex-end"
    {...props}>
    <Box width={[2 / 3, 2 / 3, 3 / 4, 1 / 2, 1 / 2, 2 / 3]}>{leftColumn}</Box>
    <Box width={[1 / 3, 1 / 3, 1 / 4, 1 / 2, 1 / 2, 1 / 3]}>
      <Flex flexDirection="row" justifyContent="flex-end">
        <Box mr={2}>{centerColumn}</Box>
        <Box>{rightColumn}</Box>
      </Flex>
    </Box>
  </Flex>
);

export default SummaryItem;

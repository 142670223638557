import PropTypes from 'prop-types';
import React from 'react';
import { Box } from '../../../ui-kit';
import Text from '../../../ui-kit/Text';
import AlertSuccessIcon from '../../../ui-kit/icons/AlertSuccessIcon';
import colors from '../../../ui-kit/themes/colors';
import { editCopyByReplaceString } from '../../../utilities/copyFunctions';
import { SummaryContent } from '../Content/SummaryContent';
import SummaryItem from '../SummaryItem';
import * as COPY from './utilities/copy';

const DayPassDetail = ({ passTitle, rate, promoCode, dayPassPromoDescription, amountOfDayPasses }) => {
  return (
    <Box pt={3} color="deepLakeBlue.100">
      <>
        <SummaryItem
          labelTitle={COPY.PRICE_PER_GUEST}
          leftColumn={
            <Text lineHeight={16} fontSize={0} fontWeight="bold">
              {dayPassPromoDescription}
            </Text>
          }
          rightColumn={
            <Text fontSize={0} fontWeight="bold">
              ${rate.toFixed(2)}
            </Text>
          }
        />
        <SummaryItem
          labelTitle={COPY.PRICE_PER_GUEST}
          leftColumn={
            <Text lineHeight={18} fontSize={0} fontWeight="500">
              {amountOfDayPasses} {passTitle}
            </Text>
          }
        />
      </>
      {promoCode ? (
        <SummaryContent.Item fontWeight="bold" color="deepForestGreen.100">
          <SummaryContent.Description>
            <Text lineHeight={16} fontSize={0}>
              {editCopyByReplaceString(COPY.PROMOCODE_APPLIED, promoCode, '<PROMOCODE>')}
            </Text>
          </SummaryContent.Description>
          <AlertSuccessIcon color={colors.successGreen} size={20.5} />
        </SummaryContent.Item>
      ) : null}
    </Box>
  );
};

DayPassDetail.propTypes = {
  passTitle: PropTypes.string,
  rate: PropTypes.number,
  promoCode: PropTypes.string,
  dayPassPromoDescription: PropTypes.string,
  amountOfDayPasses: PropTypes.number
};

DayPassDetail.defaultProps = {
  rate: 0.0
};

export default DayPassDetail;

import styled, { css } from 'styled-components';
import ModalAdapter from './ModalAdapter';
import theme from '../ui-kit/themes/theme';

const StyledModal = styled(ModalAdapter).attrs({
  overlayClassName: 'Overlay',
  modalClassName: 'Modal',
  closeTimeoutMS: 250
})`
  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${props =>
      props.fullSizeContent && props.fullSizeBackgroundColor
        ? props.fullSizeBackgroundColor
        : `${theme.colors.deepLakeBlue[100]}80`};
    transition: opacity 0.25s ease-in-out;
    z-index: ${props => (props.zIndex ? props.zIndex : theme.zIndex.loader)};
    opacity: 0;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }

  .Modal {
    ${props => props.width && `width : ${props.width};`}
    ${props => props.height && `height : ${props.height};`}
    ${props => props.maxWidth && `max-width : ${props.maxWidth};`}
    ${props => props.marginTop && `margin-top : ${props.marginTop};`}
    outline: none;
    max-height: 100%;

    ${({ fullSizeContent }) => {
      if (fullSizeContent) {
        return css`
          width: 100%;
          height: 100%;
          background: ${props =>
            props.fullSizeBackgroundColor ? props.fullSizeBackgroundColor : props.theme.colors.nightSkyBlack[100]};
        `;
      }

      return css`
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        background: ${props => props.theme.colors.pureWhite[0]};
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        padding: ${props => (props.padding ? props.padding : 0)};
        box-shadow: ${props => props.theme.boxShadows[3]};
        border-radius: ${props => (props.borderRadius ? props.borderRadius : '8px')};
      `;
    }}
  }
`;

StyledModal.displayName = 'StyledModal';

StyledModal.defaultProps = {
  theme: theme,
  padding: '30px'
};

export default StyledModal;

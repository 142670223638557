import PropTypes from 'prop-types';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Box, Flex, Text, Tooltip } from '../../../../ui-kit';
import QuestionIcon from '../../../../ui-kit/icons/Question';
import theme from '../../../../ui-kit/themes/theme';
import { SummaryContent } from '../../Content';
import * as COPY from '../utilities/copy';

const propTypes = {
  hasAdditionalCharges: PropTypes.bool.isRequired,
  taxes: PropTypes.number
};

export const AdditionalCharges = ({ taxes, sustainabilityFee, parkingFee, resortFee }) => {
  const isExtraLargeScreen = useMediaQuery({
    minWidth: theme.breakpoints.xl
  });
  return (
    <>
      <SummaryContent.Item fontWeight="bold">
        <SummaryContent.Description style={{ fontSize: '12px' }}>{COPY.ADDITIONAL_CHARGES}</SummaryContent.Description>
      </SummaryContent.Item>

      <SummaryContent.Item>
        <SummaryContent.Description>{COPY.TAXES}</SummaryContent.Description>
        <SummaryContent.Amount>{`$${taxes.toFixed(2)}`}</SummaryContent.Amount>
      </SummaryContent.Item>

      {sustainabilityFee > 0 ? (
        <SummaryContent.Item>
          <SummaryContent.Description>{COPY.SUSTAINABILITY_FEE}</SummaryContent.Description>
          <SummaryContent.Amount>{`$${sustainabilityFee.toFixed(2)}`}</SummaryContent.Amount>
        </SummaryContent.Item>
      ) : null}

      {parkingFee > 0 ? (
        <SummaryContent.Item>
          <SummaryContent.Description>{COPY.PARKING_FEE}</SummaryContent.Description>
          <SummaryContent.Amount>{`$${parkingFee.toFixed(2)}`}</SummaryContent.Amount>
        </SummaryContent.Item>
      ) : null}

      {resortFee > 0 ? (
        <SummaryContent.Item>
          <SummaryContent.Description>
            {COPY.AMENITY_FEE}
            <Flex height={16} ml={2}>
              <Tooltip
                ariaKey="Amenity Fee"
                title={COPY.AMENITY_FEE}
                borderColor={theme.colors.snowMountainGrey[100]}
                textAlign="left"
                alignment={isExtraLargeScreen ? 'center' : 'right'}
                triggerComponent={props => (
                  <Box ref={props.triggerRef}>
                    <QuestionIcon size={16} />
                  </Box>
                )}
                tooltipContent={() => <Text fontSize={0}>{COPY.TOOLTIP_COPY}</Text>}
              />
            </Flex>
          </SummaryContent.Description>
          <SummaryContent.Amount>{`$${resortFee.toFixed(2)}`}</SummaryContent.Amount>
        </SummaryContent.Item>
      ) : null}

      <SummaryContent.Divider />
    </>
  );
};

AdditionalCharges.propTypes = propTypes;

AdditionalCharges.defaultProps = {
  taxes: 0
};

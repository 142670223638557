export const EVENT_ACTIONS = {
  conversion: 'conversion'
};

export const trackGTagEvent = eventAction => {
  if (typeof window.gtag === 'function') {
    window.gtag('event', eventAction, {
      allow_custom_scripts: true,
      send_to: 'DC-8341108/signup/email0+standard'
    });
  }
};

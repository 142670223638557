export const CLOSE_ACCOUNT = 'If you need to close your Voyagers Club account, please call <<phone>>';
export const LOYALTY_USER_COPY = '<amount> Voyagers Points ';
export const USER_COPY = 'Adventure is waiting!';
export const AVAILABLE = 'available';

//LoyaltyEarn.js
export const YOU_WILL_EARN = 'You will earn at least <POINTS> with this booking';
export const NUMBER_POINTS = '<TOTALPOINTS> Voyagers Points';
export const POINTS_EARNED_UPPON_COMPLETION = 'Points are added upon completion of stay';
export const ADD_MORE_NIGHTS = 'Add one more night to earn <POINTS> on this booking';
export const VOYAGERS_POINTS = 'Voyagers Points';
export const JOIN_VOYAGERS = 'Join Voyagers Club for free';
export const JOIN_COPY = 'and add one more night to start earning <JOIN>';
export const EARN_BY_JOIN = 'and earn <JOIN> with this booking';
export const START_EARNING = 'Start earning points with this booking';

export const YOU_HAVE_POINTS = 'You have <POINTS> Voyagers Points!';
export const EARN_POINTS = 'Earn points by booking 2+ nights';
export const SELECT_SUITE = 'Select a suite to apply your credit';
export const YOU_HAVE = 'You have <TEXT> <SECOND_TEXT>';
export const VOYAGERS_POINTS_EXCLAMATION = '<POINTS> Voyagers Points!';
export const VOYAGERS_POINTS_NO_EXCLAMATION = '<POINTS> Voyagers Points';
export const YOU_HAVE_POINTS_BONUS_LINK = 'You have <TEXT> and <SECOND_TEXT>';
export const YOU_HAVE_A_BONUS_DEAL = 'a Bonus Deal available!';
export const YOU_HAVE_BONUS_DEALS = 'Bonus Deals available!';
export const JOIN_VOYAGERS_CLUB_COPY = '<JOIN_UNDERLINE> and earn Voyagers Points with this booking';

//Loyalty profile
export const AVAILABLE_VOYAGERS_POINTS = 'Available Voyagers Points';
export const USE_POINTS_NEXT_BOOKING = 'Use your Points on your next booking';
export const POINTS_ADDED_UPON_COMPLETION = 'Points are added upon completion of stay';
export const BOOK_NOW = 'Book Now';
export const SEE_TERMS_AND_CONDITIONS = 'See Terms & Conditions';
export const EXPIRATION_POINTS = '<POINTS> points will expire on <DATE>';
export const TEMP_EXPIRATION_NOTICE = 'Points expire 15 months after they are earned';

// Loyalty history
export const VOYAGERS_POINTS_HISTORY = 'Voyagers Points History';
export const HISTORY_POINTS = '<POINTS> Points';
export const SEE_MORE = 'See More';
export const SEE_LESS = 'See Less';
export const VOYAGERS_EXPIRATION_HISTORY = 'Points expire 15 months after they are added to your account';

import get from 'lodash-es/get';
import { initializeState } from '../../utilities/storageUtils';

const alertSuccess = 'ALERT_IS_SUCCESSFUL';
const setAlertLoading = 'ALERT_IS_LOADING';
const setAlertError = 'ALERT_GOT_ERROR';

const initialState = {
  data: [],
  isLoading: false,
  hasError: false,
  errorMessage: ''
};

export const actionCreators = {
  // Get deal by promo code.
  getBookingEngineAlerts: () => dispatch => {
    dispatch({ type: setAlertLoading });

    // This logic should be later replaced by an Alert related API.
    const response = window.bookingEngineAlert ? { data: JSON.parse(window.bookingEngineAlert) } : { data: [] };

    dispatch({
      type: alertSuccess,
      data: response.data
    });
  }
};

const reducer = (state, action) => {
  state = initializeState(state, initialState);

  switch (action.type) {
    case alertSuccess:
      return {
        ...state,
        data: action.data,
        isLoading: false,
        hasError: false,
        errorMessage: ''
      };

    case setAlertLoading:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        errorMessage: ''
      };

    case setAlertError:
      return {
        ...state,
        data: [],
        isLoading: true,
        hasError: true,
        errorMessage: action.errorMessage
      };

    default:
      return state;
  }
};

export default reducer;

// Selectors
export const getCurrentBookingEngineAlertDataByIndex = state => {
  return (index = 0) => {
    return get(state, `data.${index}`, {});
  };
};

export const getAmountOfBookingEngineAlertOptions = state => {
  const bookingEngineAlertOptions = get(state, `data`);
  return bookingEngineAlertOptions ? bookingEngineAlertOptions.length : 0;
};

import { isArrayEmpty } from '../../utilities/arraysUtils';
import { DATE_FORMATS, TAXES } from '../../utilities/constants';
import { GwDatesWrapper } from '../../utilities/gwDatesWrapper';

export const handleTotal = (
  userOffers,
  activeOffers,
  showLoyalty,
  loyaltyOptIn,
  pointsBalance,
  suiteTotal,
  currentSelectedPackages,
  setExcedent
) => {
  let offersTotal = 0;
  const offersAmount =
    userOffers &&
    userOffers.map(e => {
      return activeOffers.includes(e.id) && (offersTotal = offersTotal + e.dollarValue);
    });

  let total = suiteTotal;
  if (showLoyalty && loyaltyOptIn) {
    if (offersAmount && !isArrayEmpty(offersAmount) && offersTotal <= total) {
      total = total - offersTotal;
      setExcedent && setExcedent(false);
    } else {
      setExcedent && setExcedent(true);
    }
    if (pointsBalance && pointsBalance > 0 && pointsBalance < total) {
      total = total - pointsBalance;
    } else if (pointsBalance >= total) {
      total = 0;
    }
  }
  let packagesTotal = 0;
  if (currentSelectedPackages) {
    currentSelectedPackages.forEach(currentPackage => {
      if (currentPackage.amount && currentPackage.quantity) {
        let calculateQuantityPrice = currentPackage.amount * currentPackage.quantity;
        packagesTotal = packagesTotal + calculateQuantityPrice;
      }
    });
  }

  return total + packagesTotal;
};

export const handleTotalWithTaxes = (
  userOffers,
  activeOffers,
  showLoyalty,
  loyaltyOptIn,
  pointsBalance,
  suiteTotal,
  taxesAndFees,
  roomAdjustmentTax,
  currentSelectedPackages,
  setExcedent
) => {
  const taxData = taxesAndFees.find(({ name }) => name === TAXES) || {};
  const totalWithoutTaxes = handleTotal(
    userOffers,
    activeOffers,
    showLoyalty,
    loyaltyOptIn,
    pointsBalance,
    suiteTotal,
    currentSelectedPackages,
    setExcedent
  );
  let taxes = handleTaxes(
    Number(taxData.amount || 0),
    showLoyalty,
    loyaltyOptIn,
    pointsBalance,
    suiteTotal,
    roomAdjustmentTax,
    userOffers,
    activeOffers
  ).toFixed(2);

  let feesTotals = 0;
  taxesAndFees.map(item => {
    if (item.name !== TAXES) {
      feesTotals += item.amount;
    }
    return feesTotals;
  });

  return (totalWithoutTaxes + Number(taxes) + feesTotals).toFixed(2);
};

export const handlePointsBalance = (showLoyalty, loyaltyOptIn, pointsBalance, suiteTotal, userOffers, activeOffers) => {
  let balance = 0;
  let offersTotal = 0;

  userOffers &&
    userOffers.map(e => {
      return activeOffers.includes(e.id) && (offersTotal = offersTotal + e.dollarValue);
    });

  if (showLoyalty && loyaltyOptIn) {
    if (pointsBalance > suiteTotal - offersTotal) {
      balance = suiteTotal - offersTotal;
    } else {
      balance = pointsBalance;
    }
  }
  return balance;
};

export const handleTaxes = (
  taxAmount,
  showLoyalty,
  loyaltyOptIn,
  pointsBalance,
  suiteTotal,
  roomAdjustmentTax,
  userOffers,
  activeOffers
) => {
  let offersTotal = 0;

  userOffers &&
    userOffers.forEach(e => {
      activeOffers.includes(e.id) && (offersTotal = offersTotal + e.dollarValue);
    });

  const pointsBalanceTotal = handlePointsBalance(
    showLoyalty,
    loyaltyOptIn,
    pointsBalance,
    suiteTotal,
    userOffers,
    activeOffers
  );

  const adjustmentTax = +((pointsBalanceTotal + offersTotal) * (roomAdjustmentTax / 100)).toFixed(2);
  if (taxAmount < adjustmentTax) {
    return 0;
  }

  return taxAmount - adjustmentTax;
};

export const handleDueAmounts = (
  dueTodayAmount,
  dueAtCheckinAmount,
  roomAdjustmentTax,
  showLoyalty,
  loyaltyOptIn,
  pointsBalance,
  suiteTotal,
  numberOfNights,
  userOffers,
  activeOffers,
  setExcedent,
  handleSummaryTotal
) => {
  let dueTodayDifference = 0;
  let offersTotal = 0;

  userOffers &&
    userOffers.forEach(e => {
      activeOffers.includes(e.id) && (offersTotal = offersTotal + e.dollarValue);
    });

  let oneNightSuite = suiteTotal / numberOfNights;
  let totalPointsBurned = pointsBalance > suiteTotal - offersTotal ? suiteTotal - offersTotal : pointsBalance;
  let pointsBurnedOnDueToday = totalPointsBurned > oneNightSuite ? oneNightSuite : totalPointsBurned;

  let adjustmentTax = +(
    (handlePointsBalance(showLoyalty, loyaltyOptIn, pointsBalance, suiteTotal, userOffers, activeOffers) +
      offersTotal) *
    (roomAdjustmentTax / 100)
  ).toFixed(2);

  let pointsToBurnOnCheckIn =
    totalPointsBurned > pointsBurnedOnDueToday ? totalPointsBurned - pointsBurnedOnDueToday : 0;

  const dueToday = () => {
    let dueTodayValue = dueTodayAmount;
    let totalToDeduct = pointsBurnedOnDueToday + adjustmentTax + offersTotal;
    if (dueTodayValue >= totalToDeduct) {
      dueTodayValue = dueTodayValue - totalToDeduct;
    } else {
      dueTodayDifference = totalToDeduct - dueTodayValue;
      dueTodayValue = 0;
    }
    return showLoyalty && loyaltyOptIn ? dueTodayValue : dueTodayAmount;
  };

  const dueCheckIn = () => {
    let dueAtCheckInValue = dueAtCheckinAmount;

    if (dueAtCheckInValue > pointsToBurnOnCheckIn + dueTodayDifference) {
      dueAtCheckInValue = dueAtCheckInValue - dueTodayDifference - pointsToBurnOnCheckIn;
    }
    const dueTodayAmountToShow =
      showLoyalty && loyaltyOptIn ? +dueAtCheckInValue.toFixed(2) : +dueAtCheckinAmount.toFixed(2);

    const summaryTotalAndDueToday = +handleSummaryTotal - +dueToday();

    if (+summaryTotalAndDueToday.toFixed(2) === dueTodayAmountToShow) {
      return dueTodayAmountToShow;
    } else {
      return +summaryTotalAndDueToday.toFixed(2);
    }
  };

  let due = {
    today: dueToday().toFixed(2),
    atCheckIn: dueCheckIn().toFixed(2)
  };

  return due;
};

export const haveRestrictions = (date, restrictionList, restrictionType) => {
  return restrictionList[GwDatesWrapper.format(date, DATE_FORMATS.default)]?.some(res => res.type === restrictionType);
};

export const getRestrictionNumber = (date, restrictionList, restrictionType) => {
  return restrictionList[GwDatesWrapper.format(date, DATE_FORMATS.default)]?.find(res => res.type === restrictionType)
    ?.number;
};

import { createOfferErrorType } from '../../../../store/componentStores/Offer';
import { getAvailabilityInfoSuccessType } from '../../../../store/componentStores/Plans';
import { getOfferCodeErrorDetails } from '../../../../utilities/planUtils';
import { logOfferCodeError } from '../../../../utilities/analytics';

export const onOfferCodeError = ({
  type,
  offerCodeError,
  errorMessage,
  getState
}) => {
  const shouldLogOfferCodeError =
    type === createOfferErrorType || type === getAvailabilityInfoSuccessType;
  const isAvailabilityError = type === getAvailabilityInfoSuccessType;
  const isOfferCodeError = type === createOfferErrorType;

  if (!shouldLogOfferCodeError) return;

  if (isAvailabilityError) {
    const {
      dates: { checkinDateSelection, checkoutDateSelection } = {}
    } = getState();
    const { errorCode, offerCode } = offerCodeError || {};

    const offerCodeErrorMessage = getOfferCodeErrorDetails({
      offerCode,
      offerCodeError: errorCode,
      checkIn: checkinDateSelection,
      checkOut: checkoutDateSelection
    });

    if (offerCodeErrorMessage && offerCodeErrorMessage.message) {
      logOfferCodeError(offerCodeErrorMessage.message);
    }
  }

  if (isOfferCodeError) {
    logOfferCodeError(errorMessage);
  }
};

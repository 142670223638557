import { API_URLS, getApiUrl } from '../Utils/apiURLConfiguration';
import GWRApiClient from './GWRApiClient';

/**
 * API implementation for the SIP codes
 *
 * @class ResetPasswordApiclient
 * @extends {GWRApiClient}
 */
export default class SipRatesApiClient extends GWRApiClient {
  /**
   * @constructor
   * Calls parent constructor.
   * If there isn't any url provided, then we use the default from the environment variable
   * fills the url and token information
   * @param {string} url
   *  The url where the service endpoints are
   * @param {string} token
   *  The token of the logged user
   */
  constructor(version = '', token = '') {
    let apiVersion = version !== '' ? version : `v2.4`;

    const urlAux = getApiUrl(API_URLS.REACT_APP_SIP_RATE_SERVICE_ENDPOINT, apiVersion);

    super(urlAux, token);
  }

  async getSipRatesByMonthAndYear(sipByMonthAndYear) {
    const urlRequest = `${this.url}/sip`;

    let request = await this.getApiCall(urlRequest, sipByMonthAndYear);

    return request;
  }
}

import get from 'lodash-es/get';
import AuthClient from '../../api/auth/AuthClient';
import UserAccountApiClientTioga from '../../api/clients/UserAccountApiClient';
import UserApiClient from '../../api/clients/UserApiClient';

const updateIsLoadingType = 'IS_LOADING_TYPE';
const getUserProfileType = 'GET_USER_PROFILE';
const getUserProfileSuccessType = 'GET_USER_PROFILE_SUCCESS';
const getUserProfileErrorType = 'GET_USER_PROFILE_ERROR';

const initialState = {
  isLoading: false,
  isUpdateSuccess: false,
  user: {
    firstName: '',
    lastName: '',
    email: '',
    gender: '',
    street: '',
    city: '',
    stateProvince: '',
    country: '',
    zip: '',
    phoneNumber: '',
    streetLine1: '',
    streetLine2: '',
    birthdate: '',
    password: {
      currentPassword: '',
      newPassword: ''
    },
    family: [],
    isSocialAccountLogin: false,
    isConnectedToFacebook: false,
    isConnectedToGoogle: false
  }
};

const authService = new AuthClient();

export const actionCreators = {
  getUserProfileFromStorage: () => (dispatch, getState) => {
    dispatch({ type: getUserProfileType, isLoading: true });

    // merge auth user information and complete user information as well
    const userProfile = getState().profile.user || {};

    let userObj = authService.getUser();
    if (userObj) {
      dispatch({
        type: getUserProfileSuccessType,
        user: { ...userObj, ...userProfile },
        isLoading: false
      });
    } else {
      dispatch({
        type: getUserProfileErrorType,
        user: initialState.user,
        isLoading: false
      });
    }
  },

  getCompleteUserProfile: (user, onGetCompleted) => (dispatch, getState) => {
    dispatch({ type: getUserProfileType, isLoading: true });
    if (!authService.isLoggedIn()) {
      dispatch({
        type: getUserProfileSuccessType,
        isLoading: false,
        user: initialState.user // set inial state user when user logged out so that formik dont bind null to text feld.
      });
    } else {
      let userApiClient = new UserApiClient();
      let getUserHttpCall = userApiClient.getUser();

      let authUser = authService.getUser();

      getUserHttpCall.then(
        response => {
          if (response) {
            const completeUserProfileInfo = response.data;
            dispatch({
              type: getUserProfileSuccessType,
              isLoading: false,
              user: {
                ...authUser,
                ...completeUserProfileInfo,
                canContactBySms: completeUserProfileInfo.contactOptions.sms,
                canContactByEmail: completeUserProfileInfo.contactOptions.email
              } // merge auth user information and complete user information as well
            });
            const currentUserLocalData = JSON.parse(localStorage.getItem('user')) || {};
            if (currentUserLocalData && Object.keys(currentUserLocalData).length > 0) {
              currentUserLocalData.firstName = completeUserProfileInfo.firstName;
              localStorage.setItem('user', JSON.stringify(currentUserLocalData));
            }
            if (onGetCompleted) {
              onGetCompleted(true);
            }
          } else {
            dispatch({
              type: getUserProfileErrorType,
              isLoading: false,
              user: response.data
            });
            if (onGetCompleted) {
              onGetCompleted(true);
            }
          }
        },
        err => {
          dispatch({
            type: getUserProfileErrorType,
            user: initialState.user,
            isLoading: false
          });
        }
      );
    }
  },

  updateUser: (user, onUpdateCompleted) => (dispatch, getDate) => {
    dispatch({ type: updateIsLoadingType, isLoading: true });

    let apiClient = new UserApiClient('v2.1');
    let httpCall = apiClient.updateUser(user);

    httpCall.then(
      response => {
        dispatch({
          type: updateIsLoadingType,
          isLoading: false,
          isUpdateSuccess: true
        });
        if (onUpdateCompleted) {
          onUpdateCompleted(true);
        }
      },
      err => {
        dispatch({ type: updateIsLoadingType, isLoading: false });
        if (onUpdateCompleted) {
          onUpdateCompleted(false);
        }
      }
    );
  },

  subscribeUserSms: (profileId, phoneNumber, canContactBySms) => (dispatch, getState) => {
    dispatch({ type: updateIsLoadingType, isLoading: true });

    const apiClient = new UserApiClient();
    const isLoggedIn = apiClient.shouldUseAuthenticatedCalls();

    const payload = {
      phoneNumber,
      canContactBySms
    };
    // If the current user is logged in then we should use an auth call
    if (isLoggedIn) {
      const httpCall = apiClient.updateSms(payload);

      httpCall.then(
        response => {
          dispatch({
            type: updateIsLoadingType,
            isLoading: false,
            isUpdateSuccess: true
          });
        },
        err => {
          dispatch({ type: updateIsLoadingType, isLoading: false });
        }
      );
    } else {
      // If the user is not logged in we follow the next steps
      // Override user api client to point to user interaction endpoints
      // We don't send any token since this is an anounymous call
      const userClient = new UserApiClient();

      // We call the interactions endpoint to update the user by profile
      const httpCall = userClient.updateSmsByProfileId(profileId, payload);

      httpCall.then(
        response => {
          dispatch({
            type: updateIsLoadingType,
            isLoading: false,
            isUpdateSuccess: true
          });
        },
        err => {
          dispatch({ type: updateIsLoadingType, isLoading: false });
        }
      );
    }
  },

  updateAllPreferences: (userSmsPreferences, canContactByEmail, userLocationPreferences) => async dispatch => {
    const apiClient = new UserApiClient();
    try {
      dispatch({ type: updateIsLoadingType, isLoading: true });
      await apiClient.updateSms(userSmsPreferences);
      await apiClient.updateEmailPreference(canContactByEmail);
      await apiClient.updateLocationPreferences(userLocationPreferences);
      dispatch({
        type: updateIsLoadingType,
        isLoading: false,
        isUpdateSuccess: true
      });
    } catch (err) {
      dispatch({ type: updateIsLoadingType, isLoading: false });
    }
  },

  updateUserSettings: user => async dispatch => {
    const apiClient = new UserAccountApiClientTioga();
    try {
      dispatch({ type: updateIsLoadingType, isLoading: true });
      await apiClient.updateUserSettings(user);
      dispatch({
        type: updateIsLoadingType,
        isLoading: false,
        isUpdateSuccess: true
      });
    } catch (error) {
      dispatch({ type: updateIsLoadingType, isLoading: false });
    }
  }
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case updateIsLoadingType:
      return {
        ...state,
        isLoading: action.isLoading,
        isUpdated: action.isUpdateSuccess
      };
    case getUserProfileType:
      return {
        ...state,
        isLoading: action.isLoading,
        isUpdated: false
      };
    case getUserProfileSuccessType:
      return {
        ...state,
        isLoading: action.isLoading,
        user: action.user,
        isUpdated: false
      };
    case getUserProfileErrorType:
      return {
        ...state,
        isLoading: action.isLoading,
        user: action.user,
        isUpdated: false
      };
    default:
      return state;
  }
};

// Selectors
export const getProfileIsLoading = state => {
  return state.isLoading;
};

export const getUpdateProfileLoading = state => {
  return state.updateIsLoadingType;
};

export const getProfileUpdateStatus = state => {
  return state.isUpdated;
};

export const getProfileEmail = state => {
  return get(state, 'user.email');
};

export const getProfileLastName = state => {
  return get(state, 'user.lastName');
};

export const getProfileFirstName = state => {
  return get(state, 'user.firstName');
};

export const getProfileCountry = state => {
  return get(state, 'user.country');
};

export const getProfileState = state => {
  return get(state, 'user.stateProvince');
};

export const getProfileCity = state => {
  return get(state, 'user.city');
};

export const getProfilePostalCode = state => {
  return get(state, 'user.zip');
};

export const getProfilePhone = state => {
  return get(state, 'user.phoneNumber');
};

export const getProfileAddress = state => {
  const streetLine1 = get(state, 'user.streetLine1');
  const streetLine2 = get(state, 'user.streetLine2');
  if (streetLine2) {
    return streetLine1 + ', ' + streetLine2;
  }
  return streetLine1;
};

export const getCanContactBySms = state => {
  return get(state, 'user.contactOptions.sms');
};

/**
 * Selector to get a user from the store.
 * @param {Object} State Packages Store.
 * @return {Object} User from the store previously got from the API call.
 */
export const getUserFromState = state => get(state, 'user');

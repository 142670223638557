import styled from 'styled-components';

export const CloseButton = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;
  margin-top: 12px;
`;

export const BackButtonWrapper = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  margin-left: -6px;
  padding: 12px 6px;
  background: transparent;
`;

BackButtonWrapper.displayName = 'BackButtonWrapper';

import { combineReducers } from 'redux';

const addPackageType = 'ADD_A_PACKAGE';
const removePackageType = 'REMOVE_A_PACKAGE';
export const addFlashMessageType = 'ADD_FLASH_MESSAGE';
export const clearFlashMessageType = 'CLEAR_FLASH_MESSAGE';
export const clearAllAddedPackagesType = 'CLEAR_ALL_ADDED_PACKAGES';
export const addCabanaType = 'ADD_A_CABANA';
export const removeCabanaType = 'REMOVE_A_CABANA';
export const removeOtherCabanasType = 'REMOVE_OTHER_A_CABANAS';
export const clearPackagesByCodesType = 'CLEAR_PACKAGES_BY_CODES';

const addedPackages = (state = {}, action) => {
  switch (action.type) {
    case addPackageType:
      return {
        ...state,
        [action.id]: {
          id: action.id,
          value: action.value,
          options: action.options,
          chargeType: action.chargeType
        }
      };
    case removePackageType:
      const { [action.id]: deletedItem, ...newState } = state;
      return newState;
    case clearAllAddedPackagesType:
      return {};
    case addCabanaType:
      return {
        ...state,
        [action.id]: {
          id: action.id,
          item: action.item
        }
      };
    case removeCabanaType:
      const { [action.id]: deletedCabana, ...newCabanasState } = state;
      return newCabanasState;
    case removeOtherCabanasType:
      const filteredCabanaPkgs = {};
      Object.keys(state).forEach(key => {
        if (action.items.includes(key)) {
          filteredCabanaPkgs[key] = state[key];
        }
      });
      return filteredCabanaPkgs;
    case clearPackagesByCodesType:
      return { ...action.addedPackages };
    default:
      return state;
  }
};

const flashMessage = (state = '', action) => {
  switch (action.type) {
    case addFlashMessageType:
      return action.message;
    case clearFlashMessageType:
      return '';
    default:
      return state;
  }
};

export default combineReducers({
  addedPackages,
  flashMessage
});

// Actions
export const actionCreators = {
  addPackage: (packageId, value, options, chargeType) => ({
    type: addPackageType,
    id: packageId,
    value,
    options,
    chargeType
  }),
  removePackage: packageId => ({
    type: removePackageType,
    id: packageId
  }),
  addCabana: item => ({
    type: addCabanaType,
    id: `${item.value.rate}~${item.value.packageCode}~${item.value.date}`,
    item: item.value
  }),
  removeCabana: item => ({
    type: removeCabanaType,
    id: `${item.value.rate}~${item.value.packageCode}~${item.value.date}`
  }),
  removeOtherCabanas: itemsList => ({
    type: removeOtherCabanasType,
    items: itemsList
  }),
  clearFlashMessage: () => ({
    type: clearFlashMessageType
  }),
  clearPackagesByCodes: packagesCodes => (dispatch, getState) => {
    let currentPackages = getState().addApackage.addedPackages;
    const selectedPackages = {};

    if (Object.keys(currentPackages).length > 0) {
      Object.keys(currentPackages).forEach(function(prop, index) {
        if (!packagesCodes.includes(prop)) selectedPackages[prop] = currentPackages[prop];
      });

      dispatch({
        type: clearPackagesByCodesType,
        addedPackages: selectedPackages
      });
    }
  },
  clearAllAddedPackages: () => ({
    type: clearAllAddedPackagesType
  })
};

// Selectors
/**
 * Get all added packages data.
 * @param {Object} state Added packages data.
 */
export const getAddedpackages = state => state.addedPackages;

/**
 * Get flash message from add a package.
 * @param {Object} state Added packages data.
 */
export const getFlashMessage = state => state.flashMessage;

export const API_URLS = {
  REACT_APP_RESERVATION_SERVICE_ENDPOINT: '{DOMAIN_URL}/reservation/api/{VER}/Reservation',
  REACT_APP_BIRTHDAYS_SERVICE_ENDPOINT: '{DOMAIN_URL}/reservation/api/{VER}/Birthday',
  REACT_APP_AVAILABILITY_SERVICE_ENDPOINT: '{DOMAIN_URL}/availability/api/{VER}/Availability',
  REACT_APP_MOBILE_SERVICE_ENDPOINT: '{DOMAIN_URL}/mobile/api/{VER}/mobile',
  REACT_APP_PAYMENT_SERVICE_ENDPOINT: '{DOMAIN_URL}/payment/api/{VER}/payment',
  REACT_APP_RECENT_SEARCHES_SERVICE_ENDPOINT: '{DOMAIN_URL}/recentsearches/api/{VER}/ows',
  REACT_APP_PRICING_SERVICE_ENDPOINT: '{DOMAIN_URL}/pricing/api/{VER}/PricingApi',
  REACT_APP_USER_SERVICE_ENDPOINT: '{DOMAIN_URL}/user/api/{VER}',
  REACT_APP_SIP_RATE_SERVICE_ENDPOINT: '{DOMAIN_URL}/availability/api/{VER}/Rate',
  REACT_APP_ITEM_SERVICE_ENDPOINT: '{DOMAIN_URL}/availability/api/{VER}/Item',
  REACT_APP_LODGE_SERVICE_ENDPOINT: '{DOMAIN_URL}/lodge-info/{VER}',
  REACT_APP_CONCIERGE__ENDPOINT: '{DOMAIN_URL}/concierge/{VER}',
  REACT_APP_ROOM_BOOKING_ENDPOINT: '{DOMAIN_URL}/room-booking/{VER}/lodges',
  REACT_APP_TRAILHEAD_BOOKING_SERVICE_ENDPOINT: '{DOMAIN_URL}/booking/',
  REACT_APP_USERACCOUNT_ENDPOINT_TIOGA: '{DOMAIN_URL}/user-account/{VER}/user'
};

const DYNAMIC_API_URL = 'https://<<apiDomain>>.api.greatwolf.com';
const apiDomainURL = sessionStorage.getItem('apiDomain');
const regex = /^[a-zA-Z0-9]+$/;

const setDynamicUrl = () => {
  const dynamicURL = DYNAMIC_API_URL.replace('<<apiDomain>>', apiDomainURL);
  window.document.body.style.border = '50px solid #0000FF';
  return dynamicURL;
};
export const getApiUrl = (url, version, tioga) => {
  const envUrl =
    apiDomainURL && regex.test(apiDomainURL)
      ? setDynamicUrl()
      : tioga
      ? process.env.REACT_APP_GWR_API_DOMAIN_TIOGA
      : process.env.REACT_APP_GWR_API_DOMAIN;
  const formatedUrl = url.replace('{DOMAIN_URL}', envUrl).replace('{VER}', version);
  const apiUrl = formatedUrl;
  return apiUrl;
};

export const getGraphQLUrl = () => {
  return process.env.REACT_APP_GRAPHQL_GREATWOLF_ENDPOINT;
};

export const getTrailheadUrl = () => {
  return process.env.REACT_APP_GWR_API_DOMAIN_TRAILHEAD;
};
export const getGeolocationUrl = () => {
  return process.env.REACT_APP_GEOLOCATION_GREATWOLF_ENDPOINT;
};

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

/**
 * Exposes mock data that should come from AEM to the global object
 * Note this needs to be at the top level so is accessible by all other
 * imported modules.
 */
import './data/birthdayPartyTimesAndLocationsJson';
import './data/bookingEngineAlert';
import './data/location';
import './data/mysteryDealPromoList';
import './data/promoCodeListJson';
// end of mock data

import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import 'react-block-ui/style.css';
import 'react-dates/initialize';
// *** Leave following commented lines in place till 30-JUN-2024 ***
// import 'react-dates/lib/css/_datepicker.css';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import ScrollToTop from './ScrollToTop';
import './components/_moment_/gw_datepicker.css';
import theme from './components/ui-kit/themes/theme';
import './index.css';
import './infrastructure/interceptors/axios_interceptors';
import * as serviceWorker from './serviceWorker';
import { history, store } from './store/configureStore';

//App is the main component and needs to be imported last
//TEMPORARY FIX, the main issue is a circular dependency between the store and the App component
import App from './App';

// const persistedState = loadState();
const rootElement = document.getElementById('root');
const flowName = rootElement?.getAttribute('data-flow');

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <ConnectedRouter history={history}>
        <ScrollToTop>
          <App flowName={flowName} />
        </ScrollToTop>
      </ConnectedRouter>
    </ThemeProvider>
  </Provider>,
  rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

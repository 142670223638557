import PropTypes from 'prop-types';
import React from 'react';
import DrawerModal from './DrawerModal';
import StyledModal from './styles';

export const MODAL_VARIATIONS = {
  DEFAULT: 'DEFAULT',
  DRAWER: 'DRAWER'
};

const COMPONENTS = {
  [MODAL_VARIATIONS.DEFAULT]: StyledModal,
  [MODAL_VARIATIONS.DRAWER]: DrawerModal
};

const Modal = ({ children, variation, ...props }) => {
  const ModalComponent = COMPONENTS[variation];

  return <ModalComponent {...props}>{children}</ModalComponent>;
};

Modal.VARIATIONS = MODAL_VARIATIONS;

Modal.defaultProps = {
  children: null,
  isVisible: false,
  onCloseComplete: null,
  variation: MODAL_VARIATIONS.DEFAULT,

  /* Drawer Variation Props */
  backButton: false,
  onBackButtonClick: null,
  title: '',
  isBlocking: false,
  width: 480
};

Modal.propTypes = {
  children: PropTypes.node,
  isVisible: PropTypes.bool,
  onCloseModal: PropTypes.func,
  variation: PropTypes.oneOf(Object.values(MODAL_VARIATIONS)),

  /* Drawer Variation Props */
  backButton: PropTypes.bool,
  onBackButtonClick: PropTypes.func,
  title: PropTypes.string,
  isBlocking: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  innerPadding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  titleColor: PropTypes.string
};

export default Modal;

import { initializeState } from '../../utilities/storageUtils';
const incrementAdultsCountType = 'INCREMENT_ADULTS_COUNT';
const decrementAdultsCountType = 'DECREMENT_ADULTS_COUNT';
const setAdultsCountType = 'SET_ADULTS_COUNT';

const incrementKidsCountType = 'INCREMENT_KIDS_COUNT';
const decrementKidsCountType = 'DECREMENT_KIDS_COUNT';
const setKidsCountType = 'SET_KIDS_COUNT';

const setKidsAgesType = 'SET_KIDS_AGES';

const clearAllGuestsDataType = 'CLEAR_ALL_GUESTS_DATA';

// SET AN INITIAL STATE FOR REDUX
const initialState = { adultsCount: 0, kidsCount: 0, kidsAges: [] };

export const actionCreators = {
  // Increment & Decrement Adults Count
  incrementAdultsCount: () => ({ type: incrementAdultsCountType }),
  decrementAdultsCount: () => ({ type: decrementAdultsCountType }),
  // Set Adult Count
  setAdultsCount: count => ({ type: setAdultsCountType, count: count }),

  // Increment & Decrement Kids Count
  incrementKidsCount: () => ({ type: incrementKidsCountType }),
  decrementKidsCount: () => ({ type: decrementKidsCountType }),

  // Set Kids Count
  setKidsCount: count => ({ type: setKidsCountType, count: count }),
  setKidsAges: agesArr => ({ type: setKidsAgesType, kidsAges: agesArr }),

  // Clear all guests data
  clearAllGuestsData: () => ({ type: clearAllGuestsDataType })
};

// This reducer combines reducers for providing new values
// to the Redux store based on actions defined above
//
// If the TYPE matches the action, the new state will replace the old
// this occurs on a per action basis and you should see the Redux store
// (state tree) reflect the change

export const reducer = (state, action) => {
  state = initializeState(state, initialState);
  // ADULTS COUNT REDUCERS
  if (action.type === incrementAdultsCountType) {
    return { ...state, adultsCount: parseInt(state.adultsCount) + 1 };
  }

  if (action.type === decrementAdultsCountType) {
    return { ...state, adultsCount: parseInt(state.adultsCount) - 1 };
  }

  if (action.type === setAdultsCountType) {
    return {
      ...state,
      adultsCount: action.count
    };
  }

  // KIDS COUNT REDUCERS
  if (action.type === incrementKidsCountType) {
    return { ...state, kidsCount: parseInt(state.kidsCount) + 1 };
  }

  if (action.type === decrementKidsCountType) {
    return { ...state, kidsCount: parseInt(state.kidsCount) - 1 };
  }
  if (action.type === setKidsCountType) {
    return {
      ...state,
      kidsCount: action.count
    };
  }
  if (action.type === setKidsAgesType) {
    return {
      ...state,
      kidsAges: action.kidsAges
    };
  }

  // Clear all guests data
  if (action.type === clearAllGuestsDataType) {
    return initialState;
  }

  return state;
};

// Selectors
/**
 * Get the selected adults counts.
 * @param {Object} state
 * @returns {number} Number of adults.
 */
export const getAdultsCount = state => state.adultsCount;

/**
 * Get the selected kids counts.
 * @param {Object} state
 * @returns {number} Number of adults.
 */
export const getKidsCount = state => state.kidsCount;

/**
 * Get the kids ages.
 * @param {Object} state
 * @returns {Array} Array of number.
 */
export const getKidsAges = state => state.kidsAges;

/**
 * Get the number of guests (adults + kids).
 * @param {Object} state
 * @returns {number} Number of guests.
 */
export const getNumberOfGuests = state => state.adultsCount + state.kidsCount;

import { API_URLS, getApiUrl } from '../Utils/apiURLConfiguration';
import GWRApiClient from './GWRApiClient';

/**
 * Concrete implementation for the Reset Pasword endpoints
 *
 * @class ResetPasswordApiclient
 * @extends {GWRApiClient}
 */
export default class ResetPasswordApiClient extends GWRApiClient {
  /**
   * @constructor
   * Calls parent constructor.
   * If there isn't any url provided, then we use the default from the environment variable
   * fills the url and token information
   * @param {string} url
   *  The url where the service endpoints are
   * @param {string} token
   *  The token of the logged user
   */
  constructor(version = '', token = '') {
    let apiVersion = version !== '' ? version : `v2`;

    const urlAux = getApiUrl(API_URLS.REACT_APP_USER_SERVICE_ENDPOINT, apiVersion);

    super(urlAux, token);
  }

  async forgotPassword(email, callbackUrl) {
    const urlRequest = `${this.url}/auth/forgot-password`;
    let requestObject = {
      Email: email,
      Url: callbackUrl
    };
    let request = await super.postApiCall(urlRequest, requestObject);
    return request;
  }
  async changePassword(email, newPassword, resetToken) {
    const urlRequest = `${this.url}/auth/change-password`;
    let requestObject = {
      Email: email,
      NewPassword: newPassword,
      ResetToken: resetToken
    };
    let request = super.postApiCall(urlRequest, requestObject);
    return request;
  }

  async changePasswordByOldPassword(email, oldPassword, newPassword) {
    const urlRequest = `${this.url}/auth/change-password-by-old-password`;

    let requestObject = {
      Email: email,
      oldPassword: oldPassword,
      NewPassword: newPassword
    };

    let request = super.postApiCall(urlRequest, requestObject);

    return request;
  }
}

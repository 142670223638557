import { API_URLS, getApiUrl } from '../Utils/apiURLConfiguration';
import GWRApiClient from './GWRApiClient';

/**
 * Concrete implementation for the User endpoints
 *
 * @class UserApiClient
 * @extends {GWRApiClient}
 */
export default class UserApiClient extends GWRApiClient {
  constructor(version = '', token = '') {
    let apiVersion = version !== '' ? version : `v2`;

    const urlAux = getApiUrl(API_URLS.REACT_APP_USER_SERVICE_ENDPOINT, apiVersion);

    super(urlAux, token);
  }
  /**
   * @function
   * Get user informstion based on token
   */
  async getUser() {
    const urlRequest = `${this.url}/User`;
    let request = await super.getApiCallAuth(urlRequest);
    return request;
  }

  /**
   * @function
   * Saves user information
   * @param {object} user
   */
  async updateUser(user) {
    const urlRequest = `${this.url}/User`;
    let request = await super.patchApiCallAuth(urlRequest, user);
    return request;
  }

  /**
   * @funtion
   *  Saves Lead generator form
   * @param  {object} user
   */
  async createLeadGedUser(user) {
    const urlRequest = `${this.url}/User/leadgen`;
    let request = await super.postApiCall(urlRequest, user);
    return request;
  }

  /**
   * @funtion
   *  Whether email already taken
   * @param  {string}  emailaddress
   */
  async isEmailAlreadyTaken(email) {
    const urlRequest = `${this.url}/User/is-email-already-taken/?Email=${encodeURIComponent(email)}`;
    let request = await super.getApiCall(urlRequest);
    return request;
  }

  /**
   * @funtion
   *  Get address lookup from address string match
   * @param  {string}  addressMatch
   */
  async getAddressLookup(addressMatch) {
    const urlRequest = `${this.url}/User/address-lookup?addressMatch=${encodeURIComponent(addressMatch)}`;
    return await super.getApiCall(urlRequest);
  }

  /**
   * @function
   * Update SMS suscription to current user, phone number and sms flag
   * @param {object} suscriptionData
   */
  async updateSms(suscriptionData) {
    const urlRequest = `${this.url}/User/preferences/sms`;
    return await super.putApiCallAuth(urlRequest, suscriptionData);
  }

  /**
   * @function
   * Interactions. Update SMS suscription to current user, phone number and sms flag
   * @param {string} profileId
   * @param {object} subscriptionData
   */
  async updateSmsByProfileId(profileId, subscriptionData) {
    const urlRequest = `${this.url}/Interactions/preferences/${profileId}/sms`;
    return await super.putApiCallAuth(urlRequest, subscriptionData);
  }

  /**
   * @function
   * Update Email suscription to current user
   * @param {Boolean} canContactByEmail
   */
  async updateEmailPreference(canContactByEmail) {
    const urlRequest = `${this.url}/User/preferences/email`;
    return await super.putApiCallAuth(urlRequest, {
      CanContactByEmail: canContactByEmail
    });
  }

  /**
   * @function
   * Update Properties/Locations suscription to current user
   * @param {object} suscriptionData
   */
  async updateLocationPreferences(suscriptionData) {
    const urlRequest = `${this.url}/User/preferences/destination`;
    return await super.putApiCallAuth(urlRequest, suscriptionData);
  }

  /**
   * @function
   * Update LoyaltyOptIn flag to current user
   * @param {object} request
   */
  async updateLoyaltyOptIn(request) {
    const urlRequest = `${this.url}/User/${request.profileId}/loyalty/opt-in`;
    return await super.putApiCallAuth(urlRequest, request.queryParams);
  }

  /**
   * @function
   * Update User Funnel Depth for the booking funnel
   * @param {object} request
   */
  async updateFunnelDepth(request) {
    const urlRequest = `${this.url}/User/funnel-depth`;
    return await super.postApiCallAuth(urlRequest, request);
  }

  /**
   *
   * @function
   * Get user by LeadGen
   * @param {object} request
   */
  async LeadGenSignUpData(request) {
    const urlRequest = `${this.url}/User/summary/profile?ProfileKey=${request.profileKey}&ProfileId=${request.profileId}`;
    return await super.getApiCall(urlRequest, request.queryParams);
  }

  async sendReferralEmail(request) {
    const urlRequest = `${this.url}/User/referral/send`;
    let response = await super.postApiCallAuth(urlRequest, request.queryParams);
    return response;
  }

  async sendReferralEmailLoggedOutVersion(request) {
    const urlRequest = `${this.url}/User/referral/send/profile`;
    let response = await super.postApiCall(urlRequest, request.queryParams);
    return response;
  }
}

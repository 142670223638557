import { initializeState } from '../../utilities/storageUtils';

export const PRE_CHECK_IN_STEP = {
  SUMMARY: 'SUMMARY',
  DINING: 'DINING',
  ATTRACTIONS: 'ATTRACTIONS',
  ADVENTURE_PASS: 'ADVENTURE_PASS',
  TRANSITION: 'TRANSITION'
};
const updatePreCheckInStep = 'UPDATE_PRE_CHECK_IN_STEP';
const updateAddedPackagesFails = 'UPDATE_ADDED_PACKAGES_FAILED';
const resetAddedPackagesFails = 'RESET_ADDED_PACKAGES_FAILED';
const updateNewPackages = 'UPDATE_NEW_PACKAGES';
const updateVirdeeRedirectionFailed = 'UPDATE_VIRDEE_REDIRECTION_FAILED';

const initialState = {
  preCheckInStep: PRE_CHECK_IN_STEP.SUMMARY,
  addedPackagesFailed: 0,
  addedPackages: [],
  virdeeRedirectionFailed: 0
};

export const actionCreators = {
  changePreCheckInStep: step => dispatch => {
    dispatch({ type: updatePreCheckInStep, step });
  },
  updateAddedPackagesFails: () => dispatch => {
    dispatch({ type: updateAddedPackagesFails });
  },
  resetAddedPackagesFails: () => dispatch => {
    dispatch({ type: resetAddedPackagesFails });
  },
  updateNewPackages: packages => dispatch => {
    dispatch({ type: updateNewPackages, packages });
  },
  updateVirdeeRedirectionFailed: () => dispatch => {
    dispatch({ type: updateVirdeeRedirectionFailed });
  }
};

export const reducer = (state, action) => {
  state = initializeState(state, initialState);

  switch (action.type) {
    // Get Resort Locations SUCCESS
    case updatePreCheckInStep:
      return {
        ...state,
        preCheckInStep: action.step
      };
    case updateAddedPackagesFails:
      return {
        ...state,
        addedPackagesFailed: ++state.addedPackagesFailed
      };
    case resetAddedPackagesFails:
      return {
        ...state,
        addedPackagesFailed: 0
      };
    case updateNewPackages:
      return {
        ...state,
        addedPackages: action.packages
      };
    case updateVirdeeRedirectionFailed:
      return {
        ...state,
        virdeeRedirectionFailed: ++state.virdeeRedirectionFailed
      };
    default:
      return state;
  }
};

export default reducer;

export const getPreCheckInStep = state => {
  return state.preCheckInStep;
};

export const getAddedPackagesFailed = state => {
  return state.addedPackagesFailed;
};

export const getPreCheckInAddedPackages = state => {
  return state.addedPackages;
};

export const getVirdeeRedirectionFailed = state => {
  return state.virdeeRedirectionFailed;
};

import { push } from 'connected-react-router';
import AvailabilityApiClient from '../../api/clients/AvailabilityApiClient';
import { DAY_PASSES_TYPE } from '../../utilities/constants';
import { formatDate } from '../../utilities/dates';
import { initializeState } from '../../utilities/storageUtils';
import { getResortLocation } from './CurrentLodge';

const initialState = {
  numberOfGuests: 0,
  arrivalDate: null,
  selectedTitle: '',
  selectedRate: 0,
  selectedTaxesAndFees: 0,
  selectedRateCode: '',
  selectedItemCode: '',
  selectedDescription: '',
  promoCode: '',
  qualifyingId: '',
  isLoading: false, //remove on next realease
  isDayPassAvailable: false, //remove on next realease
  item: {
    rate: '',
    itemCode: '',
    rateCode: '',
    baseRate: 0
  },
  availabilityData: [],
  promoCodeData: null,
  isDaypassFetching: false,
  daypassCalendarMonth: null,
  parkingFee: 0,
  resortFee: 0,
  sustainabilityFee: 0,
  taxesAndFees: 0
};

const setDayPassType = 'SET_DAY_PASS';
const isDayPassAvailableType = 'IS_DAYPASS_AVAILABLE';
const updateIsLoadingType = 'UPDATE_LOADING';
const updateDayPassMetadataType = 'UPDATE_DAYPASS_METADATA';
const setAvailableDates = 'SET_AVAILABLE_DATES';
const updateDayPassFetch = 'UPDATE_DAYPASS_FETCHING';
// const isAlertOpenType = "IS_ALERT_OPEN";

const hasDayPassError = dayPassObject => {
  return dayPassObject.filter(dayPassType => dayPassType.itemCode === '').length;
};

export const actionCreators = {
  //remove on next realease
  validateDayPass: values => (dispatch, getState) => {
    // 1. Start loading
    const { arrivalDate, numberOfGuests, promoCode } = values;
    const formatedArrivalDate = arrivalDate.format('M/DD/YYYY');
    const payload = {
      StartDate: formatedArrivalDate,
      EndDate: formatedArrivalDate,
      NumberOfGuests: numberOfGuests,
      Location: getResortLocation(getState().currentLodge),
      ItemGroup: 'WPF',
      PromoCode: promoCode && promoCode.toUpperCase()
    };

    dispatch({ type: updateIsLoadingType, isLoading: true });

    // 2. Get availability daypass
    (async () => {
      try {
        const apiClient = new AvailabilityApiClient();
        const availabilityDayPass = await apiClient.getAvailabilityDayPasses(payload);
        const { data } = availabilityDayPass;
        // 3. If I get data save it in the store to be used

        if (data.length !== 0) {
          if (hasDayPassError(data) > 1) {
            // 4. If I dont get data the daypass is not available
            throw new Error();
          } else {
            const daypassMetadata = [...data];

            dispatch({
              type: isDayPassAvailableType,
              isDayPassAvailable: true
            });
            dispatch({
              type: updateDayPassMetadataType,
              item: daypassMetadata
            });
          }
        } else {
          dispatch({ type: isDayPassAvailableType, isDayPassAvailable: false });
        }
      } catch (e) {
        // 4. If I dont get data the daypass is not available
        dispatch({ type: isDayPassAvailableType, isDayPassAvailable: false });
      } finally {
        // 6. Stop loading data
        dispatch({ type: updateIsLoadingType, isLoading: false });
      }
    })();
  },
  //remove on next realease
  submitDayPass: values => (dispatch, getState) => {
    // 1. save number of guests
    dispatch({ type: setDayPassType, ...values });
    // 3. Navigate to daypass payment form
    dispatch(push('/daypass/payment'));
  },

  checkAvailableDates: values => async (dispatch, getState) => {
    // Get values
    const { month: monthStr, year, promoCode } = values;

    dispatch({
      type: updateDayPassFetch,
      fetchData: {
        isDaypassFetching: true,
        monthToFetch: monthStr
      }
    });

    const month = +monthStr;

    const todayDate = new Date();
    const startingDate = new Date(+year, month - 1, 1);
    const endingDate = new Date(+year, month, 0);

    //Validate if date is before than current date
    if (todayDate.getFullYear() > year) {
      dispatch({
        type: setAvailableDates,
        availabilityData: { promotion: '', dayPasses: [] },
        date: values
      });
      return dispatch({
        type: updateDayPassFetch,
        fetchData: {
          isDaypassFetching: false
        }
      });
    } else if (todayDate.getMonth() + 1 > month && todayDate.getFullYear() === year) {
      dispatch({
        type: setAvailableDates,
        availabilityData: { promotion: '', dayPasses: [] },
        date: values
      });
      return dispatch({
        type: updateDayPassFetch,
        fetchData: {
          isDaypassFetching: false
        }
      });
    }

    const isCurrentMonth = month === todayDate.getMonth() + 1 && year === todayDate.getFullYear();

    const StartDate = isCurrentMonth ? formatDate(todayDate) : formatDate(startingDate);
    const EndDate = formatDate(endingDate);

    const payload = {
      StartDate,
      EndDate,
      NumberOfGuests: 1,
      Location: getResortLocation(getState().currentLodge),
      ItemGroup: 'WPF',
      PromoCode: promoCode
    };

    const apiClient = new AvailabilityApiClient('v2.1');
    let result = { data: {} };

    try {
      result = await apiClient.getAvailabilityDayPasses(payload);
    } catch (error) {}

    dispatch({
      type: setAvailableDates,
      availabilityData: result.data,
      date: values
    });

    dispatch({
      type: updateDayPassFetch,
      fetchData: {
        isDaypassFetching: false
      }
    });
  },

  submitDayPassAndRedirect: values => dispatch => {
    const { arrivalDate, numberOfGuests, promotion, qualifyingId, passData, promoCodeData } = values;
    const title = passData.itemCode === DAY_PASSES_TYPE.fullDay ? 'Full Day Pass' : 'Half Day Pass';
    const getTitleAndRate = () => {
      if (promotion) {
        const promo = promotion.toUpperCase();
        return {
          promoCode: promo,
          selectedTitle: title,
          selectedRateCode: passData.rateCode
        };
      }

      return {
        promoCode: '',
        selectedTitle: title,
        selectedRateCode: passData.rateCode
      };
    };
    const { promoCode, selectedTitle, selectedRateCode } = getTitleAndRate();
    const payload = {
      arrivalDate,
      numberOfGuests,
      selectedTitle,
      selectedRate: passData.roomRateTotal,
      selectedTaxesAndFees: passData.taxesAndFees,
      selectedItemCode: passData.itemCode,
      selectedRateCode,
      promoCode,
      selectedDescription: passData.description,
      parkingFee: passData.totalParkingFee,
      resortFee: passData.totalResortFee,
      sustainabilityFee: passData.totalSustainabilityFee,
      totalTaxesAndFees: passData.totalTaxesAndFees,
      item: {
        date: arrivalDate,
        roomRateTotal: passData.roomRateTotal,
        itemCode: passData.itemCode,
        taxesAndFees: passData.taxesAndFees
      },
      ...(promoCodeData && promoCodeData),
      ...(qualifyingId && { qualifyingId })
    };
    // 1. save number of guests
    dispatch({ type: setDayPassType, ...payload });
    // 3. Navigate to daypass payment form
    dispatch(push('/daypass/payment'));
  }
};

const reducer = (state, action) => {
  state = initializeState(state, initialState);

  switch (action.type) {
    case updateIsLoadingType:
      return {
        ...state,
        item: {
          ...state.item
        },
        isLoading: action.isLoading
      };

    case isDayPassAvailableType:
      return {
        ...state,
        item: {
          ...state.item
        },
        isDayPassAvailable: action.isDayPassAvailable
      };

    case updateDayPassMetadataType:
      return {
        ...state,
        item: {
          ...action.item
        }
      };

    case setDayPassType:
      return {
        ...state,
        arrivalDate: action.arrivalDate,
        numberOfGuests: action.numberOfGuests,
        selectedTitle: action.selectedTitle,
        selectedRate: action.selectedRate,
        selectedTaxesAndFees: action.selectedTaxesAndFees,
        selectedItemCode: action.selectedItemCode,
        selectedRateCode: action.selectedRateCode,
        promoCode: action.promoCode,
        qualifyingId: action.qualifyingId,
        selectedDescription: action.selectedDescription,
        parkingFee: action.parkingFee,
        resortFee: action.resortFee,
        sustainabilityFee: action.sustainabilityFee,
        taxesAndFees: action.totalTaxesAndFees,
        item: [action.item]
      };

    case setAvailableDates:
      // We get the month of the request if the request has days
      const date =
        action.availabilityData.dayPasses?.length > 0 ? new Date(action.availabilityData.dayPasses[0].date) : null;
      const monthFromAPI = date ? date.getMonth() + 1 : null;
      const isLastInfo = monthFromAPI === action.date.month && date.getFullYear() === action.date.year;

      return {
        ...state,
        promoCodeData: action.availabilityData.promotion ? action.availabilityData.promotion : '',
        ...(isLastInfo && {
          availabilityData: action.availabilityData.dayPasses ? action.availabilityData.dayPasses : []
        })
      };
    case updateDayPassFetch:
      return {
        ...state,
        isDaypassFetching: action.fetchData.isDaypassFetching,
        daypassCalendarMonth: action.fetchData.isDaypassFetching ? action.fetchData.monthToFetch : null,
        ...(action.fetchData.isDaypassFetching && { availabilityData: [] })
      };

    default:
  }

  return state;
};

export default reducer;

// Selectors
export const getSelectedRate = state => {
  return state.selectedRate;
};

export const getSelectedTitle = state => {
  return state.selectedTitle;
};

export const getPromoCode = state => {
  return state.promoCode;
};

export const getNumberOfGuests = state => {
  return state.numberOfGuests;
};

export const getArrivalDate = state => {
  return state.arrivalDate;
};

export const getDayPassesGrossTotal = state => {
  const { numberOfGuests, selectedRate } = state;
  return selectedRate * numberOfGuests;
};

export const getDayPassesTaxesTotal = state => {
  const { numberOfGuests, selectedTaxesAndFees } = state;
  return selectedTaxesAndFees * numberOfGuests;
};

export const getDayPassesNetTotal = state => {
  const dayPassesGrossTotal = getDayPassesGrossTotal(state);
  const dayPassesTaxesTotal = getTaxesAndFees(state);

  return dayPassesGrossTotal + dayPassesTaxesTotal;
};

export const getSelectedDescription = state => {
  return state.selectedDescription;
};

export const getDayPassParkingFee = state => {
  return state.parkingFee;
};

export const getDayPassResortFee = state => {
  return state.resortFee;
};

export const getDayPassSustainabilityFee = state => {
  return state.sustainabilityFee;
};

export const getTaxesAndFees = state => {
  return state.taxesAndFees;
};

import PropTypes from 'prop-types';
import React from 'react';

import { StyledButton } from './styles';

const propTypes = {
  /**
   * Use the variant prop to select different styles for the button.
   */
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'ghost',
    'deals',
    'book',
    'affirmConnect',
    'primaryArrow',
    'secondaryArrow',
    'ghostArrow',
    'dealsArrow',
    'bookArrow',
    'short',
    'shortSecondary',
    'bookWidget',
    'bookWidgetArrow',
    'newPrimary',
    'newPrimarySecondary'
  ]),
  /**
   * Instead of use full width prop to make the button take 100% of the available space please use fluid.
   */
  fluid: PropTypes.bool,

  /**
   * Use the floating prop to indicate the button will be floated to the right, this is intended ot be used with the `Sticky` component.
   */
  floating: PropTypes.bool,
  /**
   * An string indicating if the component should render an element that is different from the normal button element.
   */
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.object]),
  /**
   * An string indicating the size variant for the button.
   */
  size: PropTypes.string
};

const defaultProps = {
  variant: 'primary',
  fluid: false,
  size: 'medium'
};

const Button = props => <StyledButton {...props}></StyledButton>;

Button.defaultProps = defaultProps;
Button.propTypes = propTypes;

export default Button;

export const AffirmButton = props => {
  const { variant, ...restProps } = props;
  return <Button variant="affirmConnect" {...restProps} />;
};

import moment from 'moment';
import { DATE_FORMATS } from './constants';

const _gwDateFromat = DATE_FORMATS.default;
const _today = moment();
const _yesterday = _today.subtract(1, 'day');
const _tomorrow = _today.add(1, 'day');
const _localeData = moment.localeData();
const _getMoment = (date, format, clone = false) =>
  moment.isMoment(date) ? (clone ? date.clone() : date) : format ? moment(date, format) : moment(date);
const _createDate = dateStr => _getMoment(dateStr).format(_gwDateFromat);
const _todayStr = _createDate(_today);
const _yesterdayStr = _createDate(_yesterday);
const _tomorrowStr = _createDate(_tomorrow);

/**
 * This wrapper is going to abstract the date library used in the application.
 *  https://medium.com/swlh/best-moment-js-alternatives-5dfa6861a1eb
 * Main principles/approach:
 *  1. We only deal with 'date' portion of the 'Date' object, not the 'time' portion in this application
 *  2. We represent the 'date' as a string using 'DATE_FORMATS.default' date format
 *  3. No part of this application should use 'Date' or 'moment' object to create, store, calculate or format date information
 *  4. Code in this application should use this wrapper class for any date related operation instead of importing 'moment' library and utilizing that, only exception (for now) is this wrapper class
 */
export const GwDatesWrapper = {
  // STANDARD DATE FORMAT FOR THIS WRAPPER
  gwDateFromat: _gwDateFromat,
  // GET DATE FROM STRING
  createDate: dateStr => (dateStr === 'today' ? _todayStr : _createDate(dateStr)),
  // GET CURRENT DATE
  today: () => _todayStr,
  tomorrow: () => _tomorrowStr,
  yesterday: () => _yesterdayStr,
  // GET LOCALE DATA
  getLocaleData: () => _localeData,
  // PARSE DATESTR USING FORMAT AND RETURN STANDARD DATE AS STRING
  parseDate: (dateStr, format) => _createDate(_getMoment(dateStr, format)),
  // MOMENT PARSEZONE
  parseZone: dateStr => moment.parseZone(dateStr),
  // GET CURRENT MONTH
  month: (dateStr, monthName) => _getMoment(dateStr).month(monthName),
  // GET CURRENT YEAH
  year: (dateStr, newYearNumber) => _getMoment(dateStr).year(newYearNumber),
  // FORMAT DATE
  format: (dateStr, format) => _getMoment(dateStr).format(format),
  // DURATION
  duration: number => moment.duration(number),
  // CREATE CC DATE
  createCCDate: (month, year) => this.format(this.parseDate(`${month}/${year}`, 'MM/YYYY'), 'YYYY-MM'),
  // END OF UNITY
  endOf: (dateStr, unit = 'days') => _createDate(_getMoment(dateStr, null, true).endOf(unit)),
  // START OF UNITY
  startOf: (dateStr, unit = 'days') => _createDate(_getMoment(dateStr, null, true).startOf(unit)),
  // DAY
  day: dateStr => _getMoment(dateStr).day(),
  // WEEKDAY
  weekday: dateStr => _getMoment(dateStr).weekday(),
  // ADD TIME TO DATE
  add: (dateStr, amount, unit = 'days') => _createDate(_getMoment(dateStr, null, true).add(amount, unit)),
  // SUBTRACT TIME TO DATE
  subtract: (dateStr, amount, unit = 'days') => _createDate(_getMoment(dateStr, null, true).subtract(amount, unit)),
  // DIFFERENCE BETWEEN DATES
  diff: (dateStr1, dateStr2, unit = 'days') => _getMoment(dateStr1).diff(_getMoment(dateStr2), unit),
  // IS DATE BETWEEN
  isBetween: (dateStr, start, end, unit = 'days', inclusionRules) =>
    _getMoment(dateStr).isBetween(_getMoment(start), _getMoment(end), unit, inclusionRules),
  // IS DATE THE SAME
  isSame: (dateStr1, dateStr2, unit = 'days') => _getMoment(dateStr1).isSame(_getMoment(dateStr2), unit),
  // IS DATE AFTER
  isAfter: (dateStr1, dateStr2, unit = 'days') => _getMoment(dateStr1).isAfter(_getMoment(dateStr2), unit),
  // IS DATE BEFORE
  isBefore: (dateStr1, dateStr2, unit = 'days') => _getMoment(dateStr1).isBefore(_getMoment(dateStr2), unit),
  // IS DATE THE SAME OR AFTER
  isSameOrAfter: (dateStr1, dateStr2, unit = 'days') => _getMoment(dateStr1).isSameOrAfter(_getMoment(dateStr2), unit),
  // IS DATE THE SAME OR BEFORE
  isSameOrBefore: (dateStr1, dateStr2, unit = 'days') =>
    _getMoment(dateStr1).isSameOrBefore(_getMoment(dateStr2), unit),
  // IS DATE TODAY
  isToday: dateStr => _getMoment(dateStr).isSame(_today, 'day'),
  // IS DATE TOMORROW
  isTomorrow: dateStr => _getMoment(dateStr).isSame(_tomorrow, 'day'),
  // IS DATE YESTERDAY
  isYesterday: dateStr => _getMoment(dateStr).isSame(_yesterday, 'day'),
  //SUBTRACT A DATE BY ANOTHER
  subtractDateByAnother: (date1, date2) => {
    return _createDate(_getMoment(date1) - _getMoment(date2));
  },
  // FORMAT RANGE
  formatRange: (firstDateStr, secondDateStr, format, separator = '-') =>
    `${_getMoment(firstDateStr).format(format)} ${separator} ${_getMoment(secondDateStr).format(format)}`,
  // THIS WILL REMOVE THE TIME WHEN THE DATE IS IN THE FORMAT YYYY-MM-DDTHH:MM:SS
  removeTimeFromDate: date => {
    return date.split('T')[0];
  },
  // THIS WILL REMOVE THE (EMPTY) TIME WHEN THE DATE IS IN THE FORMAT 'YYYY-MM-DD 00:00:00'
  removeEmptyTimeFromDate: date => {
    return date.split('00')[0].trim();
  },
  // SET DATE - Generic setter, accepting unit as first argument, and value as second argument
  set: (dateStr, unit = 'days', value) => _createDate(_getMoment(dateStr).set(unit, value)),
  // IS DATE A VALID DATE
  isValid: date => _getMoment(date).isValid(),
  isIntanceOfMoment: dateStr => dateStr instanceof moment,
  // GET MOMENT OBJECT
  getMoment: (dateStr, format) => _getMoment(dateStr, format),
  // FORMAT OF LOCALE
  getLocaleDateFormat: (formatKey = 'L') => _localeData.longDateFormat(formatKey),
  // LOCALE FIRST DAY OF THE WEEK
  firstDayOfWeek: () => _localeData.firstDayOfWeek()
};

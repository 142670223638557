import UserApiClient from '../../api/clients/UserApiClient';
import { getLeadGenEventObject } from '../../infrastructure/middleware/analytics/analyticsObjectBuilders';
import { GTM_EVENT } from '../../utilities/constants';
import { pushEvent } from '../../utilities/dataLayerUtils';
import { EVENT_NAME, trackFbPixelEvent } from '../../utilities/facebookPixel';
import { EVENT_ACTIONS, trackGTagEvent } from '../../utilities/gtag';
import { LocalStorageKeys } from '../../utilities/storageUtils';

const signUpIsProcessingType = 'IS_PROCESSING_TYPE';
const setAlreadyShownType = 'SET_IS_ALREADY_SHOWN';
const resetLeadGenStatus = 'RESET_LEAD_GEN_STATUS';
const initialState = {
  isProcessing: false,
  isSignupSuccess: false,
  isSignupFailure: false,
  isAlreadyShown: false
};

export const actionCreators = {
  signUpLeadGen: user => (dispatch, getDate) => {
    dispatch({
      type: signUpIsProcessingType,
      isProcessing: true,
      isSignupFailure: false,
      isSignupSuccess: false
    });

    const apiClient = new UserApiClient();
    const httpCall = apiClient.createLeadGedUser(user);

    httpCall.then(
      response => {
        dispatch({
          type: signUpIsProcessingType,
          isProcessing: false,
          isSignupSuccess: true,
          isAlreadyShown: true,
          isSignupFailure: false
        });

        const eventData = getLeadGenEventObject(GTM_EVENT.ACTION.leadGenSuccess, GTM_EVENT.LABEL.greatWolf);
        pushEvent(eventData);

        trackFbPixelEvent(EVENT_NAME.subscribe);
        trackGTagEvent(EVENT_ACTIONS.conversion);
        // Set a local storage flag that user is successfully signed up and can access all gated offers
        // Don't place this in the state storage because that gets deleted at an interval
        localStorage.setItem(LocalStorageKeys.Ungated, true);
        // Trigger a storage event to let the AEM know that the UNGATED value changed
        window.dispatchEvent(new Event('storage'));
      },
      err => {
        dispatch({
          type: signUpIsProcessingType,
          isProcessing: false,
          isSignupFailure: true,
          isSignupSuccess: false,
          isAlreadyShown: false
        });
      }
    );
  },

  setAlreadyShown: params => dispatch => {
    dispatch({
      type: setAlreadyShownType,
      isAlreadyShown: params
    });
  },

  isEmailAlreadyTaken: email => dispatch => {
    const apiClient = new UserApiClient();
    return apiClient.isEmailAlreadyTaken(email);
  },

  resetLeadGenStatus: () => dispatch => {
    dispatch({
      type: resetLeadGenStatus,
      isProcessing: false,
      isSignupSuccess: false,
      isSignupFailure: false
    });
  }
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case signUpIsProcessingType:
      return {
        ...state,
        isProcessing: action.isProcessing,
        isSignupSuccess: action.isSignupSuccess,
        isSignupFailure: action.isSignupFailure,
        isAlreadyShown: action.isAlreadyShown
      };
    case setAlreadyShownType:
      return {
        ...state,
        isAlreadyShown: action.isAlreadyShown
      };
    case resetLeadGenStatus:
      return {
        ...state,
        isProcessing: action.isProcessing,
        isSignupSuccess: action.isSignupSuccess,
        isSignupFailure: action.isSignupFailure
      };
    default:
      return state;
  }
};

//DAYPASS TOTALS
export const SUBTOTAL = 'Sub Total';
export const TAXES = 'Taxes';
export const TAXES_AND_FEES = 'Taxes and Fees';
export const PARKING_FEE = 'Parking Fee';
export const SUSTAINABILITY_FEE = 'Sustainability Fee';

//DAYPASS HEADER
export const NUMBER_OF_PASSES = '# of Day Passes';
export const VALID_ON = 'Valid On';

//DAYPASS FOOTER
export const TOTAL = 'Total';

//DAYPASS DETAILS
export const PRICE_PER_GUEST = 'Price per guest';
export const PROMOCODE_APPLIED = 'Code: <PROMOCODE> Applied';

//ADITIONAL CHARGES
export const ADDITIONAL_CHARGES = 'Additional Charges';

//PACKAGE LIST
export const REMOVE = 'Remove';

//DAYPASS TOTALS AND ADDITIONAL CHARGES SHARED COPIES
export const TOOLTIP_COPY =
  'The Day Pass Amenity Fee at Great Wolf Lodge encompasses a range of services and inclusions crafted to elevate family experiences and add extra conveniences. This fee covers amenities such as water park life jackets and towels, resort-wide access to wireless internet, and delightful complimentary Wolf Ears for children aged 12 and under.';
export const AMENITY_FEE = 'Amenity Fee';

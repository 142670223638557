import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router';
import routes from '../../../../../routes';
import { Button } from '../../../../ui-kit';
import Scrollable from '../../../../ui-kit/Scrollable';
import theme from '../../../../ui-kit/themes/theme';
import { handleTotal } from '../../../../utilities/utils';
import * as COPY from '../utilities/copy';
import CartHeader from './Content/CartHeader';
import { CartModalButtonsContainer, Overlay, OverlayContainer, ScrollContainer } from './styles';

const propTypes = {
  toggleOpen: PropTypes.func,
  cartHasPackages: PropTypes.bool,
  overlayVisible: PropTypes.bool,
  variation: PropTypes.string
};

const defaultProps = {
  toggleOpen: () => {},
  cartHasPackages: false,
  overlayVisible: false,
  variation: null
};

const CartOverlay = ({
  toggleOpen,
  cartHasPackages,
  overlayVisible,
  isCartVariation,
  children,
  onPlanPage,
  isCartExpired,
  ...otherProps
}) => {
  // Dynamic calculation os scroll height
  const [contentScrollHeight, setContentScrollHeight] = useState(0);
  const scrollingContainer = useRef(null);
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.md})`
  });
  const scrollContainerPadding = useMemo(() => (isMobile ? theme.space[3] : theme.space[7]), [isMobile]);
  const location = useLocation();

  useEffect(() => {
    if (!scrollingContainer.current) return;
    setContentScrollHeight(scrollingContainer.current.clientHeight - scrollContainerPadding);
  }, [isMobile, scrollContainerPadding, overlayVisible]);

  const {
    userOffers,
    activeOffers,
    showLoyalty,
    loyaltyOptIn,
    pointsBalance,
    suiteTotal,
    currentSelectedPackages,
    setExcedent,
    isUserAuthenticated,
    summaryTotal,
    selectedSuite,
    hasSelectedPackages,
    pushToPayment,
    pushToLastVisitedStep,
    pushToDiningPackagesPage,
    pushToPackagesPage,
    resortLocationUrlParam
  } = otherProps;

  const subTotal = isUserAuthenticated
    ? handleTotal(
        userOffers,
        activeOffers,
        showLoyalty,
        loyaltyOptIn,
        pointsBalance,
        suiteTotal,
        currentSelectedPackages,
        setExcedent
      ).toFixed(2)
    : summaryTotal?.toFixed(2);

  const hasDiningPackages = currentSelectedPackages?.some(pkg => pkg.packageType?.includes('dining'));

  const continueBooking = () => {
    if (onPlanPage) {
      toggleOpen();
      if (
        hasSelectedPackages &&
        hasDiningPackages &&
        location.pathname !== '/plan/packages-activities' &&
        location.pathname !== '/plan/packages-dining'
      ) {
        return pushToPayment();
      }
      if (location.pathname === '/plan') {
        return pushToPackagesPage();
      }
      if (location.pathname === '/plan/packages-activities') {
        return pushToDiningPackagesPage();
      }
      if (location.pathname === '/plan/packages-dining') {
        return pushToPayment();
      }
    } else {
      toggleOpen();
      if (hasSelectedPackages && hasDiningPackages) {
        window.location.href = resortLocationUrlParam.replace(/\/$/, '') + routes.plan.payment;
        return;
      }
      pushToLastVisitedStep();
    }
  };

  return (
    <>
      {isCartVariation ? (
        <Overlay onClick={toggleOpen} isCartVariation={isCartVariation} overlayVisible={overlayVisible}></Overlay>
      ) : null}

      <OverlayContainer
        isDevelopment={process.env.NODE_ENV === 'development'}
        isCartVariation={isCartVariation}
        isCartEmpty={!cartHasPackages}
        overlayVisible={overlayVisible}>
        {isCartVariation ? (
          <CartHeader subTotal={!selectedSuite || Object.keys(selectedSuite).length === 0 ? 0 : subTotal} />
        ) : null}

        <ScrollContainer isCartVariation={isCartVariation} ref={scrollingContainer} onPlanPage={onPlanPage}>
          <Scrollable
            maxHeight={isCartVariation ? contentScrollHeight : null}
            scrollColor={theme.colors.successGreen}
            showTrack
            trackColor={theme.colors.snowMountainGrey[50]}
            hideScrollWhileScrolling={!isCartVariation}>
            {children}
          </Scrollable>
        </ScrollContainer>
        {(isCartVariation && !onPlanPage && cartHasPackages && !isCartExpired) ||
        (isCartVariation && onPlanPage && isMobile && cartHasPackages) ? (
          <CartModalButtonsContainer py={7} px={[4, 4, 4, 7, 7]}>
            <Button onClick={continueBooking} fluid={isMobile}>
              {hasSelectedPackages && location.pathname === '/plan/packages-dining'
                ? COPY.GO_TO_CHECKOUT
                : COPY.CONTINUE_BOOKING}
            </Button>
          </CartModalButtonsContainer>
        ) : null}
      </OverlayContainer>
    </>
  );
};

CartOverlay.propTypes = propTypes;
CartOverlay.defaultProps = defaultProps;

export default CartOverlay;

import PropTypes from 'prop-types';
import React from 'react';
import { GwDatesWrapper } from '../../../../utilities/gwDatesWrapper';
import NotificationBanner from '../../../NotificationBanner';
import Flex from '../../../ui-kit/Flex';
import Text from '../../../ui-kit/Text';
import { DayPassContainer } from '../../assets/styles';
import * as COPY from './utilities/copy';

const DayPassHeader = ({ notificationType, notificationMessage, showNotification, validOn, amountOfDayPasses }) => {
  const parsedValidOn = GwDatesWrapper.format(validOn, 'MM/DD');

  return (
    <DayPassContainer mx={-3}>
      {showNotification ? (
        <NotificationBanner mt={0} mb={2} type={notificationType} message={notificationMessage} />
      ) : null}
      <Flex
        width={1 / 2}
        alignItems="center"
        height={showNotification ? 146 : 98}
        justifyContent={'center'}
        flexDirection="column"
        py={2}>
        <Text fontWeight="500" mb={2} fontSize={0} pt={showNotification && 60}>
          {COPY.NUMBER_OF_PASSES}
        </Text>
        <Text fontWeight="bold" color="deepLakeBlue.100" fontSize={20}>
          {amountOfDayPasses}
        </Text>
      </Flex>
      <Flex
        width={1 / 2}
        alignItems="center"
        height={showNotification ? 146 : 98}
        justifyContent={'center'}
        flexDirection="column"
        py={2}>
        <Text fontWeight="500" mb={2} fontSize={0} pt={showNotification && 60}>
          {COPY.VALID_ON}
        </Text>
        <Text fontWeight="bold" color="deepLakeBlue.100" fontSize={20}>
          {parsedValidOn}
        </Text>
      </Flex>
    </DayPassContainer>
  );
};

DayPassHeader.propTypes = {
  validOn: PropTypes.string,
  amountOfDayPasses: PropTypes.number
};

export default DayPassHeader;

import BirthdaysApiClient from '../../api/clients/BirthdaysApiClient';
import { getTimesAndLocationLabel } from '../../utilities/birthdayUtils';
import { GwDatesWrapper } from '../../utilities/gwDatesWrapper';
import { initializeState } from '../../utilities/storageUtils';
import { getCurrentResort } from './CurrentLodge';

const postBirthdayPartySuccessType = 'POST_BIRTHDAY_PARTY_SUCCESS';
const postBirthdayPartyLoadingType = 'POST_BIRTHDAY_PARTY_LOADING';
const postBirthdayPartyErrorType = 'POST_BIRTHDAY_PARTY_ERROR';

// SET AN INITIAL STATE FOR REDUX
const initialState = {
  summaryData: {},
  sendingPost: false,
  postFailed: false,
  postErrorMessage: ''
};

export const actionCreators = {
  // TODO: Send the form information to salesforce email service
  // Perform the request search by Phone and Cc to API endpoint
  bookBirthdayParty: requestObject => (dispatch, getState) => {
    const property = getCurrentResort(getState().currentLodge);

    // we should clean the output from the birthday dates, should be this same year
    let birthdayPartyRequest = Object.assign({}, requestObject);
    birthdayPartyRequest.children.forEach(birthdayChild => {
      birthdayChild.birthday.set({ year: GwDatesWrapper.year(GwDatesWrapper.today()) });
    });

    // Flat additional children count to additional information
    birthdayPartyRequest.additionalInformation['AdditionalChildren'] = requestObject.additionalChildren.numChildren;

    birthdayPartyRequest.partyDetails.timeAndLocationDetail = getTimesAndLocationLabel(
      birthdayPartyRequest.partyDetails.timeAndLocation
    );

    birthdayPartyRequest.propertyDetails = {
      birthdayEmail: property.birthdayEmail,
      propertyCode: property.operaCode
    };

    dispatch({
      type: postBirthdayPartyLoadingType,
      sendingPost: true
    });
    // Perform the post to the backend
    const apiClient = new BirthdaysApiClient();
    const request = apiClient.createBirthdayBooking(birthdayPartyRequest);
    return request.then(
      response => {
        dispatch({
          type: postBirthdayPartySuccessType,
          summaryData: requestObject
        });
      },
      err => {
        dispatch({
          type: postBirthdayPartyErrorType,
          postErrorMessage: `Ops, ${err.message}`
        });
      }
    );
  }
};

// This reducer combines reducers for providing new values
// to the Redux store based on actions defined above
//
// If the TYPE matches the action, the new state will replace the old
// this occurs on a per action basis and you should see the Redux store
// (state tree) reflect the change

export const reducer = (state, action) => {
  state = initializeState(state, initialState);

  // Get Book information reducers
  switch (action.type) {
    // Post data request succeded
    case postBirthdayPartySuccessType:
      return {
        ...state,
        summaryData: action.summaryData,
        sendingPost: false,
        postFailed: false,
        postErrorMessage: ''
      };
    // The request is currently running
    case postBirthdayPartyLoadingType:
      return {
        ...state,
        sendingPost: action.sendingPost
      };
    // The Post request failed
    case postBirthdayPartyErrorType:
      return {
        ...state,
        summaryData: {},
        sendingPost: false,
        postFailed: true,
        postErrorMessage: action.postErrorMessage
      };
    default:
      return state;
  }
};

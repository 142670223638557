import { useCallback, useEffect, useMemo, useState } from 'react';
import UserAccountApiClientTioga from '../../api/clients/UserAccountApiClient';

const useHistoryPagination = (profileId, initialPage = 1) => {
  // Set Values for the page
  const [page, setPage] = useState(initialPage);
  const [pageLimit, setPageLimit] = useState(1);
  const [paginationContent, setPaginationContent] = useState([]);
  const [rowCount, setRowCount] = useState(5);
  const [displayArray, setDisplayArray] = useState([]);
  const [nextPageAvailable, setNextPageAvailable] = useState(false);
  const [resetAvailable, setResetAvailable] = useState(false);
  const rowCountLimit = useMemo(() => 50 * page, [page]);
  const [hasError, toggleError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Reset function
  const reset = useCallback(() => {
    toggleError(false);
    setErrorMessage('');
  }, []);

  // Update pagination on success for the useEffect
  const updatePaginationOnSuccessEffect = useCallback(historyData => {
    const { history } = historyData;
    // Set the initial history
    const initialHistory = addUUID(history.data);
    setPaginationContent(initialHistory);
    // Set the initial display array
    const initialDisplayArray = spliceArrayAndAddItToAnother(initialHistory, []);
    setDisplayArray(initialDisplayArray);
    // Set the next page available
    const isNextPagePossible = history.totalPages > 1;
    if (isNextPagePossible) {
      setNextPageAvailable(true);
    }
    // Set the page limit
    setPageLimit(history.totalPages);
  }, []);

  // Update pagination on success for  check next page
  const updatePaginationOnSuccessNextPage = useCallback(
    historyData => {
      const { history } = historyData;
      // Get new data
      const nextPageData = addUUID(history.data);
      // Create new pagination content
      const newPaginationContent = [...paginationContent, ...nextPageData];
      // Set the new pagination content
      setPaginationContent(newPaginationContent);
      // Update the row count
      setRowCount(prevState => prevState + 5);
      // Add more rows to the display array
      const newDisplayArray = spliceArrayAndAddItToAnother(newPaginationContent, displayArray, rowCount, rowCount + 5);
      setDisplayArray(newDisplayArray);
      // Set new page
      setPage(prevState => prevState + 1);
      // Check if next page is possible
      const isNextPagePossible = page + 1 < pageLimit;
      if (isNextPagePossible) {
        setNextPageAvailable(true);
      } else {
        setNextPageAvailable(false);
      }
    },
    [displayArray, page, pageLimit, paginationContent, rowCount]
  );

  // Log error on failure
  const updatePaginationOnFailure = useCallback(error => {
    console.log('Error', error);
  }, []);

  /**
   * Fetch function
   * This function return dynaic tile value
   */
  const fetch = useCallback(
    async (id, page, { toggleLoading, onSuccess, onFailure }) => {
      reset();
      toggleLoading(true);

      const apiClient = new UserAccountApiClientTioga('v1');

      const payload = {
        queryParams: { page }
      };
      let result = null;

      try {
        result = await apiClient.getUserPointsHistory(payload);
        toggleLoading(false);
        onSuccess(result.data);
      } catch (error) {
        toggleLoading(false);
        toggleError(true);
        setErrorMessage(error);
        onFailure(error);
      }
    },
    [reset]
  );

  // Reset rows
  const resetRows = () => {
    // Reset row count
    setRowCount(5);
    // Reset display array
    const newDisplayArray = spliceArrayAndAddItToAnother(displayArray, []);
    setDisplayArray(newDisplayArray);
    // Reset reset available
    setResetAvailable(false);
  };

  // Rows to display
  const seeMoreRows = useCallback(() => {
    // If limit reached and more pages available fetch next page
    if (verifyIfSmallerByFive(rowCount, rowCountLimit) && nextPageAvailable) {
      // Get new data
      fetch(profileId, page + 1, {
        toggleLoading: setIsLoading,
        onSuccess: updatePaginationOnSuccessNextPage,
        onFailure: updatePaginationOnFailure
      });
      return;
    }

    // Update the row count
    const newRowCount = rowCount + 5;
    setRowCount(newRowCount);
    // Add more rows to the display array
    const newDisplayArray = spliceArrayAndAddItToAnother(paginationContent, displayArray, rowCount, rowCount + 5);
    setDisplayArray(newDisplayArray);
    // Check if new row count is higher than the pagination content
    if (newRowCount >= paginationContent.length && !nextPageAvailable) {
      setResetAvailable(true);
    }
  }, [
    displayArray,
    fetch,
    nextPageAvailable,
    page,
    paginationContent,
    profileId,
    rowCount,
    rowCountLimit,
    updatePaginationOnFailure,
    updatePaginationOnSuccessNextPage
  ]);

  // Set Initial Values
  // Best solution to avoid calling the API several times?
  useEffect(() => {
    const timer = setTimeout(() => {
      // Set the initial history
      if (profileId) {
        fetch(profileId, 1, {
          toggleLoading: setIsLoading,
          onSuccess: updatePaginationOnSuccessEffect,
          onFailure: updatePaginationOnFailure
        });
      }
    }, 1500);
    return () => clearTimeout(timer);
  }, [fetch, profileId, updatePaginationOnFailure, updatePaginationOnSuccessEffect]);

  return {
    displayArray,
    seeMoreRows,
    resetAvailable,
    resetRows,
    isLoading,
    hasError,
    errorMessage
  };
};

export default useHistoryPagination;

// Add UIUID to each object in the array
function addUUID(array) {
  return array.map(obj => ({ ...obj, uuid: `${obj.type}-${obj.date}-${obj.points}` }));
}

// Order array by date from the most recent to the oldest
function orderByDate(array) {
  return array.sort((a, b) => new Date(b.date) - new Date(a.date));
}

// Splice section of the array and add it to new array
function spliceArrayAndAddItToAnother(rawArray, displayArray, start = 0, end = 5) {
  const rawCopy = [...rawArray];
  const displayCopy = [...displayArray];

  const canNotSpliceByFive = displayArray.length + 5 > rawArray.length;

  const rawCopySlice = canNotSpliceByFive ? rawCopy.slice(start, rawArray.length) : rawCopy.slice(start, end);
  rawCopySlice.forEach(item => {
    displayCopy.push(item);
  });

  return orderByDate(displayCopy);
}

// Check if value is smaller by 5 than other value
function verifyIfSmallerByFive(currentCount, countLimit) {
  return currentCount === countLimit - 5;
}

import normalizeUrl from 'normalize-url';
import routes from '../routes';
import { logMyProfileNav } from './analytics';
import { SITE_NIAGON, TERMS_AND_CONDITIONS_SUFFIX } from './constants';

/**
 * Returns items to be added in the My Account - mobile user menu dropdown.
 */
export const getMobileLoggedInUserDropdownItems = resortLocationUrlParam => [
  {
    name: 'My Profile',
    onClick: e => {
      logMyProfileNav();
      window.location.href = `${resortLocationUrlParam}${routes.profile.self}`;
    }
  },
  {
    name: 'Refer a Friend',
    url: `${resortLocationUrlParam}${routes.referAFriend.replace('/', '').replace(/\/$/, '')}` // remove the first and last slash in the URL, since resortLocationUrlParam has already an ending slash '/'
  },
  {
    name: 'My Preferences',
    url: `${resortLocationUrlParam}${routes.profile.myPreferences.replace('/', '').replace(/\/$/, '')}` // remove the first and last slash in the URL, since resortLocationUrlParam has already an ending slash '/'
  },
  {
    name: 'My Reservations',
    url: `${resortLocationUrlParam}${routes.reservations.myReservations.self.replace('/', '').replace(/\/$/, '')}` // remove the first and last slash in the URL, since resortLocationUrlParam has already an ending slash '/'
  },
  {
    name: 'My Recent Searches',
    url: `${resortLocationUrlParam}${routes.myRecentSearches.replace('/', '')}` // remove the first slash in the URL, since resortLocationUrlParam has already an ending slash '/'
  },
  {
    name: 'Sign Out',
    onClick: (_, { logoutCurrentUser, logLogout }) => {
      logoutCurrentUser();
      logLogout();
    }
  }
];

/**
 * Returns items to be added in the user menu dropdown
 */
export const getLoggedInUserDropdownItems = resortLocationUrlParam => [
  {
    name: 'My Profile',
    url: `${resortLocationUrlParam}${routes.profile.self.replace('/', '')}` // remove the first and last slash in the URL, since resortLocationUrlParam has already an ending slash '/'
  },
  {
    name: 'Refer a Friend',
    url: `${resortLocationUrlParam}${routes.referAFriend.replace('/', '')}` // remove the first and last slash in the URL, since resortLocationUrlParam has already an ending slash '/'
  },
  {
    name: 'My Preferences',
    url: `${resortLocationUrlParam}${routes.profile.myPreferences.replace('/', '').replace(/\/$/, '')}` // remove the first and last slash in the URL, since resortLocationUrlParam has already an ending slash '/'
  },
  {
    name: 'My Reservations',
    url: `${resortLocationUrlParam}${routes.reservations.myReservations.self.replace('/', '')}` // remove the first slash in the URL, since resortLocationUrlParam has already an ending slash '/'
  },
  {
    name: 'My Recent Searches',
    url: `${resortLocationUrlParam}${routes.myRecentSearches.replace('/', '')}` // remove the first slash in the URL, since resortLocationUrlParam has already an ending slash '/'
  },
  {
    name: 'Sign Out',
    url: '#'
  }
];

/**
 * When the user hit Sign out in the middle of this routes, then the app is not redirected to the home page
 */
export const routesExcludedOnSignOut = [
  '/plan',
  '/plan/packages-activities',
  '/plan/packages-dining',
  '/plan/payment',
  '/plan/payment-success',
  '/daypass/payment-success',
  '/deals'
];

/**
 * When the user hit Sign out in the middle of this routes, then the app is redirected to the home page
 */
export const routesRedirectOnSignOut = [
  '/user/profile',
  '/user/refer-a-friend',
  '/plan/payment-success',
  '/plan/confirmation',
  '/daypass/payment-success',
  '/daypass/confirmation',
  '/reservations/my-reservations',
  '/my-recent-searches'
];

export const isMatchParamsRelatedT3Page = (matchParams, t2Page) => {
  if (!t2Page) return false;
  if (!matchParams || Object.values(matchParams).length !== 1) return false;
  if (!matchParams[0].includes(t2Page)) return false;
  if (!matchParams[0].includes('/')) return false;

  return true;
};

/**
 * Checks if the current site is niagara
 * @param {string} resortLocation. App resort location information
 */
export const checkIfIsNiagaraProperty = resortLocation => {
  return resortLocation === SITE_NIAGON;
};

/**
 * Orders and routes configs for Navigation Menu
 */
export const NAV_MENU_FLOWS = {
  primary: [
    {
      route: routes.profile.self,
      title: 'My Profile'
    },
    {
      route: routes.reservations.myReservations.self,
      title: 'My Reservations'
    },
    {
      route: routes.myRecentSearches,
      title: 'My Recent Searches'
    },
    {
      route: routes.profile.myPreferences,
      title: 'My Preferences'
    }
  ]
};

/**
 * Compare two pathnames by normalize them first
 * @param {string} firstUrl First pathname to compare
 * @param {string} secondUrl Second pathname to compare
 * @returns boolean
 */
export const compareUrlPathnames = (firstUrl, secondUrl) => {
  if (firstUrl === secondUrl) return true;

  // check if any of the urls is a root url (only '/')
  if (firstUrl === '/' || secondUrl === '/') return false;

  // remove start slash, only if there is an slash at the begining of the string
  // because starting ones throw an error
  return normalizeUrl(firstUrl.replace(/^\//, '')) === normalizeUrl(secondUrl.replace(/^\//, ''));
};

/**
 * Concatenates the terms link of a suite
 * @param {string} resortUrl found value
 * @returns string
 */
export const getTermsAndConditionUrl = resortUrl => {
  return `${resortUrl}${TERMS_AND_CONDITIONS_SUFFIX}`;
};

// Remove trailing slash from slugUrl if exists, ie /booking/ -> /booking
export const trimUrlPathname = urlPath => (urlPath.endsWith('/') ? urlPath.slice(0, -1) : urlPath);

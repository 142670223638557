import PropTypes from 'prop-types';
import React from 'react';
import { Box } from '../../../ui-kit';
import { BoldText } from '../../assets/SummaryStyles';
import colors from '../../../ui-kit/themes/colors';
import SummaryItem from '../SummaryItemPackage';
import * as COPY from './utilities/copy';

const DayPassFooter = ({ total }) => {
  return (
    <Box borderBottom={'1px solid ' + colors.snowMountainGrey[100]}>
      <SummaryItem
        labelTitle="Total"
        role="complementary"
        aria-label="Total Price"
        pt={3}
        leftColumn={
          <BoldText large color="deepLakeBlue.100">
            {COPY.TOTAL}
          </BoldText>
        }
        rightColumn={
          <BoldText large color="deepLakeBlue.100">
            ${total.toFixed(2)}
          </BoldText>
        }
      />
    </Box>
  );
};

DayPassFooter.propTypes = {
  total: PropTypes.number
};

DayPassFooter.defaultProps = {
  total: 0.0
};

export default DayPassFooter;

import { push } from 'connected-react-router';
import AvailabilityApiClient from '../../api/clients/AvailabilityApiClient';
import Nor1UpgradeApiClient from '../../api/clients/Nor1UpgradeApiClient';
import { OFFER_CODE_TYPE, PERSONALIZATION_PACKAGE_RULES } from '../../utilities/constants';
import { initializeState } from '../../utilities/storageUtils';
import { getPackagesBookingGrossTotal } from './Packages';
import { setAvailabilityDetailsError } from './Plans';
import { gaTrackingPlanSearchErrorCode } from './utilities/util';

export const selectSuiteType = 'SELECT_SUITE';
export const clearSelectedSuiteType = 'CLEAR_SELECTED_SUITE';
const getDetailedAvailabilityType = 'GET_DETAILED_AVAILABILITY_INFO';
export const getDetailedAvailabilitySuccessType = 'GET_DETAILED_AVAILABILITY_SUCCESS';
export const getDetailedAvailabilityErrorType = 'GET_DETAILED_AVAILABILITY_ERROR';
export const getDetailedAvailabilityResetType = 'GET_DETAILED_AVAILABILITY_RESET';
const getNor1UpgradesType = 'GET_NOR1_UPGRADES';
const getPackageAvailabilityInfoErrorType = 'GET_PACKAGE_AVAILABILITY_INFO_ERROR';
const getPlanPackageInfoType = 'GET_PLAN_PACKAGES_INFO_TYPE';
const setPlanPackageInfoSuccess = 'SET_PLAN_PACKAGES_INFO_SUCCESS';
const setSuiteRates = 'SET_NEW_SUITE_RATES';

export const logImpressionsData = 'GTM_IMPRESSIONS';
export const setPersonalizationPackageRules = 'SET_PERSONALIZATION_PACKAGES';

const MISMATCH = 'RATES_MISMATCH';

// SET AN INITIAL STATE FOR REDUX
const initialState = {
  selectedSuite: null,
  getAvailabilityLoading: false,
  suiteDetailedAvailability: [],
  errorMessage: '',
  nor1UpgradesDetails: {},
  suiteLcoPackage: {},
  flexTripAllowed: false,
  flexPackage: {},
  //planPackages: [],
  dayType: 0,
  suiteRateMismatched: false,
  onSuiteSelectionError: '', // Variable for tracking purposes, seems we are not using error variables anymore, and reuse/add them again could be risky
  personalizationPackageRules: [],
  personalizationResponses: [],
  responseHasPackages: false,
  suiteDetailsFetchSuccess: false,
  getPlanPackageInfoLoading: false,
  taxesAndFeesDetailAvailability: {}
};

export const actionCreators = {
  pushImpressionsAnalyticsData: eventProps => dispatch => {
    dispatch({
      type: logImpressionsData,
      eventProps
    });
  },

  pushToPackagesPage: () => dispatch => {
    dispatch(push('/plan/packages-activities'));
  },

  pushToDiningPackagesPage: () => dispatch => {
    dispatch(push('/plan/packages-dining'));
  },

  pushToPayment: () => dispatch => {
    dispatch(push('/plan/payment'));
  },

  selectSuite: (suite, eventProps) => dispatch => {
    dispatch({ type: selectSuiteType, selectedSuite: suite, eventProps });
  },

  getDetailedAvailability: (requestObject, failureCallback = () => {}) => async (dispatch, getState) => {
    const skipCompareValidation = requestObject.skipCompareValidation ? requestObject.skipCompareValidation : false;
    delete requestObject.skipCompareValidation;

    try {
      dispatch({
        type: getDetailedAvailabilityType,
        getAvailabilityLoading: true
      });
      dispatch({
        type: getPlanPackageInfoType,
        getPlanPackageInfoLoading: true
      });

      const selectedSuite = getState().suite.selectedSuite;
      const suiteCode = selectedSuite.suiteCode;
      requestObject.suiteCode = suiteCode;
      const selectedRate = selectedSuite.nightlyRate;
      const offerType = getState().plans.offerType || '';

      const apiClient = new AvailabilityApiClient('v2.3');
      const response = await apiClient.getAvailabilityBySuite(suiteCode, requestObject);

      if (!response.data) {
        const errorCode = await response.json();

        if (errorCode) {
          gaTrackingPlanSearchErrorCode(JSON.stringify(errorCode));
          dispatch({
            type: getDetailedAvailabilityErrorType,
            getAvailabilityLoading: false,
            suiteDetailedAvailability: []
          });
          dispatch({
            type: getPackageAvailabilityInfoErrorType,
            packageAvailabilityList: []
          });
          dispatch({
            type: setAvailabilityDetailsError,
            error: {
              errorCode: errorCode,
              offerCode: requestObject.OfferCode
            }
          });
          failureCallback();
          return;
        }
      }

      if (response.data.suiteRates && response.data.suiteRates.length > 0) {
        response.data.suiteRates = response.data.suiteRates.map(rate => {
          rate['roomAdjustmentTax'] = rate['roomAdjustmentTax'] || 12.5;
          return rate;
        });
      }

      dispatch({
        type: getDetailedAvailabilitySuccessType,
        getAvailabilityLoading: false,
        suiteDetailedAvailability: [...response.data.suiteRates],
        suiteLcoPackage: response.data.lco,
        flexTripAllowed: response.data.isFlexTripAllowed ?? false,
        flexPackage: response.data.flexTripPackage,
        dayType: response.data.dayType,
        personalizationPackageRules: response.data.pr,
        personalizationResponses: response.data.rr,
        responseHasPackages: response.data.packageTable && response.data.packageTable.length > 0 ? true : false,
        suiteDetailsFetchSuccess: true,
        availabilityKey: response.data?.availabilityKey,
        taxesAndFeesDetailAvailability: response.data?.taxesAndFees
      });

      dispatch({ type: setPersonalizationPackageRules });

      dispatch({
        type: setPlanPackageInfoSuccess,
        planPackages: response.data.packageTable,
        getPlanPackageInfoLoading: false
      });

      const isNotSameOffer = offerType !== OFFER_CODE_TYPE.group;
      const isRateNotSame = selectedRate
        ? selectedRate.toFixed(2) !== response.data.suiteRates[0].totalNightlyRate.toFixed(2)
        : false;

      if (!skipCompareValidation && isNotSameOffer && isRateNotSame && !requestObject.isDateChanged) {
        gaTrackingPlanSearchErrorCode(MISMATCH);

        // WILL BE COMMENTED BY US 44687 FOR TESTING MISMATCH
        // dispatch({
        //   type: getDetailedAvailabilityType,
        //   suiteRateMismatched: true
        // });
      }
    } catch (err) {
      gaTrackingPlanSearchErrorCode(JSON.stringify(err));
      dispatch({
        type: getDetailedAvailabilityErrorType,
        getAvailabilityLoading: false,
        suiteStoreErrorMessage: `Oops! ${
          err.validationErrors && err.validationErrors.errorCode ? err.validationErrors.errorCode : err.message
        }`,
        suiteDetailedAvailability: [],
        getPlanPackageInfoLoading: false
      });
      dispatch({
        type: clearSelectedSuiteType
      });
      failureCallback();
      throw err;
    }
  },

  clearSelectedSuite: () => {
    return {
      type: clearSelectedSuiteType,
      selectedSuite: null,
      suiteRateMismatched: false,
      suiteDetailedAvailability: []
    };
  },

  /**
   * Post API call to get upgraded suite details
   */
  getUpgradeDetails: requestObject => dispatch => {
    const apiClient = new Nor1UpgradeApiClient(process.env.REACT_APP_NOR1_UPGRADES_URL);
    const request = apiClient.getNor1UpgradesInfo(requestObject);

    return request.then(
      response => {
        dispatch({
          type: getNor1UpgradesType,
          nor1UpgradesDetails: response.data
        });
      },
      err => {
        dispatch({
          type: getNor1UpgradesType,
          nor1UpgradesDetails: {}
        });
      }
    );
  },

  updateSuiteRates: newSuiteRates => dispatch => {
    dispatch({
      type: setSuiteRates,
      suiteDetailedAvailability: newSuiteRates
    });
  },

  resetAvailabilityCall: () => dispatch => {
    dispatch({
      type: getDetailedAvailabilityResetType
    });
  },
  updateRateCodes: (requestObject, newSelectedSuite) => async (dispatch, getState) => {
    delete requestObject.skipCompareValidation;

    try {
      dispatch({
        type: getDetailedAvailabilityType,
        getAvailabilityLoading: true
      });

      const suiteCode = newSelectedSuite.suiteCode;
      requestObject.suiteCode = suiteCode;
      const apiClient = new AvailabilityApiClient('v2.3');
      const response = await apiClient.getAvailabilityBySuite(suiteCode, requestObject);

      if (!response.data) {
        const errorCode = await response.json();

        if (errorCode) {
          dispatch({
            type: getDetailedAvailabilityErrorType,
            getAvailabilityLoading: false,
            suiteDetailedAvailability: []
          });
          dispatch({
            type: setAvailabilityDetailsError,
            error: {
              errorCode: errorCode,
              offerCode: requestObject.OfferCode
            }
          });
          return;
        }
      }

      if (response.data.suiteRates && response.data.suiteRates.length > 0) {
        response.data.suiteRates = response.data.suiteRates.map(rate => {
          rate['roomAdjustmentTax'] = rate['roomAdjustmentTax'] || 12.5;
          return rate;
        });
      }

      dispatch({
        type: getDetailedAvailabilitySuccessType,
        getAvailabilityLoading: false,
        suiteDetailedAvailability: [...response.data.suiteRates],
        suiteLcoPackage: response.data.lco,
        flexTripAllowed: response.data.isFlexTripAllowed ?? false,
        flexPackage: response.data.flexTripPackage,
        dayType: response.data.dayType,
        personalizationPackageRules: response.data.pr,
        personalizationResponses: response.data.rr,
        responseHasPackages: response.data.packageTable && response.data.packageTable.length > 0 ? true : false,
        suiteDetailsFetchSuccess: true,
        availabilityKey: response.data?.availabilityKey,
        taxesAndFeesDetailAvailability: response.data?.taxesAndFees
      });

      dispatch({ type: setPersonalizationPackageRules });

      dispatch({
        type: setPlanPackageInfoSuccess,
        planPackages: response.data.packageTable,
        getPlanPackageInfoLoading: false
      });
      dispatch({ type: selectSuiteType, selectedSuite: newSelectedSuite });
    } catch (err) {
      gaTrackingPlanSearchErrorCode(JSON.stringify(err));
      dispatch({
        type: getDetailedAvailabilityErrorType,
        getAvailabilityLoading: false,
        suiteStoreErrorMessage: `Oops! ${
          err.validationErrors && err.validationErrors.errorCode ? err.validationErrors.errorCode : err.message
        }`,
        suiteDetailedAvailability: [],
        getPlanPackageInfoLoading: false
      });
      dispatch({
        type: clearSelectedSuiteType
      });

      throw err;
    }
  }
};

// This reducer combines reducers for providing new values
// to the Redux store based on actions defined above
//
// If the TYPE matches the action, the new state will replace the old
// this occurs on a per action basis and you should see the Redux store
// (state tree) reflect the change

const reducer = (state, action) => {
  state = initializeState(state, initialState);
  if (action.type === selectSuiteType) {
    return {
      ...state,
      selectedSuite: action.selectedSuite,
      suiteStoreErrorMessage: ''
    };
  }
  if (action.type === getDetailedAvailabilityType) {
    return {
      ...state,
      getAvailabilityLoading: action.getAvailabilityLoading,
      suiteRateMismatched: action.suiteRateMismatched
    };
  }
  if (action.type === getDetailedAvailabilityErrorType) {
    return {
      ...state,
      suiteStoreErrorMessage: action.suiteStoreErrorMessage,
      getAvailabilityLoading: action.getAvailabilityLoading,
      suiteDetailedAvailability: action.suiteDetailedAvailability,
      onSuiteSelectionError: action.suiteStoreErrorMessage
    };
  }
  if (action.type === getDetailedAvailabilitySuccessType) {
    return {
      ...state,
      getAvailabilityLoading: false,
      suiteDetailedAvailability: action.suiteDetailedAvailability,
      suiteLcoPackage: action.suiteLcoPackage,
      flexTripAllowed: action.flexTripAllowed ?? false,
      flexPackage: action.flexPackage,
      dayType: action.dayType,
      personalizationPackageRules: action.personalizationPackageRules,
      personalizationResponses: action.personalizationResponses,
      responseHasPackages: action.responseHasPackages,
      suiteDetailsFetchSuccess: action.suiteDetailsFetchSuccess,
      selectedSuite: action.availabilityKey
        ? { ...state.selectedSuite, availabilityKey: action.availabilityKey }
        : state.selectedSuite,
      taxesAndFeesDetailAvailability: action.taxesAndFeesDetailAvailability
    };
  }
  if (action.type === setSuiteRates) {
    return {
      ...state,
      suiteDetailedAvailability: action.suiteDetailedAvailability
    };
  }

  if (action.type === clearSelectedSuiteType) {
    return {
      ...state,
      selectedSuite: action.selectedSuite,
      suiteRateMismatched: action.suiteRateMismatched,
      suiteDetailedAvailability: action.suiteDetailedAvailability
    };
  }

  if (action.type === getNor1UpgradesType) {
    return {
      ...state,
      nor1UpgradesDetails: action.nor1UpgradesDetails
    };
  }

  if (action.type === getDetailedAvailabilityResetType) {
    return {
      ...state,
      suiteDetailsFetchSuccess: false
    };
  }
  if (action.type === getPlanPackageInfoType) {
    return {
      ...state,
      getPlanPackageInfoLoading: action.getPlanPackageInfoLoading
    };
  }
  if (action.type === setPlanPackageInfoSuccess) {
    return {
      ...state,
      planPackages: action.planPackages,
      getPlanPackageInfoLoading: action.getPlanPackageInfoLoading
    };
  }
  return state;
};

export default reducer;

// Selectors
/**
 * Get selected suite
 * @param {Object} state
 * @returns {Object} Represent selected suite.
 */
export const getSelectedSuite = state => {
  return state.selectedSuite;
};

/**
 * Get suite net total.
 * @param {Object} state Suite store
 * @return {number} Suite gross total plus taxes
 */
export const getSuiteNetTotal = state => {
  const suiteGrossTotal = getSuiteGrossTotal(state);
  const suiteTaxesAndFees = getSuiteTaxes(state);
  return suiteGrossTotal + suiteTaxesAndFees;
};

/**
 * Get suite title
 * @param {Object} state Suite store
 * @return {string} Suite title
 */
export const getSuiteTitle = state => {
  const selectedSuite = getSelectedSuite(state);
  return selectedSuite ? selectedSuite.title : '';
};

/**
 * Get suite headline
 * @param {Object} state Suite store
 * @return {string} Suite headline
 */
export const getSuiteHeadline = state => {
  const selectedSuite = getSelectedSuite(state);
  return selectedSuite ? selectedSuite.headline : '';
};

/**
 * Get the Sustainability fee all selected packages an its quantities.
 * @param {Object} state Packages store
 * @return {number} Sustainability Fee
 */
export const getSustainabilityFee = state => {
  const { suiteDetailedAvailability } = state;
  return suiteDetailedAvailability && suiteDetailedAvailability.length > 0
    ? suiteDetailedAvailability[0].sustainabilityFee
    : 0;
};

/**
 * Get the Resort fee all selected packages an its quantities.
 * @param {Object} state Packages store
 * @return {number} Resort Fee
 */
export const getResortFee = state => {
  const { suiteDetailedAvailability } = state;
  return suiteDetailedAvailability && suiteDetailedAvailability.length > 0 ? suiteDetailedAvailability[0].resortFee : 0;
};

/**
 * Get the Parking fee all selected packages an its quantities.
 * @param {Object} state Packages store
 * @return {number} Parking Fee
 */
export const getParkingFee = state => {
  const { suiteDetailedAvailability } = state;
  return suiteDetailedAvailability && suiteDetailedAvailability.length > 0
    ? suiteDetailedAvailability[0].parkingFee
    : 0;
};

/**
 * Get suite promotional savings.
 * @param {Object} state Suite store
 * @return {number} Suite promotional savings
 */
export const getPromotionalSavings = state => {
  return Math.abs(getSuiteBaseRate(state) - getSuiteGrossTotal(state));
};

/**
 * Get suite taxes for the current stay.
 * @param {Object} state Suite store
 * @return {number} Suite taxes for the stay
 */
export const getSuiteTaxes = state => {
  const { suiteDetailedAvailability } = state;
  return suiteDetailedAvailability && suiteDetailedAvailability.length > 0 ? suiteDetailedAvailability[0].taxes : 0;
};

/**
 * Get suite gross total for the stay.
 * @param {Object} state Suite store
 * @return {number} Suite gross total
 */
export const getSuiteGrossTotal = state => {
  const { suiteDetailedAvailability } = state;

  return suiteDetailedAvailability && suiteDetailedAvailability.length > 0
    ? suiteDetailedAvailability[0].totalNightlyRate
    : 0;
};

/**
 * Get suite base rate for the stay.
 * @param {Object} state Suite store
 * @return {number} Suite base rate
 */
export const getSuiteBaseRate = state => {
  const { selectedSuite, suiteDetailedAvailability } = state;
  const numOfNights = suiteDetailedAvailability ? suiteDetailedAvailability.length : 0;
  return Number(selectedSuite?.highestRate) * numOfNights;
};

export const getSuiteAndPackageTotalCharge = state => {
  const suiteNetTotal = getSuiteNetTotal(state.suite);
  const packageSuiteNetTotal = getPackagesBookingGrossTotal(state.packages);
  return suiteNetTotal + packageSuiteNetTotal;
};

export const getSuiteType = state => {
  const selectedSuite = getSelectedSuite(state);
  return selectedSuite ? selectedSuite.suiteType : '';
};

/**
 * Returns the Interactions package personalization data from the detail availability reponse
 * @param {Object} state
 */
export const getPackageInteractionsData = state => {
  const interactions =
    state.personalizationResponses &&
    state.personalizationResponses.filter(pr => pr.rule === PERSONALIZATION_PACKAGE_RULES.interactions);

  return interactions && interactions.length > 0 && interactions[0].data;
};

export const hasPlanPackages = state => {
  return state.suite.responseHasPackages;
};

export const getSuitIsLoading = state => {
  return state.suite.getAvailabilityLoading;
};

export const getRoomAdjustmentTax = state => {
  return state?.suite?.suiteDetailedAvailability[0]?.roomAdjustmentTax ?? 0;
};

export const getSuiteLCO = state => {
  return state.suiteLcoPackage;
};

export const getDetailedAvailabilitySuccessStatus = state => {
  return state.suiteDetailsFetchSuccess;
};

export const getPlanPackageInfoLoading = state => {
  return state.getPlanPackageInfoLoading;
};

export const getRateType = state => {
  return state?.selectedSuite?.rateType || '';
};

export const getTaxesAndFeesDetailAvailability = state => {
  return state.taxesAndFeesDetailAvailability;
};
